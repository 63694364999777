import * as React from 'react';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';

const DynamicModeling = ({ setConfigInputs }) => {
  
    const [dynModeling, setDynModeling] = useState({
            dynamic_model: false, 
            dynamic_sample_per_it: null, 
            max_dynamic_it: null
        })


    // What saves the survey setting values once there is a change in the input field
    const handleInputChange = (e) => {

      setDynModeling(
        {
          ...dynModeling,
          [e.target.name]: e.target.value
        }
      )
      
      setConfigInputs( prevState  => ({ ...prevState,   [e.target.name]: e.target.value }));

    };

    // Used for the switch changing
  const handleSwitchChange = (e) => {
    setDynModeling(
      {
        ...dynModeling,
        [e.target.name]: e.target.checked
      }
    )

    setConfigInputs( prevState  => ({ ...prevState,   [e.target.name]: e.target.checked }));
    
    // if dynamic_modeling is unchecked, reset the values of dynamic_sample_per_it and max_dynamic_it to null
    // if it is set to true, set the values equal to zero
    if (e.target.checked === false) {
      setConfigInputs( prevState  => ({ ...prevState,   dynamic_sample_per_it: null }));
      setConfigInputs( prevState  => ({ ...prevState,   max_dynamic_it: null }));
    } else {
      setConfigInputs( prevState  => ({ ...prevState,   dynamic_sample_per_it: 0 }));
      setConfigInputs( prevState  => ({ ...prevState,   max_dynamic_it: 0 }));
    }
  }

  const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
      borderRadius: 22 / 2,
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 16,
        height: 16,
      },
      '&:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main),
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      '&:after': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main),
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      width: 16,
      height: 16,
      margin: 2,
    },
  }));

    return (
        <Grid container sx={{ marginBottom: '20px'}}>            
            <Grid item xs = {3}>
                <FormControl>
                    <FormGroup>
                    <h5>Dynamic Modeling</h5>
                    <FormControlLabel
                        sx={{ p: 0.5 }}
                        control={<Android12Switch checked={dynModeling.dynamic_model}
                        onChange={handleSwitchChange} name="dynamic_model" />}
                    />
                    </FormGroup>
                </FormControl>  
            </Grid>
            <Divider orientation='vertical' sx={{ width: '20px' }} />
            {dynModeling.dynamic_model === true ?
            <>
            <Grid item xs={3}>
                <h5>Max Samples per Batch</h5>
                <TextField
                  sx={{ width: '70%'}}
                  inputProps={{ inputMode: 'numeric', pattern: '[0-30]*', min: 0 }}
                  id="dynamic_sample_per_it"
                  variant="outlined"
                  type="number"
                  name="dynamic_sample_per_it"
                  value={dynModeling.dynamic_sample_per_it}
                  onChange={handleInputChange}
                />
            </Grid>
              <Divider orientation='vertical' sx={{ width: '20px' }} />
              <Grid item xs={3}>
                <h5>Max Number of Batch</h5>
                <TextField
                  sx={{ width: '70%'}}
                  inputProps={{ inputMode: 'numeric', pattern: '[0-30]*', min: 0 }}
                  id="max_dynamic_it"
                  variant="outlined"
                  type="number"
                  name="max_dynamic_it"
                  value={dynModeling.max_dynamic_it}
                  onChange={handleInputChange}
                />
              </Grid>
              </>
              : <div></div>}
        </Grid>
    );
}


export default DynamicModeling;