import * as React from 'react';
import { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { IconButton, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';


const AllVariables = ({ setConfigInputs }) => {

    const [variablesList, setVariablesList] = useState([
        {
            variable_names: "", 
            variable_types: "Continuous", 
            variable_units: "", 
            variable_material_codes: "", 
            variable_descriptions: ""
        },
        {
            variable_names: "", 
            variable_types: "Continuous", 
            variable_units: "", 
            variable_material_codes: "", 
            variable_descriptions: ""
        },]);
        
        // when variablesList is modified, we change the values of setConfigInputs to make API call in CreateAdvanced
        useEffect(() => {
            
            let all_var_names = variablesList.map(d => d.variable_names).filter(n => n);
            let all_var_types = variablesList.map(d => d.variable_types).filter(n => n);
            let all_var_variable_lows = variablesList.map(d => d.variable_lows).filter(number => typeof number == 'number');
            let all_var_variable_highs = variablesList.map(d => d.variable_highs).filter(number => typeof number == 'number');
            let all_var_units = variablesList.map(d => d.variable_units).filter(n => n);
            let all_material_codes = variablesList.map(d => d.variable_material_codes).filter(n => n);
            let all_var_descriptions = variablesList.map(d => d.variable_descriptions).filter(n => n);
            let all_var_categories = variablesList.map(d => d.variable_categories).filter(n => n);

            //console.log('in useEffect all_var_types')

            setConfigInputs( prevState  => ({ ...prevState,   variable_names: all_var_names }));
            setConfigInputs( prevState  => ({ ...prevState,   variable_types: all_var_types }));
            setConfigInputs( prevState  => ({ ...prevState,   variable_lows: all_var_variable_lows }));
            setConfigInputs( prevState  => ({ ...prevState,   variable_highs: all_var_variable_highs }));

            setConfigInputs( prevState  => ({ ...prevState,   variable_units: all_var_units }));
            setConfigInputs( prevState  => ({ ...prevState,   variable_material_codes: all_material_codes }));
            setConfigInputs( prevState  => ({ ...prevState,   variable_descriptions: all_var_descriptions }));
            setConfigInputs( prevState  => ({ ...prevState,   variable_categories: all_var_categories }));

          }, [variablesList]);


      let addVariableFields = () => {
        setVariablesList([...variablesList, {
          variable_names: "", variable_types: "Continuous", variable_units: "", variable_material_codes: "", variable_descriptions: ""
        }])
      }
    
      let removeVariableFields = (i) => {
        let newVariables = [...variablesList];
        newVariables.splice(i, 1);
        setVariablesList(newVariables);
      }
    

      // Step 2: Modifying variable based on variable_types selection
      const contVar = { variable_lows: 0, variable_highs: 1 };

      const levelsList = { levels: 2, variable_categories: Array(2).fill("") };


      // This is what happens when the variables are changed in the variable fields
      const handleVariableChange = (e, index) => {
        const newVariables = [...variablesList];
        const updatedVariable = { ...newVariables[index] }; // Create a copy of the variable object
      
        if (e.target.name === "variable_lows" || e.target.name === "variable_highs" || e.target.name === "levels"
        ) {
            console.log('setting variable_lows')
          updatedVariable[e.target.name] = parseInt(e.target.value);
          console.log(updatedVariable)

        } else {
          updatedVariable[e.target.name] = e.target.value;
        }
      
        // if continuous, append contVars to that variable[index], otherwise append levelsList
        if (e.target.value === "Continuous") {
          updatedVariable.variable_types = "Continuous";
          updatedVariable.variable_lows = 0;
          updatedVariable.variable_highs = 1;
        } else if (e.target.value === "Discrete" || e.target.value === "Categorical") {
          updatedVariable.variable_types = e.target.value;
          updatedVariable.levels = 2;
          updatedVariable.variable_categories = Array(2).fill("");
        }

        // This is to take care of the addition and subtraction of the level fields
        if (e.target.name === "levels") {
            let currentLevelCount = updatedVariable.variable_categories.length;
            let desiredLevelCount = updatedVariable.levels;

            if (currentLevelCount < desiredLevelCount) {
                const diff = desiredLevelCount - currentLevelCount;
                updatedVariable.variable_categories.push(...Array(diff).fill(""));
            } else if (currentLevelCount > desiredLevelCount) {
                updatedVariable.variable_categories.splice(desiredLevelCount);
            }
        }        
      
        newVariables[index] = updatedVariable; // Update the variable object in the array
      
        setVariablesList(newVariables);

      };
      

        const handleLevelChange = (e, index, levelIndex) => {
            const newVariables = [...variablesList];
            newVariables[index].variable_categories[levelIndex] = e.target.value;
            setVariablesList(newVariables);
        };
          

        console.log(variablesList)

    return (
        <Grid container sx={{ marginBottom: '30px'}}>
            <Grid item xs={12}>
                <h5>Variables</h5>
            </Grid>
                    {variablesList.map((element, index) => (
                        <>
                        <div key={`variable-field-${index}`} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px', marginTop: '12px' }}>
                            <Grid item xs={2}>
                            <TextField
                                // required
                                label="Name"
                                id="variable_names"
                                variant="outlined"
                                name="variable_names"
                                value={element.variable_names}
                                onChange={(e) => handleVariableChange(e, index)}
                                inputProps={{ autoComplete: 'off' }}
                            />
                            </Grid>
                            <Divider orientation='vertical' sx={{ width: '10px' }} />
                            <Grid item xs={2}>
                                <FormControl sx={{ minWidth: 180 }}>
                                <InputLabel variant="outlined" id="var-type-label">Type</InputLabel>
                                <Select
                                    // required
                                    label="Type *"
                                    labelId="var-type-label"
                                    id="var-type"
                                    value={element.variable_types}
                                    name="variable_types"
                                    onChange={(e) => handleVariableChange(e, index)}
                                    inputProps={{ autoComplete: 'off' }}
                                >
                                    <MenuItem value="Continuous">Continuous</MenuItem>
                                    <MenuItem value="Categorical">Categorical</MenuItem>
                                    <MenuItem value="Discrete">Discrete</MenuItem>
                                </Select>
                                </FormControl>
                            </Grid>
                            <Divider orientation='vertical' sx={{ width: '10px' }} />
                            <Grid item xs={2}>
                            <TextField
                                label="Units (Optional)"
                                id="variable_units"
                                variant="outlined"
                                name="variable_units"
                                value={element.variable_units}
                                onChange={(e) => handleVariableChange(e, index)}
                                inputProps={{ autoComplete: 'off' }}
                            />
                            </Grid>
                            <Divider orientation='vertical' sx={{ width: '10px' }} />
                            <Grid item xs={2}>
                            <TextField
                                label="Material Code (Optional)"
                                id="variable_material_codes"
                                variant="outlined"
                                name="variable_material_codes"
                                value={element.variable_material_codes}
                                onChange={(e) => handleVariableChange(e, index)}
                                inputProps={{ autoComplete: 'off' }}
                            />
                            </Grid>
                            <Divider orientation='vertical' sx={{ width: '10px' }} />
                            <Grid item xs={2}>
                            <TextField
                                label="Descriptions"
                                id="variable_descriptions"
                                variant="outlined"
                                name="variable_descriptions"
                                value={element.variable_descriptions}
                                onChange={(e) => handleVariableChange(e, index)}
                                inputProps={{ autoComplete: 'off' }}
                            />
                            </Grid>
                            {element.variable_types === "Continuous" ?
                                <>
                                    <Divider orientation='vertical' sx={{ width: '10px' }} />
                                    <Grid item xs={2}>
                                    <TextField
                                        label="Low Level"
                                        required
                                        inputProps={{ min: 0 }}
                                        id="variable_lows"
                                        variant="outlined"
                                        type="number"
                                        name="variable_lows"
                                        value={element.variable_lows}
                                        onChange={(e) => handleVariableChange(e, index)}
                                    />
                                    </Grid>
                                    <Divider orientation='vertical' sx={{ width: '10px' }} />
                                    <Grid item xs={2}>
                                    <TextField
                                        required
                                        inputProps={{ min: element.variable_lows+1 }}
                                        label="High Level"
                                        id="variable_highs"
                                        variant="outlined"
                                        type="number"
                                        name="variable_highs"
                                        value={element.variable_highs}
                                        onChange={(e) => handleVariableChange(e, index)}
                                    />
                                    </Grid>
                                </>
                            : element.variable_types === "Discrete" || element.variable_types === "Categorical" ?
                                <>
                                <Divider orientation='vertical' sx={{ width: '10px' }} />
                                    <TextField
                                        required
                                        inputProps={{ min: 2, max: 10 }}
                                        sx={{ minWidth: 100 }}
                                        label="# of Levels"
                                        id="levels"
                                        variant="outlined"
                                        type="number"
                                        name="levels"
                                        value={element.levels}
                                        onChange={(e) => handleVariableChange(e, index)}
                                    />
                                </>
                            : <div></div>}
                            <Grid item xs={2}>
                            {variablesList.length !== 1 &&
                                (<IconButton sx={{ display: 'block'}} aria-label="delete" className="remove-btn" onClick={() => removeVariableFields(index)}>
                                    <DeleteIcon />
                                </IconButton>)}
                            </Grid>
                        </div>
                        {element.variable_types === "Discrete" || element.variable_types === "Categorical" ? (
                            <div key={`level-fields-${index}`} style={{ display: 'flex', marginBottom: '15px' }}>
                                {Array.from({ length: element.levels }, (_, levelIndex) => (
                                <React.Fragment key={`levelField-${index}-${levelIndex}`}>
                                    <Grid item xs={12}>
                                    <TextField
                                        key={`levelField-${index}-${levelIndex}`}
                                        id={`levelField-${index}-${levelIndex}`}
                                        label={`Level ${levelIndex + 1}`}
                                        variant="outlined"
                                        name={`level${levelIndex + 1}`}
                                        value={element.variable_categories[levelIndex]}
                                        onChange={(e) => handleLevelChange(e, index, levelIndex)}
                                        sx={{ width: 136 }}
                                    />
                                    </Grid>
                                    <Divider key={`divider-${index}-${levelIndex}`} orientation='vertical' sx={{ width: '10px' }} />
                                </React.Fragment>
                                ))}
                            </div>
                            ) : (
                            <div key={`level-fields-${index}`} />
                            )}
                        <Grid item xs={12}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                        {variablesList.length - 1 === index &&
                            (<Button sx={{ display: 'block' }} aria-label="add" variant="contained" className="add-btn" onClick={() => addVariableFields()} size="large">
                                <AddIcon />
                            </Button>)}
                        </div>
                        </Grid>
                        </>
                    ))}
        </Grid>
    );
}


export default AllVariables;