import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import Switch from '@mui/material/Switch';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import { Link, redirect, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { login } from '../../../../actions/auth'
//import '../styles.scss';
import axios from 'axios';

//menu components
import IntroMenu from './components/IntroMenu';
import Configuration from './components/Configuration';
import ShareAndSave from './components/ShareAndSave';
import ViewSamples from './components/ViewSamples';
import StyleEditor from './components/StyleEditor';
import AddPages from './components/AddPages';
import ConfigurationCustom from './components/ConfigurationCustom';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

/// Each sub-menu has a 'handleMenuSubmit const which handles making the api call and updating state accordingly /// 
// submit the intro menu form and return a survey id
const handleIntroSubmit = async (access_token, introInputs, setSurveyID) => {

  console.log("in handleIntroSubmit");
  console.log(introInputs)

  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/survey/create/intro_menu`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        'Authorization': `JWT ${access_token}`,
        'Accept': 'application/json'
      },
      body: JSON.stringify(introInputs)
    }).then(response => response.json())
      .then(response => {
        setSurveyID(response.survey_id) // set the survey id from the response
        console.log(response)
      })
  } catch (err) {
    console.log(err.message)
  }
}

// submit the config menu form
// if custom required or optional terms is selected, return the terms a user must select in config custom
const handleConfigSubmit = async (access_token, configInputs, setConfigInputs, surveyID, configCustom, setConfigCustom, samples, setSamples) => {

  console.log("in handleConfigSubmit");

  // add survey_id to a config body to submit all together
  const config_body = { ...configInputs, survey_id: surveyID }

  // check if user selected DOE (D-Optimality), if so set optional terms
  if (config_body.design_type === "DOE (D-Optimality)") {
    config_body.optional_terms = config_body.required_terms;
  }

  console.log("config_body");
  console.log(config_body);

  try {

    console.log("in handleConfigSubmit");
    const swal = Swal.fire({
      title: 'Please wait...',
      text: 'Waiting on your Design of Experiment to build...',
      allowOutsideClick: false,
      backdrop: 'rgba(0, 0, 0, 0.5)',
      didOpen: () => {
        Swal.showLoading();
      }
    });

    const response = await fetch(`${process.env.REACT_APP_API_URL}/survey/create/add_configuration`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        'Authorization': `JWT ${access_token}`,
        'Accept': 'application/json'
      },

      body: JSON.stringify(config_body)
    }
    );

    const data = await response.json();
    console.log('json response')
    console.log(data);


    // if the required or optional terms are custom, we will be returned an array of required terms
    if (configInputs.required_terms === "Custom" || configInputs.optional_terms === "Custom") {
      setConfigCustom({ ...configCustom, terms: data.required_terms });
      console.log(configCustom);
      swal.close();
    }
    else { // Add in the swal
      swal.close();
      setSamples(data.DoE);
      console.log("json response");
    }
  } catch (err) {
    console.log(err.message);
  }
}

// used to submit the custom config array and return the samples matrix
const handleCustomConfigSubmit = async (access_token, surveyID, configCustom, samples, setSamples) => {

  // manually set for now
  console.log("in handleCustomConfigSubmit")

  const custom_config_body = {
    "survey_id": surveyID,
    "custom_model": configCustom.term_status
  }


  console.log("in handleCustomConfigSubmit");
  console.log(custom_config_body);

  // access the api url in order to add the custom model to the survey and get samples back
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/survey/create/add_configuration_custom`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        'Authorization': `JWT ${access_token}`,
        'Accept': 'application/json'
      },
      body: JSON.stringify(custom_config_body)
    }
    );

    const data = await response.json();
    console.log('json response');
    console.log(data.DoE);

    // from the json response, set the samples generated by matlab - SWAL
    setSamples(data.DoE);

    //print the error if the api call
  } catch (err) {
    console.log(err.message)
  }
}

// used to submit the custom config array and return the samples matrix
const handleUpdateSamplesSubmit = async (access_token, surveyID, samples) => {

  // manually set for now
  // @TODO: get the survey id from the intro menu later on
  //let temp_survey_id = "1b9e006c-3748-4e20-8e49-dc789369f971";

  // let custom_config_body = {
  //   "survey_id": surveyID,
  //   "custom_model": configCustom.term_status
  // }

  const samples_body = {
    "survey_id": surveyID,
    "doe_matrix": samples
  }

  console.log("in handleUpdateSamplesSubmit");
  console.log(samples_body);

  // access the api url in order to add the custom model to the survey and get samples back
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/survey/create/update_samples`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        'Authorization': `JWT ${access_token}`,
        'Accept': 'application/json'
      },
      body: JSON.stringify(samples_body)
    }
    );

    const response_body = await response.json();
    console.log('json response');
    console.log(response_body);

    //print the error if the api call
  } catch (err) {
    console.log(err.message)
  }
}

// used to submit the add pages for a survey
const handleAddPagesSubmit = async (access_token, surveyID, addPages) => {

  console.log('in handleAddPagesSubmit')

  const add_pages_body = {
    "survey_id": "5de31688-44ce-46e5-8d93-9c748627155d",
    "primary_survey_question": [{ "question": "how good is this flavor?", "show_rating_label": true, "minimum": 0, "maximum": 10, "step": 1, "shape": "Star" }],
    "secondary_survey_question": [{ "question": "does it also taste good?", "format": "Checkbox", "Randomize": true, "num_options": 3, "options": ["red", "blue", "green"] }, { "question": "what about the packaging?", "format": "JAR Scale", "Scale": 3, "options": ["terrible", "okay", "alright"] }],
    "instructions": { "title": "this is how you evaluate the packaging", "background": "first flip it over and read the nutrition label" },
    "demographic_questions": [{ "question_type": "age", "num_options": 2, "options": ["<30", ">=30"] }, { "question_type": "gender", "num_options": 3, "options": ["male", "female", "prefer to not identify"] }],
    "add_recommendation": [{ "type": "drivers", "num_rec": 3, "option_values": [{ "rec_title": "fish", "variable_values": 3, "attached_image": null }, { "rec_title": "cat", "variable_values": 4, "attached_image": null }, { "rec_title": "grapes", "variable_values": 9, "attached_image": null }] }]
  }

  //add_pages
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/survey/create/add_pages`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        'Authorization': `JWT ${access_token}`,
        'Accept': 'application/json'
      },
      body: JSON.stringify(add_pages_body)
    }
    );

    const response_body = await response.json();
    console.log('json response');
    console.log(response_body);

    //print the error if the api call
  } catch (err) {
    console.log(err.message)
  }
}

// used to submit the style editor for a survey
const handleStyleEditorSubmit = async (access_token, surveyID, styleEditor) => {
  console.log('in handleStyleEditorSubmit')

  const style_editor_body = { ...styleEditor, "survey_id": surveyID }


  //add_pages
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/survey/create/add_add_style`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        'Authorization': `JWT ${access_token}`,
        'Accept': 'application/json'
      },
      body: JSON.stringify(style_editor_body)
    }
    );

    const response_body = await response.json();
    console.log('json response');
    console.log(response_body);

    //print the error if the api call
  } catch (err) {
    console.log(err.message)
  }
}

// used to submit the share and save fields for a survey
const handleShareAndSave = async (access_token, surveyID, shareSave) => {
  console.log('in handleShareAndSave')

  const share_save_body = { ...shareSave, "survey_id": surveyID }



  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/survey/create/add_share_save`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        'Authorization': `JWT ${access_token}`,
        'Accept': 'application/json'
      },
      body: JSON.stringify(share_save_body)
    }
    );

    const res = await response.json();
    console.log('json response');
    console.log(res);

    //print the error if the api call
  } catch (err) {
    console.log(err.message)
  }
}


const CreateAdvanced = ({ isAuthenticated, access_token }) => {

  // set the surveyID
  useEffect(() => {

    console.log('in useEffect')
    setConfigInputs(prevState => ({ ...prevState, survey_id: surveyID }));
  }, [surveyID, setConfigInputs]);


  // ALL CONSTS USED FOR EACH SUBMENU //
  // surveyID, set from intro page and sent for all subsequent API calls to identify survey
  const [surveyID, setSurveyID] = useState("empty");

  // menu fields from intro inputs
  const [introInputs, setIntroInputs] = useState({
    project_title: "",
    brief_number: "",
    flavor_type: "Not Applicable",
    project_objective: "",
    project_background: "",
    project_type: "Ingredient Replacement",
    hardware: "Not Applicable",
    atom_administer: true
  })

  // the configuration inputs which encompass variables, constants, dynamic modeling, model type, and terms
  const [configInputs, setConfigInputs] = useState({
    variable_names: [],
    variable_types: [],
    variable_lows: [],
    variable_highs: [],
    variable_units: [],
    variable_material_codes: [],
    variable_descriptions: [],
    variable_categories: [],
    constant_names: [],
    constant_types: [],
    constant_amounts: [],
    required_terms: null,
    optional_terms: null,
    design_type: null,
    dynamic_model: false,
    dynamic_sample_per_it: null,
    max_dynamic_it: null
  })

  // if user selects custom survey for either required or optional, we set terms from api response and ask user to set term status
  const [configCustom, setConfigCustom] = useState({
    terms: [],
    term_status: []
  });

  // the DoE returned from matlab that a user can then edit
  const [samples, setSamples] = useState("");

  // addpages is used if a user wishes to use ATOM to deliver a survey, it covers the questions/instructions a user wants to display
  const [primaryQuestion, setPrimaryQuestion] = useState({
    primary_survey_question: [{
      question: "",
      show_rating_label: false,
      minimum: null,
      maximum: null,
      step: null,
      shape: "",
      rating_labels: []
    }]
  })

  const [secondaryQuestion, setSecondaryQuestion] = useState({
    secondary_survey_question: [{
      question: "",
      format: "",
      show_rating_label: false,
      minimum: null,
      maximum: null,
      step: null,
      shape: "",
      options: [],
      scale: null,
      fields: [],
      randomize: false,
      num_options: null,
    }]
  })

  const [instructions, setInstructions] = useState({
    instructions: [{
      title: "",
      description: "",
    }]
  })

  const [demographicQuestions, setDemographicQuestions] = useState({
    demographic_questions: [{
      age_range: false,
      gender: false,
      race: false,
      question: "",      
      randomize: false,
      number_of_options: null,
      fields: []
    }]
  })

  const [addRecommendation, setAddRecommendation] = useState({
    recommendations: [{
      drivers_of_liking: false,
      show_reccomendations: false,
      number_of_recommendations: 3
    }]
  })

  const [addPages, setAddPages] = useState({
    primary_survey_question: primaryQuestion,
    secondary_survey_question: secondaryQuestion,
    instructions: [],
    demographic_questions: [],
    add_recommendation: []
  })

  useEffect(() => {
    setAddPages(prevState => ({
      ...prevState,
      primary_survey_question: primaryQuestion.primary_survey_question,
      secondary_survey_question: secondaryQuestion.secondary_survey_question,
      instructions: instructions.instructions,
      demographic_questions: demographicQuestions.demographic_questions,
      add_recommendation: addRecommendation.recommendations // Please correct this key to the right one
    }));
  }, [primaryQuestion, secondaryQuestion, instructions, demographicQuestions, addRecommendation]);

  console.log([primaryQuestion, secondaryQuestion, instructions, demographicQuestions, addRecommendation])
  // console.log("Checking Secondary Survey Question Format:")
  // console.log(addPages.secondary_survey_question[0].format);
   
  const [styleEditor, setStyleEditor] = useState({
    survey_theme: null,
    page_background: null,
    page_title: null,
    description_text: null,
    question_text: null,
    answer_text: null,
    answer_description: null,
    error_text: null,
    progress_indicator: null,
    buttons: null,
    word_cloud: null,
    recommendation_name: null,
    recommendation_title: null,
    page_title_bold: null,
    page_title_italic: null,
    description_text_bold: null,
    description_text_italic: null,
    question_bold: null,
    question_italic: null,
    answer_bold: null,
    answer_italic: null,
    answer_description_bold: null,
    answer_description_italic: null,
    error_bold: null,
    error_italic: null,
    recommendation_name_bold: null,
    recommendation_name_italic: null,
    header_bold: null,
    header_italic: null
  })

  // share and save is the final step, it converts a survey from draft to final and allows user to set sharing options and viewers
  const [shareSave, setShareSave] = useState({
    internal_share: false,
    customer_share: false,
    share_list: []
  });

  // These are here to handle the change of the tab panels
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  // why is this here?
  const handleInputChange = (e) => {

    // handle change for checkbox
    if (e.target.type === "checkbox") {

      setIntroInputs(
        {
          ...introInputs,
          [e.target.name]: e.target.checked
        }
      )
      // handle all other change types
    } else {

      setIntroInputs({
        ...introInputs,
        [e.target.name]: e.target.value,
      });
    }
  };

  // used to set the state of the menu
  // the index is used to set the active sub-menu open and trigger handleForm submits
  const handleNext = () => {

    // submit intro form when user clicks 'Next" on intro menu
    if (value === 0) {
      handleIntroSubmit(access_token, introInputs, setSurveyID);

      // go to next tab panel
      setValue((prevValue) => prevValue + 1);

    }

    // submit config form when user clicks 'Next' on config menu
    if (value === 1) {
      console.log(configInputs)

      handleConfigSubmit(access_token, configInputs, setConfigInputs, surveyID, configCustom, setConfigCustom, samples, setSamples);

      // if Custom is set for either required or optional terms, go to the config optional menu, else go straight to samples menu
      if (configInputs.required_terms === "Custom" || configInputs.optional_terms === "Custom") {
        setValue((prevValue) => prevValue + 1);
      } else {
        setValue((prevValue) => prevValue + 2);
      }
    }

    //handle custom config menu when 'Next' is clicked
    if (value === 2) {

      // submit the custom terms alongside the survey id
      //console.log(configCustom);
      handleCustomConfigSubmit(access_token, surveyID, configCustom, samples, setSamples);
      //console.log(samples)

      // to the the view samples page (3)
      setValue((prevValue) => prevValue + 1);
    }

    //handle update samples menu when 'Next is clicked
    if (value === 3) {

      handleUpdateSamplesSubmit(access_token, surveyID, samples)

      // move to the next view of Add Pages (4) only if atom_administer is true, else go to share and save
      if (introInputs.atom_administer === true) {
        setValue((prevValue) => prevValue + 1);
      } else {
        setValue((prevValue) => prevValue + 3);
      }
    }


    //handle update samples menu when 'Next is clicked
    if (value === 4) {

      // add add pages data to survey
      handleAddPagesSubmit(access_token, surveyID, addPages);

      // move to the next view of Style Editor
      setValue((prevValue) => prevValue + 1);
    }


    //handle update style editor menu when Next is clicked
    if (value === 5) {

      // add add pages data to survey
      handleStyleEditorSubmit(access_token, surveyID, styleEditor)

      // move to the next view of Style Editor
      setValue((prevValue) => prevValue + 1);
    }


    //handle update share and save menu when Next is clicked
    if (value === 6) {

      // save the shareandsave screen that includes who to share with and email share invites
      handleShareAndSave(access_token, surveyID, shareSave);

      // SWAL to confirm survey is created and a user may return home
        Swal.fire( {
            title:"Your Project is Ready!",
            text: "You have taken the first step to building amazing solutions using artificial intelligence. Continue working with customers and collaborators to build amazing solutions and come back when you are either ready to administer a survey or add results."
         }).then(function() {
          window.location = `${process.env.REACT_APP_API_URL}/dashboard`;
      });
    }
  }

  const handleBack = () => {
    if (value === 0) {
      return
    }
    setValue((prevValue) => prevValue - 1);
  }

console.log("Im in createAdvanced:", addPages)

  return (
    <Box sx={{ bgcolor: 'background.paper', mx: 'auto', width: '80%' }}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Introduction" {...a11yProps(0)} />
          <Tab label="Configuration" {...a11yProps(1)} />
          <Tab label="Configuration-Custom" {...a11yProps(2)} />
          <Tab label="View Samples" {...a11yProps(3)} />
          <Tab label="Add Pages" {...a11yProps(4)} />
          <Tab label="Style Editor" {...a11yProps(5)} />
          <Tab label="Share & Save" {...a11yProps(6)} />
        </Tabs>
      </AppBar>
      {/* This is the configuration page layout */}
      <TabPanel value={value} index={0} dir={theme.direction}>
        {/* The variables and constants fields are both within the same Grid container as separate
            Grid items. The items are layed out by row, while the whole container is layed out as
            columns.  */}

        {/* <Grid container direction="column" justifyContent="space-evenly" alignItems="flex-start" spacing={2}> */}
        <IntroMenu handleIntroInputChange={handleInputChange} introInputs={introInputs} setIntroInputs={setIntroInputs} />

      </TabPanel>
      {/* This is the Add Pages page layout */}
      <TabPanel value={value} index={1} dir={theme.direction}>
        <Configuration projectType={introInputs.project_type} atomAdminister={introInputs.atom_administer} hardware={introInputs.hardware} setConfigInputs={setConfigInputs} />
      </TabPanel>
      {/* This is the Add Pages page layout */}
      <TabPanel value={value} index={2} dir={theme.direction}>
        Configuration-Custom
        <ConfigurationCustom value={value} survey_id="1b9e006c-3748-4e20-8e49-dc789369f971" terms={configCustom} />
      </TabPanel>
      {/* This is the style editor page layout */}
      <TabPanel value={value} index={3} dir={theme.direction} >
        <ViewSamples samples={samples} configInputs={configInputs} updateSamples={setSamples} />
      </TabPanel>
      {/* This is the style editor page layout */}
      <TabPanel value={value} index={4} dir={theme.direction}>
        <AddPages setAddPages={setAddPages} />
      </TabPanel>
      {/* This is the style editor page layout */}
      <TabPanel value={value} index={5} dir={theme.direction}>
        <StyleEditor setStyleEditor={setStyleEditor} />
      </TabPanel>
      {/* This is the style editor page layout */}
      <TabPanel value={value} index={6} dir={theme.direction}>
        <ShareAndSave setShareSave={setShareSave} />
      </TabPanel>

      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '40px' }}>
        <Button variant='contained' onClick={handleBack}>Back</Button>
        <Button variant='contained' onClick={handleNext}>Next</Button>
      </div>
    </Box>
  );
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  access_token: state.auth.access
});


export default connect(mapStateToProps, {})(CreateAdvanced);