import React from 'react';
import { Link } from 'react-router-dom';

const LearnMore = () => (
    <div className='container-md pt-4'>
        <div class="jumbotron">
            <h1 class="display-4">Learn More about ATOM</h1>
        </div>
    </div>
);

export default LearnMore;