import React from 'react';
import { Card, CardContent, FormGroup } from '@mui/material';
import { styled } from '@mui/material/styles';
import '../styles.scss'
import { Fragment, useState } from 'react';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import Divider from '@mui/material/Divider';

let inputVariables = {
    title: "",
    description: "",
    briefID: "",
    contVars: false,
    catVars: false,
    discNum: false,
    usingVAS: false,
    addAmount: false,
    interactions: "",
    quadEffects: "",
    capture: false,
    confirmIncrease: false,
    useDynamic: "",
    sampleNumber: "0",
    approve: "",
    totalBatches: "0",
    incOrDec: "",
    numOfSamplesChange: "0",
    ATOMAdminister: "",
};

const BasicMenuSurvey = (props) => {

    // inputs is where the user inputs are saved
    const [inputs, setInputs] = useState(inputVariables);

    // This is where the changes to the inputs gets updated
    let handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputs({
            ...inputs,
            [name]: value,
        });
    };

    // This is the list of the variables that the user will be prompted to input
    const [variablesList, setVariablesList] = useState([
        { varName: "", varType: "", low: "", high: "", units: "", description: "", numLevels: "", firstLevel: "", secondLevel: "", thirdLevel: "", fourthLevel: "", fifthLevel: "",
         sixthLevel: "", seventhLevel: "", eighthLevel: "", ninthLevel: "", tenthLevel: ""},
    ]);

    // This is what dynamically changes the inputs as they are entered
    let handleVariableChange = (e, index) => {
        let newVariables = [...variablesList];
        newVariables[index][e.target.name] = e.target.value;
        setVariablesList(newVariables);
    };

    let addVariableFields = () => {
        setVariablesList([...variablesList, { 
            varName: "", varType: "", low: "", high: "", units: "", description: "", numLevels: "", firstLevel: "", secondLevel: "", thirdLevel: "", fourthLevel: "", fifthLevel: "",
            sixthLevel: "", seventhLevel: "", eighthLevel: "", ninthLevel: "", tenthLevel: "" }])
    }

    let removeVariableFields = (i) => {
        let newVariables = [...variablesList];
        newVariables.splice(i, 1);
        setVariablesList(newVariables)
    }

    const [constantsList, setConstantsList] = useState([
        { constName: "", constType: "", constAmount: ""},
      ]);
    
    let addConstantFields = () => {
    setConstantsList([...constantsList, { 
        constName: "", constType: "", constAmount: "" }])
    }

    let removeConstantFields = (i) => {
        let newConstants = [...constantsList];
        newConstants.splice(i, 1);
        setConstantsList(newConstants)
    }

    // This is what happens when the constants are changed in the variable fields
    const handleConstantChange = (e, index) => {
        let newConstants = [...constantsList];
        newConstants[index][e.target.name] = e.target.value;
        setConstantsList(newConstants);
    };

    const [indepsList, setIndepsList] = useState([
        { indepName: "", goal: "" },
      ]);
    
      let addIndepFields = () => {
        setIndepsList([...indepsList, { 
          indepName: "", goal: "" }])
      }
    
      let removeIndepFields = (i) => {
          let newIndeps = [...indepsList];
          newIndeps.splice(i, 1);
          setIndepsList(newIndeps)
      }
    
      // This is what happens when the independent variables are changed in the variable fields
      const handleIndepChange = (e, index) => {
        let newIndeps = [...indepsList];
        newIndeps[index][e.target.name] = e.target.value;
        setIndepsList(newIndeps);
      };

    // This function is what allows for a change to the input switches
    const handleSwitchChange = (e) => {
        setInputs(
            {
                ...inputs,
                [e.target.name]: e.target.checked
            }
        )
    }


    // This is the styling of the Android-style switches. If a different styling
    // of switch is wanted, then there are many other variants online.
    const Android12Switch = styled(Switch)(({ theme }) => ({
        padding: 8,
        '& .MuiSwitch-track': {
          borderRadius: 22 / 2,
          '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
          },
          '&:before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
              theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12,
          },
          '&:after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
              theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M19,13H5V11H19V13Z" /></svg>')`,
            right: 12,
          },
        },
        '& .MuiSwitch-thumb': {
          boxShadow: 'none',
          width: 16,
          height: 16,
          margin: 2,
        },
      }));

    // this varible is how we know what is prompted on which card
    const card_num = props.index;
    // let useVAS = inputs.usingVAS;
    // localStorage.setItem("storedUsingVAS", useVAS);


    // Based on which card the user is on, this is what will be posted
    if (card_num === 0){ // General Questions 
        return(
            <Fragment>
               <Box sx={{ p: 3, display: 'flex' }} align="left">
                        <FormControl>
                            <FormGroup>
                            <h5>...have continuous variables?</h5>
                            <FormControlLabel
                            control={<Android12Switch checked={inputs.contVars} onChange={handleSwitchChange} name="contVars" />}                           
                            />
                            {console.log(inputs.contVars)}
                            <h5>...have categorical variables?</h5>
                            <FormControlLabel
                            control={<Android12Switch checked={inputs.catVars} onChange={handleSwitchChange} name="catVars" />}
                            />
                            <h5>...have discrete numeric?</h5>
                            <FormControlLabel
                            control={<Android12Switch checked={inputs.discNum} onChange={handleSwitchChange} name="discNum" />}
                            />
                            <h5>...use a VAS?</h5>
                            <FormControlLabel
                            control={<Android12Switch checked={inputs.usingVAS} onChange={handleSwitchChange} name="usingVAS" />}
                            />
                            <h5>...need to add to a certain amount to variables or ingredients, such as 100%?</h5>
                            <FormControlLabel
                            control={<Android12Switch checked={inputs.addAmount} onChange={handleSwitchChange} name="addAmount" />}
                            />
                        </FormGroup>
                    </FormControl>
                    </Box>
            </Fragment>
        )
    } else if (card_num === 1) { // Provide Dependent Variables
        return (
            // The variable inputs that the user is prompted to make are put into a Grid to contain the 
            // variables that are added. The point of the inner Grid is to allow for the "Add Variable"
            // button to be laid out underneath the variable inputs.
            <Fragment>
                <Box sx={{ p: 3, m: 5, display: 'flex' }}>
                <Grid container direction="column" justifyContent="space-evenly" alignItems="flex-start">
                        {variablesList.map((element, index) => (
                            <div key={index}>
                            <Grid item justifyContent="flex-start" alignItems="flex-start" style={{ display: "flex"}}>
                                <TextField
                                label="Variable Name"
                                id="varName"
                                variant="outlined"
                                name="varName"
                                value={element.varName}
                                onChange={(e) => handleVariableChange(e, index)}
                                />
                                <Divider orientation='vertical' sx={{ width: '10px'}} />
                                <FormControl sx={{ minWidth: 150 }}>
                                <InputLabel variant="outlined" id="variable-type-label">Type</InputLabel>
                                <Select
                                    label="Type"
                                    labelId="variable-type-label"
                                    id="variable-type"
                                    name="varType"
                                    value={element.varType}
                                    onChange={(e) => handleVariableChange(e, index)}
                                    autoWidth
                                >
                                    <MenuItem value="continuous" id="0">Continuous</MenuItem>
                                    <MenuItem value="discrete" id="1">Discrete</MenuItem>
                                    <MenuItem value="categorical" id="2">Categorical</MenuItem>
                                </Select>
                                </FormControl>
                                <Divider orientation='vertical' sx={{ width: '10px'}} />
                                <TextField
                                    label="Description"
                                    id="description"
                                    variant="outlined"
                                    name="description"
                                    value={element.description}
                                    onChange={(e) => handleVariableChange(e, index)}
                                    sx={{ minWidth: 250 }}
                                />
                                <Divider orientation='vertical' sx={{ width: '10px'}} />
                                {/* This is where the conditional inputs are laid out (continuous, discrete, and categorical) */}
                                    {element.varType==='continuous' ?
                                    <Fragment>
                                    {element.numLevels > 0 ? element.numLevels=0 : null}
                                    <TextField
                                        sx={{ maxWidth: 140 }}
                                        label="Low Level"
                                        required
                                        id="low"
                                        variant="outlined"
                                        type="number"
                                        name="low"
                                        value={element.low}
                                        onChange={(e) => handleVariableChange(e, index)}
                                    />
                                    <Divider orientation='vertical' sx={{ width: '10px'}} />
                                    <TextField
                                        sx={{ maxWidth: 140 }}
                                        required
                                        label="High Level"
                                        id="high"
                                        variant="outlined"
                                        type="number"
                                        name="high"
                                        value={element.high}
                                        onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px'}} />
                                    <TextField
                                        sx={{ maxWidth: 140 }}
                                        required
                                        label="Units"
                                        id="units"
                                        variant="outlined"
                                        name="units"
                                        value={element.units}
                                        onChange={(e) => handleVariableChange(e, index)}
                                    /></Fragment>
                                    : <div></div>}
                                    {element.varType==='discrete' || element.varType==='categorical' ? 
                                    <Fragment>
                                        <TextField
                                            sx={{ maxWidth: 140 }}
                                            label="# of Levels"
                                            required
                                            id="num-of-levels"
                                            variant="outlined"
                                            type="number"
                                            name="numLevels"
                                            value={element.numLevels}
                                            onChange={(e) => handleVariableChange(e, index)}
                                    /></Fragment> : <div></div>}
                                    <Divider orientation='vertical' sx={{ width: '10px'}} />
                                    {element.numLevels==='1' ?
                                    <Fragment>
                                    <TextField
                                        required
                                        sx={{ maxWidth: 140 }}
                                        label="1st Level"
                                        id="firstLevel"
                                        variant="outlined"
                                        name="firstLevel"
                                        value={element.firstLevel}
                                        onChange={(e) => handleVariableChange(e, index)}
                                    /></Fragment> : element.numLevels==='2' ? 
                                    <Fragment>
                                        <TextField
                                        required
                                        sx={{ maxWidth: 140 }}
                                        label="1st Level"
                                        id="firstLevel"
                                        variant="outlined"
                                        name="firstLevel"
                                        value={element.firstLevel}
                                        onChange={(e) => handleVariableChange(e, index)}
                                    />
                                    <Divider orientation='vertical' sx={{ width: '10px'}} />
                                        <TextField
                                        sx={{ maxWidth: 140 }}
                                        label="2nd Level"
                                        id="secondLevel"
                                        variant="outlined"
                                        name="secondLevel"
                                        value={element.secondLevel}
                                        onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px'}} /></Fragment> : 
                                    element.numLevels==='3' ?
                                    <Fragment>
                                        <TextField
                                        required
                                        sx={{ maxWidth: 140 }}
                                        label="1st Level"
                                        id="firstLevel"
                                        variant="outlined"
                                        name="firstLevel"
                                        value={element.firstLevel}
                                        onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px'}} />
                                        <TextField
                                        sx={{ maxWidth: 140 }}
                                        label="2nd Level"
                                        id="secondLevel"
                                        variant="outlined"
                                        name="secondLevel"
                                        value={element.secondLevel}
                                        onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px'}} />
                                        <TextField
                                        sx={{ maxWidth: 140 }}
                                        label="3rd Level"
                                        id="thirdLevel"
                                        variant="outlined"
                                        name="thirdLevel"
                                        value={element.thirdLevel}
                                        onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px'}} />
                                    </Fragment> : element.numLevels==='4' ?
                                    <Fragment>
                                        <TextField
                                        required
                                        sx={{ maxWidth: 140 }}
                                        label="1st Level"
                                        id="firstLevel"
                                        variant="outlined"
                                        name="firstLevel"
                                        value={element.firstLevel}
                                        onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px'}} />
                                        <TextField
                                        sx={{ maxWidth: 140 }}
                                        label="2nd Level"
                                        id="secondLevel"
                                        variant="outlined"
                                        name="secondLevel"
                                        value={element.secondLevel}
                                        onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px'}} />
                                        <TextField
                                        sx={{ maxWidth: 140 }}
                                        label="3rd Level"
                                        id="thirdLevel"
                                        variant="outlined"
                                        name="thirdLevel"
                                        value={element.thirdLevel}
                                        onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px'}} />
                                        <TextField
                                            sx={{ maxWidth: 140 }}
                                            label="4th Level"
                                            id="fourthLevel"
                                            variant="outlined"
                                            name="fourthLevel"
                                            value={element.fourthLevel}
                                            onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px'}} />
                                    </Fragment> : element.numLevels==='5' ?
                                    <Fragment>
                                    <TextField
                                        required
                                        sx={{ maxWidth: 140 }}
                                        label="1st Level"
                                        id="firstLevel"
                                        variant="outlined"
                                        name="firstLevel"
                                        value={element.firstLevel}
                                        onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px'}} />
                                        <TextField
                                        sx={{ maxWidth: 140 }}
                                        label="2nd Level"
                                        id="secondLevel"
                                        variant="outlined"
                                        name="secondLevel"
                                        value={element.secondLevel}
                                        onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px'}} />
                                        <TextField
                                        sx={{ maxWidth: 140 }}
                                        label="3rd Level"
                                        id="thirdLevel"
                                        variant="outlined"
                                        name="thirdLevel"
                                        value={element.thirdLevel}
                                        onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px'}} />
                                        <TextField
                                            sx={{ maxWidth: 140 }}
                                            label="4th Level"
                                            id="fourthLevel"
                                            variant="outlined"
                                            name="fourthLevel"
                                            value={element.fourthLevel}
                                            onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px'}} />
                                        <TextField
                                            sx={{ maxWidth: 140 }}
                                            label="5th Level"
                                            id="fifthLevel"
                                            variant="outlined"
                                            name="fifthLevel"
                                            value={element.fifthLevel}
                                            onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px'}} />
                                    </Fragment>  : element.numLevels==='6' ?
                                    <Fragment>
                                    <TextField
                                        required
                                        sx={{ maxWidth: 140 }}
                                        label="1st Level"
                                        id="firstLevel"
                                        variant="outlined"
                                        name="firstLevel"
                                        value={element.firstLevel}
                                        onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px'}} />
                                        <TextField
                                        sx={{ maxWidth: 140 }}
                                        label="2nd Level"
                                        id="secondLevel"
                                        variant="outlined"
                                        name="secondLevel"
                                        value={element.secondLevel}
                                        onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px'}} />
                                        <TextField
                                        sx={{ maxWidth: 140 }}
                                        label="3rd Level"
                                        id="thirdLevel"
                                        variant="outlined"
                                        name="thirdLevel"
                                        value={element.thirdLevel}
                                        onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px'}} />
                                        <TextField
                                            sx={{ maxWidth: 140 }}
                                            label="4th Level"
                                            id="fourthLevel"
                                            variant="outlined"
                                            name="fourthLevel"
                                            value={element.fourthLevel}
                                            onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px'}} />
                                        <TextField
                                            sx={{ maxWidth: 140 }}
                                            label="5th Level"
                                            id="fifthLevel"
                                            variant="outlined"
                                            name="fifthLevel"
                                            value={element.fifthLevel}
                                            onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px'}}/>
                                        <TextField
                                            sx={{ maxWidth: 140 }}
                                            label="6th Level"
                                            id="sixthLevel"
                                            variant="outlined"
                                            name="sixthLevel"
                                            value={element.sixthLevel}
                                            onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px'}}/>
                                    </Fragment>  : element.numLevels==='7' ?
                                    <Fragment>
                                    <TextField
                                        required
                                        sx={{ maxWidth: 140 }}
                                        label="1st Level"
                                        id="firstLevel"
                                        variant="outlined"
                                        name="firstLevel"
                                        value={element.firstLevel}
                                        onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px'}} />
                                        <TextField
                                        sx={{ maxWidth: 140 }}
                                        label="2nd Level"
                                        id="secondLevel"
                                        variant="outlined"
                                        name="secondLevel"
                                        value={element.secondLevel}
                                        onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px'}} />
                                        <TextField
                                        sx={{ maxWidth: 140 }}
                                        label="3rd Level"
                                        id="thirdLevel"
                                        variant="outlined"
                                        name="thirdLevel"
                                        value={element.thirdLevel}
                                        onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px'}} />
                                        <TextField
                                            sx={{ maxWidth: 140 }}
                                            label="4th Level"
                                            id="fourthLevel"
                                            variant="outlined"
                                            name="fourthLevel"
                                            value={element.fourthLevel}
                                            onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px'}} />
                                        <TextField
                                            sx={{ maxWidth: 140 }}
                                            label="5th Level"
                                            id="fifthLevel"
                                            variant="outlined"
                                            name="fifthLevel"
                                            value={element.fifthLevel}
                                            onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px'}}/>
                                        <TextField
                                            sx={{ maxWidth: 140 }}
                                            label="6th Level"
                                            id="sixthLevel"
                                            variant="outlined"
                                            name="sixthLevel"
                                            value={element.sixthLevel}
                                            onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px'}}/>
                                        <TextField
                                            sx={{ maxWidth: 140 }}
                                            label="7th Level"
                                            id="seventhLevel"
                                            variant="outlined"
                                            name="seventhLevel"
                                            value={element.seventhLevel}
                                            onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px'}}/>
                                    </Fragment> : element.numLevels==='8' ?
                                    <Fragment>
                                    <TextField
                                        required
                                        sx={{ maxWidth: 140 }}
                                        label="1st Level"
                                        id="firstLevel"
                                        variant="outlined"
                                        name="firstLevel"
                                        value={element.firstLevel}
                                        onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px'}} />
                                        <TextField
                                        sx={{ maxWidth: 140 }}
                                        label="2nd Level"
                                        id="secondLevel"
                                        variant="outlined"
                                        name="secondLevel"
                                        value={element.secondLevel}
                                        onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px'}} />
                                        <TextField
                                        sx={{ maxWidth: 140 }}
                                        label="3rd Level"
                                        id="thirdLevel"
                                        variant="outlined"
                                        name="thirdLevel"
                                        value={element.thirdLevel}
                                        onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px'}} />
                                        <TextField
                                            sx={{ maxWidth: 140 }}
                                            label="4th Level"
                                            id="fourthLevel"
                                            variant="outlined"
                                            name="fourthLevel"
                                            value={element.fourthLevel}
                                            onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px'}} />
                                        <TextField
                                            sx={{ maxWidth: 140 }}
                                            label="5th Level"
                                            id="fifthLevel"
                                            variant="outlined"
                                            name="fifthLevel"
                                            value={element.fifthLevel}
                                            onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px'}}/>
                                        <TextField
                                            sx={{ maxWidth: 140 }}
                                            label="6th Level"
                                            id="sixthLevel"
                                            variant="outlined"
                                            name="sixthLevel"
                                            value={element.sixthLevel}
                                            onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px'}}/>
                                        <TextField
                                            sx={{ maxWidth: 140 }}
                                            label="7th Level"
                                            id="seventhLevel"
                                            variant="outlined"
                                            name="seventhLevel"
                                            value={element.seventhLevel}
                                            onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px'}}/>
                                        <TextField
                                            sx={{ maxWidth: 140 }}
                                            label="8th Level"
                                            id="eighthLevel"
                                            variant="outlined"
                                            name="eighthLevel"
                                            value={element.eighthLevel}
                                            onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px'}}/>
                                    </Fragment> : element.numLevels==='9' ?
                                    <Fragment>
                                    <TextField
                                        required
                                        sx={{ maxWidth: 140 }}
                                        label="1st Level"
                                        id="firstLevel"
                                        variant="outlined"
                                        name="firstLevel"
                                        value={element.firstLevel}
                                        onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px'}} />
                                        <TextField
                                        sx={{ maxWidth: 140 }}
                                        label="2nd Level"
                                        id="secondLevel"
                                        variant="outlined"
                                        name="secondLevel"
                                        value={element.secondLevel}
                                        onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px'}} />
                                        <TextField
                                        sx={{ maxWidth: 140 }}
                                        label="3rd Level"
                                        id="thirdLevel"
                                        variant="outlined"
                                        name="thirdLevel"
                                        value={element.thirdLevel}
                                        onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px'}} />
                                        <TextField
                                            sx={{ maxWidth: 140 }}
                                            label="4th Level"
                                            id="fourthLevel"
                                            variant="outlined"
                                            name="fourthLevel"
                                            value={element.fourthLevel}
                                            onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px'}} />
                                        <TextField
                                            sx={{ maxWidth: 140 }}
                                            label="5th Level"
                                            id="fifthLevel"
                                            variant="outlined"
                                            name="fifthLevel"
                                            value={element.fifthLevel}
                                            onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px'}}/>
                                        <TextField
                                            sx={{ maxWidth: 140 }}
                                            label="6th Level"
                                            id="sixthLevel"
                                            variant="outlined"
                                            name="sixthLevel"
                                            value={element.sixthLevel}
                                            onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px'}}/>
                                        <TextField
                                            sx={{ maxWidth: 140 }}
                                            label="7th Level"
                                            id="seventhLevel"
                                            variant="outlined"
                                            name="seventhLevel"
                                            value={element.seventhLevel}
                                            onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px'}}/>
                                        <TextField
                                            sx={{ maxWidth: 140 }}
                                            label="8th Level"
                                            id="eighthLevel"
                                            variant="outlined"
                                            name="eighthLevel"
                                            value={element.eighthLevel}
                                            onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px'}}/>
                                        <TextField
                                            sx={{ maxWidth: 140 }}
                                            label="9th Level"
                                            id="ninthLevel"
                                            variant="outlined"
                                            name="ninthLevel"
                                            value={element.ninthLevel}
                                            onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px'}}/>
                                    </Fragment> : element.numLevels==='10' ?
                                    <Fragment>
                                    <TextField
                                        required
                                        sx={{ maxWidth: 140 }}
                                        label="1st Level"
                                        id="firstLevel"
                                        variant="outlined"
                                        name="firstLevel"
                                        value={element.firstLevel}
                                        onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px'}} />
                                        <TextField
                                        sx={{ maxWidth: 140 }}
                                        label="2nd Level"
                                        id="secondLevel"
                                        variant="outlined"
                                        name="secondLevel"
                                        value={element.secondLevel}
                                        onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px'}} />
                                        <TextField
                                        sx={{ maxWidth: 140 }}
                                        label="3rd Level"
                                        id="thirdLevel"
                                        variant="outlined"
                                        name="thirdLevel"
                                        value={element.thirdLevel}
                                        onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px'}} />
                                        <TextField
                                            sx={{ maxWidth: 140 }}
                                            label="4th Level"
                                            id="fourthLevel"
                                            variant="outlined"
                                            name="fourthLevel"
                                            value={element.fourthLevel}
                                            onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px'}} />
                                        <TextField
                                            sx={{ maxWidth: 140 }}
                                            label="5th Level"
                                            id="fifthLevel"
                                            variant="outlined"
                                            name="fifthLevel"
                                            value={element.fifthLevel}
                                            onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px'}}/>
                                        <TextField
                                            sx={{ maxWidth: 140 }}
                                            label="6th Level"
                                            id="sixthLevel"
                                            variant="outlined"
                                            name="sixthLevel"
                                            value={element.sixthLevel}
                                            onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px'}}/>
                                        <TextField
                                            sx={{ maxWidth: 140 }}
                                            label="7th Level"
                                            id="seventhLevel"
                                            variant="outlined"
                                            name="seventhLevel"
                                            value={element.seventhLevel}
                                            onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px'}}/>
                                        <TextField
                                            sx={{ maxWidth: 140 }}
                                            label="8th Level"
                                            id="eighthLevel"
                                            variant="outlined"
                                            name="eighthLevel"
                                            value={element.eighthLevel}
                                            onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px'}}/>
                                        <TextField
                                            sx={{ maxWidth: 140 }}
                                            label="9th Level"
                                            id="ninthLevel"
                                            variant="outlined"
                                            name="ninthLevel"
                                            value={element.ninthLevel}
                                            onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px'}}/>
                                        <TextField
                                            sx={{ maxWidth: 140 }}
                                            label="10th Level"
                                            id="tenthLevel"
                                            variant="outlined"
                                            name="tenthLevel"
                                            value={element.tenthLevel}
                                            onChange={(e) => handleVariableChange(e, index)}
                                    /><Divider orientation='vertical' sx={{ width: '10px'}}/>
                                    </Fragment> : <div></div>}
                                    {variablesList.length !== 1 &&
                                    (<IconButton aria-label="delete" className="remove-btn" onClick={() => removeVariableFields(index)}>
                                        <DeleteIcon />
                                    </IconButton>)}
                                    </Grid>
                                    <Divider orientation='horizontal' sx={{ height: '10px' }} />
                                    <Grid item justifySelf="center">
                                        {variablesList.length - 1 === index &&
                                        (<Button variant="contained" className="add-btn" onClick={() => addVariableFields()} size="large">
                                        Add Variable
                                        </Button>)}
                                    </Grid>
                                </div>
                        ))}
                    </Grid>
                    </Box>
            </Fragment>
        )
    } else if (card_num === 2) { // Provide Constant
        return (
            // This is where the constant input fields are inputted
            <Fragment>
                <Box sx={{ p: 3, m: 5, display: 'flex' }}>
                <Grid container direction="column" justifyContent="space-evenly" alignItems="flex-start">
                {constantsList.map((element, index) => (
                    <div key={index}>
                        <Grid item justifyContent="flex-start" alignItems="flex-start" style={{ display: "flex"}}>
                            <TextField
                            label="Constant Name"
                            id="constantName"
                            variant="outlined"
                            name="constName"
                            value={element.constName}
                            onChange={(e) => handleConstantChange(e, index)}
                            />
                            <Divider orientation='vertical' sx={{ width: '10px'}} />
                            <FormControl sx={{  minWidth: 150 }}>
                                <InputLabel variant="outlined" id="constant-type-label">Constant Type</InputLabel>
                                <Select
                                    label="Constant Type"
                                    labelId="constant-type-label"
                                    id="constant-type"
                                    name="constType"
                                    value={element.constType}
                                    onChange={(e) => handleConstantChange(e, index)}
                                    autoWidth
                                >
                                    <MenuItem value="numeric" id="0">Numeric</MenuItem>
                                    <MenuItem value="discrete" id="1">Discrete</MenuItem>
                                    <MenuItem value="categorical" id="2">Categorical</MenuItem>
                                </Select>
                            </FormControl>
                            <Divider orientation='vertical' sx={{ width: '10px'}} />
                            <TextField
                                sx={{ maxWidth: 160 }}
                                label="Constant Amount"
                                id="constantAmount"
                                variant="outlined"
                                type="number"
                                name="constAmount"
                                value={element.constAmount}
                                onChange={(e) => handleConstantChange(e, index)}
                            />
                            {constantsList.length !== 1 &&
                                (<IconButton aria-label="delete" className="remove-btn" onClick={() => removeConstantFields(index)}>
                                    <DeleteIcon />
                                </IconButton>)}
                        </Grid>
                        <Divider orientation='horizontal' sx={{ height: '10px' }} />
                        <Grid item justifyContent="center" alignItems="center" style={{ display: "flex"}}>
                        {constantsList.length - 1 === index &&
                            (<Button variant="contained" className="add-btn" onClick={() => addConstantFields()} size="large">
                            Add Constant
                            </Button>)}
                        </Grid>
                    </div>
                ))} 
            </Grid>
        </Box>        
        </Fragment>
        )
    } else if (card_num === 3) { // Provide Independent Variables
        return(
        <Fragment>
            <Box sx={{ p: 3, m: 5, display: 'flex' }}>
                <Grid container direction="column" justifyContent="space-evenly" alignItems="flex-start">
                {indepsList.map((element, index) => (
              <div key={index}>
              <Grid item justifyContent="flex-start" alignItems="flex-start" style={{ display: "flex"}}>
                <TextField
                  label="Variable Name"
                  id="indepName"
                  variant="outlined"
                  name="indepName"
                  value={element.indepName}
                  onChange={(e) => handleIndepChange(e, index)}
                />
                <Divider orientation='vertical' sx={{ width: '10px'}} />
                <FormControl sx={{ minWidth: 160 }}>
                <InputLabel variant="outlined" id="goal-label">Goal</InputLabel>
                <Select
                    label="Goal"
                    labelId="goal-label"
                    id="goal"
                    value={element.goal}                       
                    name="goal"
                    onChange={(e) => handleIndepChange(e, index)}
                    autoWidth
                >
                    <MenuItem value="minimize" id="0">Minimize</MenuItem>
                    <MenuItem value="target" id="1">Target</MenuItem>
                    <MenuItem value="maximize" id="2">Maximize</MenuItem>
                </Select>
                </FormControl>
                {indepsList.length !== 1 &&
                  (<IconButton aria-label="delete" className="remove-btn" onClick={() => removeIndepFields(index)}>
                    <DeleteIcon />
                  </IconButton>)}
                  </Grid>
                  <Divider orientation='horizontal' sx={{ height: '10px' }} />
                  <Grid item justifyContent="start" alignItems="flex-start" style={{ display: "flex"}}>
                {indepsList.length - 1 === index &&
                (<Button variant="contained" className="add-btn" onClick={() => addIndepFields()} size="large">
                  Add Variable
                  </Button>)}
            </Grid>
          </div>
          ))}
                </Grid>
            </Box>
        </Fragment>
        )
    } else if (card_num === 4) { // Intuition of variable behavior
        return (
            <Fragment>
                <Box sx={{ p: 3, display: 'block' }} align="left">
                    <h6>Do you expect ingredient or variable interaction?</h6>
                        <FormControl>
                            <FormGroup>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="interactions"
                                value={inputs.interactions}
                                onChange={handleInputChange}
                            >
                                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                <FormControlLabel value="No" control={<Radio />} label="No" />
                                <FormControlLabel value="Not Sure" control={<Radio />} label="Not Sure" />
                            </RadioGroup>
                        <h6>Do you expect quadratic effects of changing ingredients or variables?</h6>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="quadEffects"
                                value={inputs.quadEffects}
                                onChange={handleInputChange}
                            >
                                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                <FormControlLabel value="No" control={<Radio />} label="No" />
                                <FormControlLabel value="Not Sure" control={<Radio />} label="Not Sure" />
                            </RadioGroup>
                        <h6>Do you wish to capture these interactions and quadratic effects?</h6>
                            <FormControlLabel
                            control={<Android12Switch checked={inputs.capture} onChange={handleSwitchChange} name="capture" />}                           
                            />
                        <h6>This will add X number of additional samples to your design. Confirm increase # of samples?</h6>
                            <FormControlLabel
                            control={<Android12Switch checked={inputs.confirmIncrease} onChange={handleSwitchChange} name="confirmIncrease" />}                           
                            />
                            </FormGroup>
                        </FormControl>
                    </Box>
            </Fragment>
        )
    } else if (inputs.usingVAS === true && card_num === 5) { // Optional questions
        return (
            <Fragment>
                {/* if maxWidth is set to 1000, it is moved to the right more */}
                <Card elevation={0} sx={{ maxWidth: 1371 }} id="5" align="left">
                    <CardContent>
                    <h5>Do you want to use dynamic modeling?</h5>
                        <FormControl>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="useDynamic"
                                value={inputs.useDynamic}
                                onChange={handleInputChange}
                            >
                                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                <FormControlLabel value="No" control={<Radio />} label="No" />
                            </RadioGroup>
                        </FormControl>
                        <Divider orientation='horizontal' sx={{ height: '10px' }} />
                        {inputs.useDynamic==='Yes' ?
                        <Fragment>
                            <h5>How many samples per batch and how many total batches?</h5>
                            <Divider orientation='horizontal' sx={{ height: '10px' }} />
                            <TextField
                                    sx={{ maxWidth:160 }}
                                    label="Sample Number"
                                    required
                                    id="sample-number"
                                    variant="outlined"
                                    type="number"
                                    name="sampleNumber"
                                    value={inputs.sampleNumber}
                                    onChange={handleInputChange}
                                />
                            <Divider orientation='vertical' sx={{ width: '10px', display: 'inline-flex'}} />
                            <TextField
                                    sx={{ maxWidth:160 }}
                                    label="Total Batches"
                                    required
                                    id="total-batches"
                                    variant="outlined"
                                    type="number"
                                    name="totalBatches"
                                    value={inputs.totalBatches}
                                    onChange={handleInputChange}
                                />
                        {/* This is not updating the sample number */}
                        <Divider orientation='horizontal' sx={{ height: '10px' }} /></Fragment> : <Fragment>{inputs.sampleNumber=''}</Fragment>}
                    <h5>ATOM recommends X # of samples. Do you approve of this?</h5>
                    <FormControl>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="approve"
                            value={inputs.approve}
                            onChange={handleInputChange}
                        >
                            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                    <Divider orientation='horizontal' sx={{ height: '10px' }} />
                    {inputs.approve==='Yes' ?
                    <Fragment>
                        <h5>Would you like to increase or decrease the number of samples?</h5>
                        <RadioGroup
                                row
                                required
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="incOrDec"
                                value={inputs.incOrDec}
                                onChange={handleInputChange}
                            >
                                <FormControlLabel value="Increase" control={<Radio />} label="Increase" />
                                <FormControlLabel value="Decrease" control={<Radio />} label="Decrease" />
                        </RadioGroup>
                        <TextField
                                sx={{ maxWidth: 160 }}
                                label="# of Samples"
                                id="numOfSamplesChanged"
                                variant="outlined"
                                type="number"
                                name="numOfSamplesChange"
                                value={inputs.numOfSamplesChange}
                                onChange={handleInputChange}
                            />
                    </Fragment> : <div></div>}
                    {inputs.approve==='No' ? 
                    <Fragment>
                        <h5>Do you want to use ATOM to administer this survey?</h5>
                        <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="ATOMAdminister"
                                value={inputs.ATOMAdminister}
                                onChange={handleInputChange}
                            >
                                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                <FormControlLabel value="No" control={<Radio />} label="No" />
                            </RadioGroup>
                        </Fragment> : <div></div>}
                    </CardContent>
                </Card>
            </Fragment>
        )
    } else if (inputs.usingVAS === false && card_num === 5) {
        return (
            <Fragment>
                <h1>You chose not to use a VAS Air and may continue with the survey.</h1>
            </Fragment>
        )
    } else if (card_num === 6) { // End of Survey Preview Responses
        return (
            <>
                <Box sx={{ p: 3, display: 'block' }} align="left">
                    <h6><b>Continuous Variables:</b> {inputs.contVars.toString().toUpperCase()}</h6>
                    <h6><b>Categorical Variables:</b> {inputs.catVars.toString().toUpperCase()}</h6>
                    <h6><b>Discrete Numeric:</b> {inputs.discNum.toString().toUpperCase()}</h6>
                    <h6><b>Using a VAS:</b> {inputs.usingVAS.toString().toUpperCase()}</h6>
                    <h6><b>Need to add a certain amount to variables or ingredients:</b> {inputs.addAmount.toString().toUpperCase()}</h6>
                    <h6><b>Indep Variables:</b> </h6>
                    <h6><b>Constants Variables:</b> </h6>
                    <h6><b>Dep Variables:</b> </h6>
                    <h6><b>Expecting Variable Interactions:</b> {inputs.interactions}</h6>
                    <h6><b>Expecting quadratic effects:</b> {inputs.quadEffects}</h6>
                    <h6><b>Do you want to capture interactions and effects:</b> {inputs.capture.toString().toUpperCase()}</h6>
                    <h6><b>Confirm increased number of samples:</b> {inputs.confirmIncrease.toString().toUpperCase()}</h6>
                    <h6><b>Using dynamic modeling:</b> {inputs.useDynamic}</h6>
                    <h6><b>Samples per batch:</b> {inputs.sampleNumber}</h6>
                    <h6><b>Total number of batches:</b> {inputs.totalBatches}</h6>
                    <h6><b>Approve ATOM recommended number of samples:</b> {inputs.approve}</h6>
                    <h6><b>Increase or decrease number of samples:</b> {inputs.incOrDec}</h6>
                    <h6><b>Increase or decrease number of samples by how much:</b> {inputs.numOfSamplesChange}</h6>
                </Box>
            </>
        )
    }
};

export default BasicMenuSurvey;