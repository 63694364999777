import * as React from 'react';
import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { InputLabel } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';

const DesignType = ({ setConfigInputs }) => {

    const [inputs, setInputs] = useState({
            design_type: "", 
            required_terms: "", 
            optional_terms: ""
        })

    
    useEffect(() => {
        
        let design_type = inputs.design_type;
        let required_terms = inputs.required_terms; 
        let optional_terms = inputs.optional_terms;
       
        setConfigInputs( prevState  => ({ ...prevState,   design_type: design_type }));
        setConfigInputs( prevState  => ({ ...prevState,   required_terms: required_terms }));
        setConfigInputs( prevState  => ({ ...prevState,   optional_terms: optional_terms }));

        }, [inputs]);


    // What saves the survey setting values once there is a change in the input field
    const handleInputChange = (e) => {
        const { name, value } = e.target;


        // if (e.target.name === "required_terms" && e.target.value === "Main Effects") {
        //     inputs.optional_terms = "";
        // }
        // else if (e.target.name === "required_terms" && e.target.value === "Main + Interactions") {
        //     inputs.optional_terms = "";
        // } else if (e.target.name === "required_terms" && e.target.value === "Main + Quadratic") {
        //     inputs.optional_terms = "";
        // }

       
        setInputs({
        ...inputs,
        [name]: value,
        });


        // if a user select DOE (D-Optimality, set the optional terms equal to required terms)
        if (inputs.design_type === "DOE (D-Optimality") {

            let required_terms = inputs.required_terms;
            setInputs({...inputs, optional_terms: required_terms });

        }

    };


    return (
        <Grid container spacing={2} style={{ marginTop: '12px' }}>            
            <Grid item xs={12}>
                <h5>Design Type</h5>
            </Grid>
            <Grid item>
                <FormControl sx={{ minWidth: 150 }}>
                    <InputLabel variant="outlined" id="design-type-label">Design Type</InputLabel>
                    <Select
                        required
                        label="Design Type *"
                        labelId="design-type-label"
                        id="design-type"
                        value={inputs.design_type}
                        name="design_type"
                        onChange={handleInputChange}
                    >
                        <MenuItem value="Bayesian">Bayesian</MenuItem>
                        <MenuItem value="DOE (D-Optimality)">DOE (D-Optimality)</MenuItem>
                        <MenuItem value="Definitive Screening">Definitive Screening</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            {inputs.design_type === "Bayesian" ?
            <>
                <Grid item>
                    <FormControl sx={{ minWidth: 280 }}>
                        <InputLabel variant="outlined" id="required-terms-label">Required Terms</InputLabel>
                        <Select
                            required
                            label="Required Terms *"
                            labelId="required-terms-label"
                            id="required-terms"
                            value={inputs.required_terms}
                            name="required_terms"
                            onChange={handleInputChange}
                        >
                            <MenuItem value="Main Effects">Main Effects</MenuItem>
                            <MenuItem value="Main + Interactions">Main + Interactions</MenuItem>
                            <MenuItem value="Main + Quadratic">Main + Quadratic</MenuItem>
                            <MenuItem value="Main + Interactions + Quadratic">Main + Interactions + Quadratic</MenuItem>
                            <MenuItem value="Custom">Custom</MenuItem>
                        </Select>
                    </FormControl>
                    </Grid>
                    {inputs.required_terms === "Custom" ?
                    <>
                    <div hidden>{inputs.optional_terms = "Custom"}</div>
                        <Grid item>
                            <FormControl sx={{ minWidth: 280 }}>
                                <InputLabel variant="outlined" id="optional-terms-label">Optional Terms</InputLabel>
                                <Select
                                    required
                                    label="Optional Terms *"
                                    labelId="optional-terms-label"
                                    id="optional-terms"
                                    value={inputs.optional_terms}
                                    name="optional_terms"
                                    onChange={handleInputChange}
                                >
                                    <MenuItem value="Main Effects" disabled>Main Effects</MenuItem>
                                    <MenuItem value="Main + Interactions" disabled>Main + Interactions</MenuItem>
                                    <MenuItem value="Main + Quadratic" disabled>Main + Quadratic</MenuItem>
                                    <MenuItem value="Main + Interactions + Quadratic" disabled>Main + Interactions + Quadratic</MenuItem>
                                    <MenuItem value="Custom">Custom</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </>
                    : inputs.required_terms === "Main + Interactions + Quadratic" ?
                    <>
                    <div hidden>{inputs.optional_terms = "Main + Interactions + Quadratic"}</div>
                        <Grid item>
                            <FormControl sx={{ minWidth: 280 }}>
                                <InputLabel variant="outlined" id="optional-terms-label">Optional Terms</InputLabel>
                                <Select
                                    required
                                    label="Optional Terms *"
                                    labelId="optional-terms-label"
                                    id="optional-terms"
                                    value={inputs.optional_terms}
                                    name="optional_terms"
                                    onChange={handleInputChange}
                                >
                                    <MenuItem value="Main Effects" disabled>Main Effects</MenuItem>
                                    <MenuItem value="Main + Interactions" disabled>Main + Interactions</MenuItem>
                                    <MenuItem value="Main + Quadratic" disabled>Main + Quadratic</MenuItem>
                                    <MenuItem value="Main + Interactions + Quadratic" defaultValue>Main + Interactions + Quadratic</MenuItem>
                                    <MenuItem value="Custom" disabled>Custom</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        </>
                    : inputs.required_terms === "Main + Interactions" ?
                    <>
                        <Grid item>
                            <FormControl sx={{ minWidth: 280 }}>
                                <InputLabel variant="outlined" id="optional-terms-label">Optional Terms</InputLabel>
                                <Select
                                    required
                                    label="Optional Terms *"
                                    labelId="optional-terms-label"
                                    id="optional-terms"
                                    value={inputs.optional_terms}
                                    name="optional_terms"
                                    onChange={handleInputChange}
                                >
                                    <MenuItem value="Main Effects" disabled>Main Effects</MenuItem>
                                    <MenuItem value="Main + Interactions" defaultValue>Main + Interactions</MenuItem>
                                    <MenuItem value="Main + Quadratic" disabled>Main + Quadratic</MenuItem>
                                    <MenuItem value="Main + Interactions + Quadratic">Main + Interactions + Quadratic</MenuItem>
                                    <MenuItem value="Custom" disabled>Custom</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </>
                    : inputs.required_terms === "Main + Quadratic" ?
                    <>
                        <Grid item>
                            <FormControl sx={{ minWidth: 280 }}>
                                <InputLabel variant="outlined" id="optional-terms-label">Optional Terms</InputLabel>
                                <Select
                                    required
                                    label="Optional Terms *"
                                    labelId="optional-terms-label"
                                    id="optional-terms"
                                    value={inputs.optional_terms}
                                    name="optional_terms"
                                    onChange={handleInputChange}
                                >
                                    <MenuItem value="Main Effects" disabled>Main Effects</MenuItem>
                                    <MenuItem value="Main + Interactions" disabled>Main + Interactions</MenuItem>
                                    <MenuItem value="Main + Quadratic" defaultValue>Main + Quadratic</MenuItem>
                                    <MenuItem value="Main + Interactions + Quadratic">Main + Interactions + Quadratic</MenuItem>
                                    <MenuItem value="Custom" disabled>Custom</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </>
                    :
                    <>
                        <Grid item>
                            <FormControl sx={{ minWidth: 280 }}>
                                <InputLabel variant="outlined" id="optional-terms-label">Optional Terms</InputLabel>
                                <Select
                                    required
                                    label="Optional Terms *"
                                    labelId="optional-terms-label"
                                    id="optional-terms"
                                    value={inputs.optional_terms}
                                    name="optional_terms"
                                    onChange={handleInputChange}
                                >
                                    <MenuItem value="Main Effects">Main Effects</MenuItem>
                                    <MenuItem value="Main + Interactions">Main + Interactions</MenuItem>
                                    <MenuItem value="Main + Quadratic">Main + Quadratic</MenuItem>
                                    <MenuItem value="Main + Interactions + Quadratic">Main + Interactions + Quadratic</MenuItem>
                                    <MenuItem value="Custom" disabled>Custom</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </> }
                </>
            : inputs.design_type === "DOE (D-Optimality)" ?
                <>
                {inputs.optional_terms = null}
                    <Grid item xs={2.25}>
                        <FormControl sx={{ minWidth: 280 }}>
                            <InputLabel variant="outlined" id="required-terms-label">Required Terms</InputLabel>
                            <Select
                                required
                                label="Required Terms *"
                                labelId="required-terms-label"
                                id="required-terms"
                                value={inputs.required_terms}
                                name="required_terms"
                                onChange={handleInputChange}
                            >
                                <MenuItem value="Main Effects">Main Effects</MenuItem>
                                <MenuItem value="Main + Interactions">Main + Interactions</MenuItem>
                                <MenuItem value="Main + Quadratic">Main + Quadratic</MenuItem>
                                <MenuItem value="Main + Interactions + Quadratic">Main + Interactions + Quadratic</MenuItem>
                                <MenuItem value="Custom">Custom</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </>
            : inputs.design_type === "Definitive Screening" ?
                <>
                <Grid item xs={2.25}>
                    <FormControl sx={{ minWidth: 280 }} disabled>
                        <InputLabel variant="outlined" id="required-terms-label">Required Terms</InputLabel>
                        <Select
                            required
                            label="Required Terms *"
                            labelId="required-terms-label"
                            id="required-terms"
                            value={inputs.required_terms = "Main Effects"}
                            name="required_terms"
                            onChange={handleInputChange}
                        >
                            <MenuItem value="Main Effects" defaultChecked>Main Effects</MenuItem>
                            <MenuItem value="Main + Interactions">Main + Interactions</MenuItem>
                            <MenuItem value="Main + Quadratic">Main + Quadratic</MenuItem>
                            <MenuItem value="Main + Interactions + Quadratic">Main + Interactions + Quadratic</MenuItem>
                            <MenuItem value="Custom">Custom</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Divider orientation='vertical' sx={{ width: '20px' }} />
                <Grid item xs={2}>
                    <FormControl sx={{ minWidth: 280 }} disabled>
                        <InputLabel variant="outlined" id="optional-terms-label">Optional Terms</InputLabel>
                        <Select
                            required
                            label="Optional Terms *"
                            labelId="optional-terms-label"
                            id="optional-terms"
                            value={inputs.optional_terms = "Main + Interactions + Quadratic"}
                            name="optional_terms"
                            onChange={handleInputChange}
                        >
                            <MenuItem value="Main Effects">Main Effects</MenuItem>
                            <MenuItem value="Main + Interactions">Main + Interactions</MenuItem>
                            <MenuItem value="Main + Quadratic">Main + Quadratic</MenuItem>
                            <MenuItem value="Main + Interactions + Quadratic" defaultChecked>Main + Interactions + Quadratic</MenuItem>
                            <MenuItem value="Custom">Custom</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                </>
            : <div></div>}
        </Grid>
    );
}


export default DesignType;