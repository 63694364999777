import * as React from 'react';
import { useState, useRef, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Container } from '@mui/material';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { SketchPicker } from 'react-color';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import BrightBerryBackground from '../styleEditorImages/Bright-Berry-Background.png';
import BrightBerryPrimary from '../styleEditorImages/Bright-Berry-Primary.jpg';
import CitrusBackground from '../styleEditorImages/citrus-gradient-bg.png';
import CitrusPrimary from '../styleEditorImages/Citrus-Primary.jpg';
import BlackBackground from '../styleEditorImages/dark-gradient-bg.png';
import BlackPrimary from '../styleEditorImages/black-primary.jpg';
import LightBackground from '../styleEditorImages/light-gradient-bg.png';
import LightPrimary from '../styleEditorImages/light-primary.png';
import LuxeBackground from '../styleEditorImages/luxe-bg.jpg';
import LuxePrimary from '../styleEditorImages/Luxe-Primary.jpg';
import TropicalBackground from '../styleEditorImages/tropical-bg.jpg';
import TropicalPrimary from '../styleEditorImages/Tropical-Primary.jpg';

const drawerWidth = "27.5rem";
//const drawerWidth = "24rem"; // Change the value to 24 rem to fix dead space.
const drawerHeight = "34.375rem";

// Checking if the hex code string is a valid hex code.
const isValidHexColor = (colorString) => /^#([0-9A-F]{3}){1,2}$/i.test(colorString);

const StyleEditor = ({ setStyleEditor }) => {

    const [inputs, setInputs] = useState({
            survey_theme: "Bright Berry",
            page_background: "#1976d2",
            page_title: "#1976d2",
            description_text: "#1976d2",
            question_text: "#1976d2",
            answer_text: "#1976d2",
            answer_description: "#1976d2",
            error_text: "#1976d2",
            progress_indicator: "#1976d2",
            buttons: "#1976d2",
            word_cloud: "#1976d2",
            recommendation_name: "#1976d2",
            recommendation_title: "#1976d2",
            page_title_bold: true,
            page_title_italic: true,
            description_text_bold: false, 
            description_text_italic: true,
            question_bold: true,
            question_italic: true,
            answer_bold: true,
            answer_italic: true,
            answer_description_bold: true,
            answer_description_italic: true,
            error_bold: true,
            error_italic: true,
            recommendation_name_bold: true,
            recommendation_name_italic: true,
            header_bold: true,
            header_italic: true,
            survey_background: null
        });


    
    
    const style_editor = inputs;
    setStyleEditor(inputs)
    console.log("style_editor")
    console.log(style_editor)


    // What saves the survey setting values once there is a change in the input field
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputs({
            ...inputs,
            [name] : value,
        });
    };

    const Android12Switch = styled(Switch)(({ theme }) => ({
        padding: 8,
        '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
        },
        '&:before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
            theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12,
        },
        '&:after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
            theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M19,13H5V11H19V13Z" /></svg>')`,
            right: 12,
        },
        },
        '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
        },
    }));

    // Used for the switch changing
    const handleSwitchChange = (e) => {
        setInputs(
        {
            ...inputs,
            [e.target.name]: e.target.checked
        }
        )
    }

    // These next few lines are used for the color choices.
    const [showColorPickers, setShowColorPickers] = useState({
        page_background: false,
        page_title: false,
        description_text: false,
        question_text: false,
        answer_text: false,
        answer_description: false,
        error_text: false,
        progress_indicator: false,
        buttons: false,
        word_cloud: false,
        recommendation_name: false,
        recommendation_title: false
      });

      const pickersRef = useRef([]);

      const handleColorButtonClick = (fieldName) => {
        setShowColorPickers((prevShowColorPickers) => ({
          ...prevShowColorPickers,
          [fieldName]: true,
        }));
      };

      const handleClosePicker = (fieldName) => {
        setShowColorPickers((prevShowColorPickers) => ({
          ...prevShowColorPickers,
          [fieldName]: false,
        }));
      };

      const handleColorChange = (color, fieldName) => {
        setInputs((prevInputs) => ({
          ...prevInputs,
          [fieldName]: color.hex,
        }));
      };

      const handleClickOutside = (event) => {
        if (pickersRef.current && pickersRef.current.length > 0) {
          for (let i = 0; i < pickersRef.current.length; i++) {
            if (pickersRef.current[i] && !pickersRef.current[i].contains(event.target)) {
              handleClosePicker(pickersRef.current[i].getAttribute('data-field-name'));
            }
          }
        }
    };

      useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);

      const fieldsWithPicker = Object.keys(showColorPickers);

    // These are used for the styling of the Survey Themes
    const containerStyle = {
        position: 'relative',
        width: '90%',
        height: '90%',
        overflow: 'hidden' // to ensure the overlay image doesn't extend beyond the container
    };

    const baseImageStyle = {
        display: 'block', // to ensure the image takes up the entire width of the container
        width: '200%',
        height: '100%'
    };

    const middleLayerStyle = (backgroundColor) => ({
        position: 'absolute',
        bottom: '4%',
        left: '13%',
        width: '75%',
        height: '90%', // 1/2 height of the container, so it covers 1/2 of the image below
        objectFit: 'contain', // to ensure the image covers the area without distortion
        backgroundColor: backgroundColor,
        zIndex: 1 // to ensure this div appears between base and overlay image
    });

    const overlayImageStyle = {
        position: 'absolute',
        bottom: '0%',
        width: '100%',
        height: '38%', // 1/2 height of the container, so it covers 1/2 of the image below
        zIndex: 2, // to ensure this image appears on top of the other one
    };

    const textStyle = {

    }

    return (
        <Box sx={{ display: 'flex' }}>
          {/* The drawer is where all of the survey settings are located */}
          <Drawer
            variant="permanent"
            containerstyle={{ height: 'calc(100%-64px)', top: 64 }}
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              [`& .MuiDrawer-paper`]: { width: drawerWidth, height: drawerHeight, boxSizing: 'border-box', position: 'relative', zIndex: 'tootip' },
            }}
          >

            {/* This box holds all of the variable input fields. */}
            <Box sx={{ overflow: 'auto', position: 'absolute', top: 0, left: 30 }}>
                    <Box display="flex" alignItems="end">
                    <FormControl>
                        <FormLabel id="survey-themes-label"><b>Survey Themes</b></FormLabel>
                        <Divider orientation='horizontal' sx={{ height: '10px' }} />
                        <RadioGroup
                            column
                            aria-labelledby="survey-themes-label"
                            name="survey_theme"
                            value={inputs.survey_theme}
                            onChange={handleInputChange}
                        >
                            <FormControlLabel value="Bright Berry" control={<Radio />} label="Bright Berry" />
                            <FormControlLabel value="Citrus" control={<Radio />} label="Citrus" />
                            <FormControlLabel value="Black Givaudan" control={<Radio />} label="Black Givaudan" />
                            <FormControlLabel value="Light" control={<Radio />} label="Light" />
                            <FormControlLabel value="Luxe" control={<Radio />} label="Luxe" />
                            <FormControlLabel value="Tropical" control={<Radio />} label="Tropical" />
                        </RadioGroup>
                    </FormControl>
                    </Box>
                <Divider orientation='horizontal' sx={{ height: '20px' }} />
                <Box display="flex" alignItems="end">
                    <FormControl>
                        <FormLabel id="customization-options-label"><b>Customization Options</b></FormLabel>
                        <Divider orientation='horizontal' sx={{ height: '10px' }} />
                        <Accordion>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            >
                            <Typography>Colors</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                            <List>
                            {fieldsWithPicker.map((fieldName) => (
                                <ListItem key={fieldName} disablePadding>
                                    <ListItemButton onClick={() => handleColorButtonClick(fieldName)}>
                                        <ListItemText primary={fieldName.split('_').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')} value={inputs[fieldName]}/>
                                        <ColorLensIcon style={{color: inputs[fieldName]}} />
                                    </ListItemButton>
                                    {showColorPickers[fieldName] && (
                                        <div ref={(el) => (pickersRef.current[fieldName] = el)} data-field-name={fieldName}>
                                            <SketchPicker color={isValidHexColor(inputs[fieldName]) ? inputs[fieldName] : '#1976d2'} onChange={(color) => handleColorChange(color, fieldName)} onClose={() => handleClosePicker(fieldName)} />
                                            <center><button onClick={() => handleClosePicker(fieldName)}>Close</button></center>
                                        </div>
                                    )}
                                </ListItem>
                            ))}
                            </List>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            >
                            <Typography>Typography</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Accordion>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                <Typography>Page Title</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                <FormControl>
                                    <FormGroup>
                                    <FormControlLabel
                                        sx={{ p: 0.5 }}
                                        label="Bold"
                                        labelPlacement="start"
                                        control={<Android12Switch checked={inputs.page_title_bold}
                                        onChange={handleSwitchChange} name="page_title_bold"/>}
                                    />
                                    </FormGroup>
                                </FormControl>
                                <FormControl>
                                    <FormGroup>
                                    <FormControlLabel
                                        sx={{ p: 0.5 }}
                                        label="Italic"
                                        labelPlacement="start"
                                        control={<Android12Switch checked={inputs.page_title_italic}
                                        onChange={handleSwitchChange} name="page_title_italic"/>}
                                    />
                                    </FormGroup>
                                </FormControl>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                <Typography>Description Text</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                <FormControl>
                                    <FormGroup>
                                    <FormControlLabel
                                        sx={{ p: 0.5 }}
                                        label="Bold"
                                        labelPlacement="start"
                                        control={<Android12Switch checked={inputs.description_text_bold}
                                        onChange={handleSwitchChange} name="description_text_bold"/>}
                                    />
                                    </FormGroup>
                                </FormControl>
                                <FormControl>
                                    <FormGroup>
                                    <FormControlLabel
                                        sx={{ p: 0.5 }}
                                        label="Italic"
                                        labelPlacement="start"
                                        control={<Android12Switch checked={inputs.description_text_italic}
                                        onChange={handleSwitchChange} name="description_text_italic"/>}
                                    />
                                    </FormGroup>
                                </FormControl>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                <Typography>Question Text</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                <FormControl>
                                    <FormGroup>
                                    <FormControlLabel
                                        sx={{ p: 0.5 }}
                                        label="Bold"
                                        labelPlacement="start"
                                        control={<Android12Switch checked={inputs.question_bold}
                                        onChange={handleSwitchChange} name="question_bold"/>}
                                    />
                                    </FormGroup>
                                </FormControl>
                                <FormControl>
                                    <FormGroup>
                                    <FormControlLabel
                                        sx={{ p: 0.5 }}
                                        label="Italic"
                                        labelPlacement="start"
                                        control={<Android12Switch checked={inputs.question_italic}
                                        onChange={handleSwitchChange} name="question_italic"/>}
                                    />
                                    </FormGroup>
                                </FormControl>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                <Typography>Answer Text</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                <FormControl>
                                    <FormGroup>
                                    <FormControlLabel
                                        sx={{ p: 0.5 }}
                                        label="Bold"
                                        labelPlacement="start"
                                        control={<Android12Switch checked={inputs.answer_bold}
                                        onChange={handleSwitchChange} name="answer_bold"/>}
                                    />
                                    </FormGroup>
                                </FormControl>
                                <FormControl>
                                    <FormGroup>
                                    <FormControlLabel
                                        sx={{ p: 0.5 }}
                                        label="Italic"
                                        labelPlacement="start"
                                        control={<Android12Switch checked={inputs.answer_italic}
                                        onChange={handleSwitchChange} name="answer_italic"/>}
                                    />
                                    </FormGroup>
                                </FormControl>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                <Typography>Answer Description Text</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                <FormControl>
                                    <FormGroup>
                                    <FormControlLabel
                                        sx={{ p: 0.5 }}
                                        label="Bold"
                                        labelPlacement="start"
                                        control={<Android12Switch checked={inputs.answer_description_bold}
                                        onChange={handleSwitchChange} name="answer_description_bold"/>}
                                    />
                                    </FormGroup>
                                </FormControl>
                                <FormControl>
                                    <FormGroup>
                                    <FormControlLabel
                                        sx={{ p: 0.5 }}
                                        label="Italic"
                                        labelPlacement="start"
                                        control={<Android12Switch checked={inputs.answer_description_italic}
                                        onChange={handleSwitchChange} name="answer_description_italic"/>}
                                    />
                                    </FormGroup>
                                </FormControl>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                <Typography>Error Text</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                <FormControl>
                                    <FormGroup>
                                    <FormControlLabel
                                        sx={{ p: 0.5 }}
                                        label="Bold"
                                        labelPlacement="start"
                                        control={<Android12Switch checked={inputs.error_bold}
                                        onChange={handleSwitchChange} name="error_bold"/>}
                                    />
                                    </FormGroup>
                                </FormControl>
                                <FormControl>
                                    <FormGroup>
                                    <FormControlLabel
                                        sx={{ p: 0.5 }}
                                        label="Italic"
                                        labelPlacement="start"
                                        control={<Android12Switch checked={inputs.error_italic}
                                        onChange={handleSwitchChange} name="error_italic"/>}
                                    />
                                    </FormGroup>
                                </FormControl>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                <Typography>Recommendation Name</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                <FormControl>
                                    <FormGroup>
                                    <FormControlLabel
                                        sx={{ p: 0.5 }}
                                        label="Bold"
                                        labelPlacement="start"
                                        control={<Android12Switch checked={inputs.recommendation_name_bold}
                                        onChange={handleSwitchChange} name="recommendation_name_bold"/>}
                                    />
                                    </FormGroup>
                                </FormControl>
                                <FormControl>
                                    <FormGroup>
                                    <FormControlLabel
                                        sx={{ p: 0.5 }}
                                        label="Italic"
                                        labelPlacement="start"
                                        control={<Android12Switch checked={inputs.recommendation_name_italic}
                                        onChange={handleSwitchChange} name="recommendation_name_italic"/>}
                                    />
                                    </FormGroup>
                                </FormControl>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                <Typography>Recommendation Header</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                <FormControl>
                                    <FormGroup>
                                    <FormControlLabel
                                        sx={{ p: 0.5 }}
                                        label="Bold"
                                        labelPlacement="start"
                                        control={<Android12Switch checked={inputs.header_bold}
                                        onChange={handleSwitchChange} name="header_bold"/>}
                                    />
                                    </FormGroup>
                                </FormControl>
                                <FormControl>
                                    <FormGroup>
                                    <FormControlLabel
                                        sx={{ p: 0.5 }}
                                        label="Italic"
                                        labelPlacement="start"
                                        control={<Android12Switch checked={inputs.header_italic}
                                        onChange={handleSwitchChange} name="header_italic"/>}
                                    />
                                    </FormGroup>
                                </FormControl>
                                </AccordionDetails>
                            </Accordion>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            >
                            <Typography>Background Image</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <input type="file" accept=".png,.jpg,.jpeg,.bmp" id="survey_background" name="survey_background" value={inputs.survey_background} /*onChange={handleInputChange}*//>
                            </AccordionDetails>
                        </Accordion>
                    </FormControl>
                </Box>
              <Divider orientation='horizontal' sx={{ height: '20px' }} />
            </Box>
          </Drawer>
          <Toolbar />
          <Box sx={{ height: '34.375rem', width: '56.5rem', overflow: 'hidden' }}>
            <div style={{ justifyContent: 'center', width: 'auto', overflow: 'hidden' }}><center><h1>Primary Survey Question</h1></center></div>
            {inputs.survey_theme === "Bright Berry" ?
                <div style={containerStyle}>
                    <div style={baseImageStyle}>
                        <img src={BrightBerryBackground} alt="Bright Berry Background" />
                        <div style={middleLayerStyle('#ffe6f0')}>
                            <img style={overlayImageStyle} src={BrightBerryPrimary} alt="Bright Berry Primary" />
                        </div>
                    </div>
                </div>
            : inputs.survey_theme === "Citrus" ?
                <div style={containerStyle}>
                    <div style={baseImageStyle}>
                        <img src={CitrusBackground} alt="Citrus Background" />
                        <div style={middleLayerStyle('#fffbeb')}>
                            <img style={overlayImageStyle} src={CitrusPrimary} alt="Citrus Primary" />
                        </div>
                    </div>
                </div>
            : inputs.survey_theme === "Black Givaudan" ?
                <div style={containerStyle}>
                    <div style={baseImageStyle}>
                        <img src={BlackBackground} alt="Black Background" />
                        <div style={middleLayerStyle('#000000')}>
                            <img style={overlayImageStyle} src={BlackPrimary} alt="Black Primary" />
                        </div>
                    </div>
                </div>
            : inputs.survey_theme === "Light" ?
                <div style={containerStyle}>
                    <div style={baseImageStyle}>
                        <img src={LightBackground} alt="Light Background" />
                        <div style={middleLayerStyle('#ffffff')}>
                            <img style={overlayImageStyle} src={LightPrimary} alt="Light Primary" />
                        </div>
                    </div>
                </div>
            : inputs.survey_theme === "Luxe" ?
                <div style={containerStyle}>
                    <div style={baseImageStyle}>
                        <img src={LuxeBackground} alt="Luxe Background" />
                        <div style={middleLayerStyle('#180f0e')}>
                            <img style={overlayImageStyle} src={LuxePrimary} alt="Luxe Primary" />
                        </div>
                    </div>
                </div>
            : inputs.survey_theme === "Tropical" ?
                <div style={containerStyle}>
                    <div style={baseImageStyle}>
                        <img src={TropicalBackground} alt="Tropical Background" />
                        <div style={middleLayerStyle('#FFFFFF')}>
                            <img style={overlayImageStyle} src={TropicalPrimary} alt="Tropical Primary" />
                        </div>
                    </div>
                </div>
            : <div></div>}
          </Box>
          </Box>
        )
}

export default StyleEditor;