import React, { Fragment } from 'react';
import { Link, Navigate, useNavigate  } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from '../actions/auth';
import atom_logo from '../assets/atom_logo.png';

const Navbar = ({ logout, isAuthenticated }) => {  

    const handleLogout = () => {
        logout();
      };


      if (!isAuthenticated) {

        return (
            <nav className='navbar navbar-expand-lg navbar-light bg-light'>
            <img classs="img-responsive" height='50px' alt='atom logo'  src={atom_logo}></img>
            <Link className='navbar-brand ms-5' to='/'>ATOM: Login</Link>
            <button 
                className='navbar-toggler' 
                type='button' 
                data-toggle='collapse' 
                data-target='#navbarNav' 
                aria-controls='navbarNav' 
                aria-expanded='false' 
                aria-label='Toggle navigation'>
                <span className='navbar-toggler-icon'></span>
            </button>
            <div className='collapse navbar-collapse' id='navbarNav'>
                <ul className='navbar-nav'>
                    <li className='nav-item active'>
                        <Link className='nav-link' to='/'>Home<span className='sr-only'></span></Link>
                    </li>
                    <li className='nav-item'>
                        <Link className='nav-link' to='/login'>Login</Link>
                    </li>
                    <li className='nav-item'>
                        <Link className='nav-link' to='/signup'>Sign Up</Link>
                    </li>
                    <li className='nav-item'>
                        <Link className='nav-link' to='/learn-more'>Learn More</Link>
                    </li>
                </ul>
            </div>
        </nav>
        ) 
      } else {
        // this is what we display for logged in users
        return (
            <nav className='navbar navbar-expand-lg navbar-light bg-light'>
            <img classs="img-responsive" height='50px' alt='atom logo'  src={atom_logo}></img>
            <Link className='navbar-brand ms-5' to='/dashboard'>ATOM</Link>
            <button 
                className='navbar-toggler' 
                type='button' 
                data-toggle='collapse' 
                data-target='#navbarNav' 
                aria-controls='navbarNav' 
                aria-expanded='false' 
                aria-label='Toggle navigation'>
                <span className='navbar-toggler-icon'></span>
            </button>
            <div className='collapse navbar-collapse' id='navbarNav'>
                <ul className='navbar-nav'>
                    <li className='nav-item active'>
                        <Link className='nav-link' to='/dashboard'>Dashboard<span className='sr-only'></span></Link>
                    </li>
                    <li className='nav-item'>
                        <Link className='nav-link' to='/my_surveys'>My Projects</Link>
                    </li>
                    <li className='nav-item'>
                        <Link className='nav-link' onClick={handleLogout} >Logout</Link>
                    </li>
                </ul>
            </div>
        </nav>
        ) 
      }
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { logout })(Navbar);