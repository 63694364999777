import * as React from 'react';
import { connect } from 'react-redux';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import { Fragment, useState, useRef } from 'react';
import axios from 'axios';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { AgGridReact } from 'ag-grid-react';
import AdvancedForm from './AdvancedForm';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '../styles.scss';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';

const drawerWidth = 300;

const Finalize = () => {
  
  const navigate = useNavigate();

  const location = useLocation();
  let doe = eval(location.state.doe);
  let variable_names = location.state.variable_names;
  let atomBuildSurvey = location.state.atomBuildSurvey;
  console.log(atomBuildSurvey);

  // Initialize the columns array and Sample # column
  let columnDefs = [];
  columnDefs.push({ headerName: '', field: 'sample' })

  // Initialize the rows array
  const initialRowData = [];
  const [rowData, setRowData] = useState(initialRowData);

  // This is where the DoE is rendered
  for (let i = 0; i < variable_names.length; i++) { // 0 -> 2
    // This will be for the column headers
    columnDefs.push({
      headerName: variable_names[i],
      field: variable_names[i],
      editable: (params) => params.data.isAdded, // Check if the row has the isAdded property
      cellClassRules: {
        'ag-cell-editable': () => true,
      },
    });
    // This will be for the rows
    for (let j = 0; j < doe[0].length; j++) { // 0 -> 2
      const value = doe[i][j];
      // IF 'Sample 1, 2, 3, ..., 10'
      if (initialRowData[j]) {
        initialRowData[j][variable_names[i].toString()] = value;
      } else {
        initialRowData.push({
          sample: 'Sample ' + (j + 1),
          [variable_names[i].toString()]: value
        });
      }
    }
  }

  // This is where the custom and dummy samples are being added
  let [customCount, setCustomCount] = useState(1);
  let [dummyCount, setDummyCount] = useState(1);

  const addCustomSample = () => {
    const newRow = { sample: 'Custom Sample ' + (customCount), isAdded: true };
    variable_names.forEach((variable_name) => {
    newRow[variable_name] = '';
    });

    // Add the new row to rowData and update the state
    const updatedRowData = [...rowData, newRow];
    setRowData(updatedRowData);
    setCustomCount(customCount+1)
  }

  const addDummySample = () => {
    const newRow = { sample: 'Dummy Sample ' + (dummyCount), isAdded: true };
    variable_names.forEach((variable_name) => {
    newRow[variable_name] = '';
    });

    // Add the new row to rowData and update the state
    const updatedRowData = [...rowData, newRow];
    setRowData(updatedRowData);
    setDummyCount(dummyCount+1)
  }

  // This allows the user to delete rows that they have added
  const deleteRow = (index, sampleType) => {
    const updatedRowData = rowData.filter((_, i) => i !== index);

    // Reassign sample names
    let customSampleCount = 1;
    let dummySampleCount = 1;
    
    updatedRowData.forEach((row) => {
      const rowSampleType = row.sample.split(' ')[0] + ' ' + row.sample.split(' ')[1];
      if (rowSampleType === 'Custom Sample') {
        row.sample = `Custom Sample ${customSampleCount}`;
        customSampleCount++;
      } else if (rowSampleType === 'Dummy Sample') {
        row.sample = `Dummy Sample ${dummySampleCount}`;
        dummySampleCount++;
      }
    });

    setRowData(updatedRowData);

    // Update the customCount or dummyCount based on the sample type
    if (sampleType === 'Custom Sample') {
      setCustomCount(customCount - 1);
    } else if (sampleType === 'Dummy Sample') {
      setDummyCount(dummyCount - 1);
    }
  };

  // This is what shows the delete icons in the right hand column
  const deleteIconCellRenderer = (params) => {
    const sampleType = params.data.sample.split(' ')[0] + ' ' + params.data.sample.split(' ')[1];

    if (params.data.isAdded) {
      return (
        <button
          style={{ border: 'none', background: 'transparent' }}
          onClick={(e) => {
            e.stopPropagation();
            deleteRow(params.rowIndex, sampleType);
          }}
        >
          <DeleteIcon />
        </button>
      );
    } else {
      return null;
    }
  };

  const deleteColumn = {
    headerName: "Delete",
    cellRendererFramework: deleteIconCellRenderer,
    width: 100
  };

  // This updates the columns after they are deleted
  const updatedColumnDefs = [...columnDefs, deleteColumn];

  // This is used for extracting the DoE when done with the finalize page
  const extractColumnValues = () => {
    const columnData = {};

    const isNumber = (value) => !isNaN(parseFloat(value)) && isFinite(value);
  
    rowData.forEach(row => {
      const keys = Object.keys(row);
      keys.slice(1).forEach(key => { // Skip the first key by using slice(1)
        if (!variable_names.includes(key)) return; // Add this line to skip keys not in variable_names
        
        if (!columnData[key]) {
          columnData[key] = [];
        }

        // Check if the value is a number and parse it as an integer
        const value = isNumber(row[key]) ? parseInt(row[key], 10) : row[key];
        columnData[key].push(value);

      });
    });
  
    const doeArray = Object.values(columnData);
    console.log(doeArray);
    return doeArray;
  };

  // This is what happens when the user clicks "Save"
  const gridRef = useRef(null);
  const HandleSubmit = (e) => {
    gridRef.current.api.stopEditing(); // SO that the user doesn't have to click out of the input box before submitting
    extractColumnValues();
    if (atomBuildSurvey) {
      navigate('/survey/create')
    } else {
      navigate('/dashboard');
      Swal.fire({
        title: 'Thank you for completing the survey!',
        text: 'Your survey has been saved.',
        icon: 'success'
      });
    }
  }


  return (
      <Box sx={{ p: 2, display: 'flex' }}>
        {/* The drawer is where all of the survey settings are located */}
        <Drawer
          variant="permanent"
          containerstyle={{ height: 'calc(100%-64px)', top: 64 }}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', position: 'absolute', top: 64, zIndex: 'tootip' },
          }}
        >
  
          {/* This box holds all of the variable input fields. */}
          <Box sx={{ overflow: 'auto', position: 'absolute', top: 0, left: 30 }}>
          {/* onClick={handleAddCustomSample} */}
              <Divider orientation='horizontal' sx={{ height: '20px' }} />
                  <Box display="flex" alignItems="end">
                      <span><h5>Add Custom Sample</h5></span>
                      <Button color="primary" size="large" onClick={addCustomSample}>
                          <AddCircleIcon />
                      </Button>
                  </Box>
              <Divider orientation='horizontal' sx={{ height: '20px' }} />
              {/* onClick={handleAddDummySample} */}
              <Box display="flex" alignItems="end">
                  <span><h5>Add Dummy Sample</h5></span>
                  <Button color="primary" size="large" onClick={addDummySample}>
                      <AddCircleIcon />
                  </Button>
              </Box>
              <Divider orientation='horizontal' sx={{ height: '20px' }} />
              {/* onClick={handleExportSamples} */}
              <Box display="flex" alignItems="end">
                  <span><h5>Export Samples (.csv)</h5></span>
                  <Button color="primary" size="large">
                      <AddCircleIcon />
                  </Button>
              </Box>
            <Divider orientation='horizontal' sx={{ height: '20px' }} />
          </Box>
        </Drawer>
        <Toolbar />
        <Box>
          <h3>Design of Experiment</h3>
          <Divider orientation='horizontal' sx={{ height: '10px' }} />
          <div className="parent-container">
          <div className="ag-theme-alpine" style={{ height: 700, width: 1200 }}>
            <AgGridReact ref={gridRef} columnDefs={updatedColumnDefs} rowData={rowData} gridOptions={{ singleClickEdit: true, autoHeight: true }}/>
          </div>
          </div>
          <div>
          <Divider orientation='horizontal' sx={{ height: '10px' }} />
          <Button type="submit" variant="contained" className="submit-btn" onClick={(e) => HandleSubmit(e)} size="large">
              Save
          </Button>
        </div>
        </Box>
  </Box>
  )
}

export default Finalize;