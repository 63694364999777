
import React, { useEffect, RefObject } from 'react'
import * as d3 from 'd3'
import cloneDeep from 'lodash/cloneDeep';
import './plotSlice.css'


const PlotSlice = ({ chart_data, width, height, plotslice_container_ref })  => {

    //let data = cloneDeep(chart_data)
    const ref = React.useRef(null);
    React.useEffect(() => {

        //const width = plotslice_container_ref.current.offsetWidth;
        //width = 2000;
        
        //console.log(`Container width: ${width}px`);
        // draw the chart
        draw(chart_data, height)

    })

    const draw = (data, height) => {

        var coefficients = cloneDeep(data)
        var example_predictors = coefficients
            .filter(coefficient => coefficient.type === 'linear')  
            .filter(coefficient => coefficient.name !== 'intercept')  
            .map(coefficient => coefficient.name) 

        //var default_values = new Array(example_predictors.length).fill(0.5);
        var num_of_charts = example_predictors.length;

        // var outer_width = width,
        var outer_height = height,
        margin = {top: 20, right: 30, bottom: 20, left: 40},
        performance_stats_size = {width: 200, height: 400},
        performance_stats_padding = {top:margin.top, left: margin.left},
        charts_holder_size = {width: Math.max(800, num_of_charts*175), height: 400}, // use at minimum 800 px width or go wider if the # of charts (variables) is high (>5)
        outer_width = margin.left+performance_stats_size.width+margin.right+charts_holder_size.width+margin.right,
        //outer_width = 2000,
        charts_holder_padding = {top: margin.top, left: (margin.left+performance_stats_size.width+margin.left)},
        units_holder_size = {width: 1000, height: 70},
        units_holder_padding = {top: charts_holder_padding.top+charts_holder_size.height+35, left: (margin.left+performance_stats_size.width+margin.left)};
        
        

        var selected_values = cloneDeep(example_predictors)
        var values_min = coefficients
            .filter(coefficient => coefficient.type === 'linear')  
            .filter(coefficient => coefficient.name !== 'intercept')  
            .map(coefficient => coefficient.min) 


        var selected_values_selection = coefficients
            .filter(coefficient => coefficient.type === 'linear')  
            .filter(coefficient => coefficient.name !== 'intercept')   
            .map(coefficient => coefficient.middle) 

        console.log('selected_values_selection')
        console.log(selected_values_selection)
        
        
        var values_max = coefficients
            .filter(coefficient => coefficient.type === 'linear')  
            .filter(coefficient => coefficient.name !== 'intercept')   
            .map(coefficient => coefficient.max) 

        var units = coefficients
        .filter(coefficient => coefficient.type === 'linear')  
        .filter(coefficient => coefficient.name !== 'intercept')   
        .map(coefficient => coefficient.unit) 
    
        var [data, domain] = predictAllValues(data, selected_values, selected_values_selection, values_min, values_max, 20);
        console.log("data");
        console.log(data);
        console.log("domain");
        console.log(domain);

        //remove all prior out
        d3.select(ref.current).selectAll("*").remove();

    
        var svg = d3.select(ref.current)
            .append("svg")
            .attr("id", "plotslice_chart")
            .attr("width", outer_width)
            .attr("height", outer_height);

        // var title = svg.append("text")
        //     .attr("id", "title")
        //     .attr("x", outer_width/2)
        //     .attr("y", margin.top*.7)
        //     .text("Plot Title");


        var performance_stats = svg.append('g')
            .attr('transform', 'translate(' + (performance_stats_padding.left) + ',' + (performance_stats_padding.top) + ')')
            .attr("width", performance_stats_size.width)
            .attr("height", performance_stats_size.height)
            //.attr("style", "outline: thin solid black;")
            .attr("id", "performance_stats");

 
        performance_stats.append("rect")
            .attr("x", 0)
            .attr("y", 0)
            .attr("id", "performance_stats_rect")
            .attr("rx", 12)
            .attr("ry", 12)
            .attr("width", performance_stats_size.width)
            .attr("height", performance_stats_size.height)
            .style("opacity", .1)
            .style("fill", "green");


        var performance_stats_title = performance_stats.append("text")
            .attr('transform', 'translate(' + (performance_stats_size.width/2) + ',' + (30) + ')')
            .attr("id", "performance_title")
            .text("Model Information");
    
        var predicted_from_selection = predictValue(coefficients, selected_values, selected_values_selection);
        var performance_stats_info = performance_stats.append("text")

        .attr('transform', 'translate(' + (performance_stats_size.width/2) + ',' + (performance_stats_size.height/2) + ')')
            .attr("id", "performance_info")
            .style("text-anchor", "middle")
            .text("Predicted Amount:" + "\n" + Math.round(predicted_from_selection * 100) / 100);
    
        
        var charts_holder = svg.append("g")
            .attr("id", 'charts_holder')
            .attr('transform', 'translate(' + (charts_holder_padding.left) + ',' + (charts_holder_padding.top) + ')')
            .attr("width", charts_holder_size.width)
            .attr("height", charts_holder_size.height);

        var units_holder = svg.append("g")
            .attr("id", 'units_holder')
            .attr('transform', 'translate(' + (units_holder_padding.left) + ',' + (units_holder_padding.top) + ')')
            .attr("width", units_holder_size.width)
            .attr("height", units_holder_size.height);


            drawPlot(data, domain, selected_values_selection)

        function drawPlot(data, domain, selected_values_selection) {

            var chart_dimensions = {height: charts_holder_size.height, width: charts_holder_size.width/num_of_charts};
            var unit_box_dimension = {height: 30, width: chart_dimensions.width*.7};
            var units_padding_left = (chart_dimensions.width-unit_box_dimension.width)/2;

            for (let i = 0; i < num_of_charts; i++) {

                var measurement_data = data[i];

                console.log(measurement_data)

                if (measurement_data["variable"].length > 18) {
                    var feature = measurement_data["variable"].slice(0,18) + ". (" + coefficients.filter(coefficient => coefficient.name === measurement_data["variable"])[0].unit + ")";
                } else {
                    var feature = measurement_data["variable"] + " (" + coefficients.filter(coefficient => coefficient.name === measurement_data["variable"])[0].unit + ")";
                }
                var feature_values = measurement_data["responses"];
                var selected_value = selected_values_selection[i];

                //we need to determine if the feature is either continuous or categorical prior to graphing
                var first_var = feature_values[0].x;
                var feature_type = ((typeof first_var == "number") ? "continuous" : "categorical");
                // console.log("feature_values: " + first_var);
                // console.log("feature_type: " + feature_type);

                charts_holder.append("g")
                .attr("id", 'chart_' + i)
                .attr('transform', 'translate(' + ((i*chart_dimensions.width)) + ',' + (0) + ')')
                .attr("width", chart_dimensions.width)
                .attr("height", chart_dimensions.height);

                d3.select("#" + 'chart_' + i).append("rect")
                .attr("x", 0)
                .attr("y", 0)
                .attr("width", chart_dimensions.width)
                .attr("height", chart_dimensions.height)
                .style("opacity", 1)
                .style("fill", "white")
                .style("stroke-width", 1)
                .style("stroke", "black");

                var units = units_holder.append("g")
                    .attr("id", 'units_' + i)
                    .attr('transform', 'translate(' +  ((i*chart_dimensions.width)) + ',' + (0) + ')')
                    .attr("width", unit_box_dimension.width)
                    .attr("height", unit_box_dimension.height);
                

                        //rects to hold the predictor value
        units.append("rect")
        .attr("class", "unit_rect")
        .attr("x", units_padding_left)
        .attr("y", 0)
        .attr("width", unit_box_dimension.width)
        .attr("height", unit_box_dimension.height);
    
units.append('text')
    .attr("id", "unit_text" + i)
    .attr("class", "unit_text")
    .attr("x", chart_dimensions.width/2)
    .attr("width", chart_dimensions.width)
    .attr("y", 50)
    .style("max-width", chart_dimensions.width*.8)
    .text(function () {
            return feature;
    });



    units.append('text')
            .attr("id", "unit_text_amount" + i)
            .attr("class", "unit_text")
            .attr("x", chart_dimensions.width/2)
            .attr("width", chart_dimensions.width)
            .attr("y", 20)
            .style("max-width", chart_dimensions.width*.8)
            .text(function () {return selected_value;});


        var y = d3.scaleLinear()
            .domain(domain)
            .range([chart_dimensions.height, 0]);

            // x scale is continuous for continuous variables, and ordinal for categorical variables
        if(feature_type == "continuous"){
            var x = d3.scaleLinear()
                .domain(d3.extent(feature_values, function(d){return d.x;}))
                .range([0, chart_dimensions.width]);

            var line = d3.line()
                .x(function(d) { return x(d.x); })
                .y(function(d) { return y(d.y); })
                .curve(d3.curveBasis);


            d3.select("#chart_"+i).append("path")
                .data([feature_values])
                .attr("fill", "none")
                .attr("stroke-width", "0.25px")
                .attr("stroke", "black")
                .attr("class", "line")
                .attr("d", line);

            
        } else {
            var x = d3.scaleOrdinal()
                .domain(feature_values.map(function(d) {return d.x;}))
                .range([0, chart_dimensions.width]);
        }

    var past_element = false;

    d3.select("#chart_" + i).selectAll("g")
    .data(feature_values)
    .enter()
    .append("g");


    d3.select("#chart_" + i).selectAll('g')
        .append("circle")
        .attr("id", function(d) {return "chart_" + i + "_circle_" + d.x;})
        .attr("cx", function(d, i){
            //plotting continuous points
            if(feature_type == "continuous") {
            return x(d.x);
            } else {                                    // when plotting categorical variables, pull them in and off the edges of the graph
                return x(d.x) + ((i==0) ? + 20: -20)    // by at least 20 pixels on each side
            }
        
        
        })
        .attr("cy", function(d){return y(d.y);})

        .attr("class", function(d) {

            console.log('selecting value')
            console.log(selected_value)
            if(typeof d.x == "number" && (Math.round(d.x * 10000000)/10000000) == selected_value){
                return "clicked";
            } else if(typeof d.x == "string" && d.x == selected_value) {
                return "clicked";
            } else {
                return "not-clicked";
            }
        }
            )
        .style("fill", function(d) {
            return ((d3.select(this).attr("class") == "clicked") ?  "white" : "black");
        })
        .style("stroke-width", "1")
        .style("stroke", function(d) {
            return ((d3.select(this).attr("class") == "clicked") ?  "green" : "black");
        })
        .attr("r", function(d) {
            return ((d3.select(this).attr("class") == "clicked") ?  6 : 1.5);
        })
        .on("click", function(d, past_element) { 

            //updte the value displayed in the unit_text_amount box
            d3.select("#unit_text_amount"+i)
                .text(function() {
                    //handle how to display the value if selected is a number
                    if(typeof d.x == "number"){
                        //console.log("entered continuous");
                        return (Math.round(d.x * 10000000)/10000000);
                    }
                    //and this is how you handle categorical values selected
                    else {
                        return ((d.x.length > 20) ? d.x.slice(0,20) : d.x);
                    }
                }
            );
            //redraw the graph with the updated values selected by the user
            redrawGraph();
        })
        .on("mouseover", function(d){

              if(d3.select(this).attr("class") == "not-clicked") {

            d3.select(this).transition()
                .duration(100)
                .style("fill", "white")
                .style("stroke", "green")
                .attr("r", 4);
        }
    })
        .on("mouseout", function(d){
            if(d3.select(this).attr("class") == "not-clicked") {
            d3.select(this).transition()
                .duration(500)
                .style("fill", "black")
                .style("stroke", "black")
                .attr("r", 1.5);
            }
            });


        //append text if the variable is categorical
    if(feature_type == "categorical"){
        d3.select("#chart_" + i).selectAll('g')
                .append("text")
                .attr("class", "circle_labels")
                .attr("id", function(d){return "circle_label_" + d.x;})
                .style("text-anchor", function(d, i) {return ((i==0) ? "start": "end");})
                .attr("x", function(d, i){return x(d.x) + ((i==0) ? + 10: -10) })
                .attr("y", function(d, i){return y(d.y) + ((i==0) ? 15: -15);})
                .text(function(d){return d.x});
        } else  {

            var x_axis = d3.axisBottom()
                .scale(x)
                .ticks(3);

             d3.select("#chart_"+i)
                .append("g")
                .attr("transform", "translate(0," + chart_dimensions.height  + ")")
                .call(x_axis);
        }

    //for the first graph, add a y axis
    if(i==0){
        //plot axis just on first plot for y
        var y_axis = d3.axisLeft().scale(y);
        d3.select("#chart_"+i).append("g").call(y_axis);

        //should add y axis label here
    }
            }
        }

        function redrawGraph() {

            //get updated values
            for (let i=0; i < num_of_charts; i++) {
        
                //console.log("Im in " + i)
                var updated_value = document.getElementById('unit_text_amount' + i).textContent;
        
                selected_values_selection[i] = updated_value;
            }
        
            //console.log(selected_values_selection);
        
            //with updated values, re-predict all features so we can re-draw the graph
            [data, domain] = predictAllValues(coefficients, selected_values, selected_values_selection, values_min, values_max, 20);
        
            d3.selectAll("#charts_holder > g").remove();
            d3.selectAll("#units_holder > g").remove();
        
            drawPlot(data, domain, selected_values_selection);
        
            predicted_from_selection = predictValue(coefficients, selected_values, selected_values_selection);
        
            //update text for predicted amount
            performance_stats_info
                .text("Predicted Amount:" + "\n" + Math.round(predicted_from_selection * 100) / 100);
        }

    //this function is used to predict a single value from the coefficients table. 
    //selected_values are the variables used in the experiment
    //selected_values_selection are the values selected by the user or loaded by default to construct the graph of plotSlice()
    function predictValue(coefficients, selected_values, selected_values_selection)  {

        // console.log('coefficients')
        // console.log(coefficients)

        console.log("selected_values")
        console.log(selected_values)

        // console.log("selected_values_selection")
        // console.log(selected_values_selection)

        let predicted_amount = 0;

        coefficients.forEach(function (coefficient) {

            //console.log(coefficient.name);

            if(coefficient.name == 'intercept') {

                predicted_amount += coefficient.estimate;
                //console.log(predicted_amount)

                //handle all linear terms
            } 
            else if(coefficient.type == 'linear'){
                //console.log('linear')

                //console.log('entered linear with ' + coefficient.name);
                //determine predictor type, whether it is categorical or continuous
                if (coefficient.name.includes('_')) {
                    //handle all indicator or categorical variables 
                    //first determine the name of variable the indicator is encoding for, and the selection for the estimate
                    //An example of how this splits a string is the following: Packaging_Hot fill PET becomes 'Packaging' and 'Hot fill PET'
                    var categorical_variable = coefficient.name.substring(0, coefficient.name.indexOf('_'));
                    var indicator_var_selection = coefficient.name.substring(coefficient.name.indexOf('_')+1, coefficient.name.length);
                    //console.log(indicator_var_selection);

                    //knowing the name of the variable, search our array of selected values and user input to later match against
                    var variable_index = selected_values.indexOf(categorical_variable);
                    var x = selected_values_selection[variable_index];
                    //console.log(x);

                    //if the user selected a categorical value that matches the indicator value, add it to our estimate
                    //if x does not equal the indicator variable selection it is because it is considered to be a part of the base case
                    if (indicator_var_selection == x) {
                        predicted_amount += coefficient.estimate;
                        //console.log('added indicator variable ' + categorical_variable + ' for selection ' + x + ' to our model estimate');
                    }
                    //console.log(predicted_amount)
                } 
                
                //this else block is used to add the estimate if the value is continuous
                else {
                //find the value that matches what is needed by the coefficient
                x = selected_values_selection[selected_values.indexOf(coefficient.name)];
                //console.log('x')
                //console.log(x)

                predicted_amount += coefficient.estimate*x;

                //console.log(predicted_amount)
                //console.log('added continuous variable for ' + coefficient.name + ' in the value of ' + coefficient.estimate*x);
                } 
            } 
            
            //if it is an interaction term, we will handle the case here
            else if(coefficient.type == 'interaction') {
                //console.log('interaction');
                //console.log(coefficient.name);
                
                var term_a = coefficient.name.substring(0, coefficient.name.indexOf(':'));
                var term_b = coefficient.name.substring(coefficient.name.indexOf(':')+1, coefficient.name.length);

                var term_a_value = -1;
                var term_b_value = -1;

                //console.log(term_a);
                //console.log(term_b);

                if (term_a.includes('_')) {
                    //handle all indicator or categorical variables 
                    //first determine the name of variable the indicator is encoding for, and the selection for the estimate
                    //An example of how this splits a string is the following: Packaging_Hot fill PET becomes 'Packaging' and 'Hot fill PET'
                    categorical_variable = term_a.substring(0, term_a.indexOf('_'));
                    indicator_var_selection = term_a.substring(term_a.indexOf('_')+1, term_a.length);
                    //console.log(indicator_var_selection);

                    //knowing the name of the variable, search our array of selected values and user input to later match against
                    variable_index = selected_values.indexOf(categorical_variable);
                    x = selected_values_selection[variable_index];
                    //console.log(x);

                    //if the user selected a categorical value that matches the indicator value, add it to our estimate
                    //if x does not equal the indicator variable selection it is because it is considered to be a part of the base case
                    if (indicator_var_selection == x) {
                        term_a_value = 1;
                        //console.log('added indicator variable for term a ' + categorical_variable + ' for selection ' + x + ' to our model estimate');
                        //console.log('added indicator variable ' + categorical_variable + ' for selection ' + x + ' to our model estimate');
                    } else {
                        term_a_value = 0;
                        //console.log('categorical interaction term a not found in estimates')
                    }
                } else {

                    term_a_value = selected_values_selection[selected_values.indexOf(term_a)];
                    //console.log("interaction term a is continuous and the value is " + term_a_value);

                }

                if (term_b.includes('_')) {
                    //handle all indicator or categorical variables 
                    //first determine the name of variable the indicator is encoding for, and the selection for the estimate
                    //An example of how this splits a string is the following: Packaging_Hot fill PET becomes 'Packaging' and 'Hot fill PET'
                    categorical_variable = term_b.substring(0, term_b.indexOf('_'));
                    indicator_var_selection = term_b.substring(term_b.indexOf('_')+1, term_b.length);
                    //console.log(indicator_var_selection);
                    //console.log('term b includes an interaction term that is categorical and it is ' + term_b)

                    //knowing the name of the variable, search our array of selected values and user input to later match against
                    variable_index = selected_values.indexOf(categorical_variable);
                    x = selected_values_selection[variable_index];
                    //console.log(x);

                    //if the user selected a categorical value that matches the indicator value, add it to our estimate
                    //if x does not equal the indicator variable selection it is because it is considered to be a part of the base case
                    if (indicator_var_selection == x) {
                        term_b_value = 1;
                        //console.log('added indicator variable for term b ' + categorical_variable + ' for selection ' + x + ' to our model estimate');
                    } else {
                        term_b_value = 0;
                        //console.log('categorical interaction term b not found in estimates')
                    }
                } else {
                    term_b_value = selected_values_selection[selected_values.indexOf(term_b)];
                    //console.log("interaction term b is continuous and the value is " + term_b_value);
                }

                //after resolving the value of interaction term a and b we can multiply by coefficient and add to model
                //console.log('added amount from ' + coefficient.name + ' is ' + coefficient.estimate*(term_a_value*term_b_value));
                
                //console.log(coefficient.estimate);
                //predicted_amount += coefficient.estimate*(term_a_value*term_b_value);
                //console.log('value of interaction term: ' + coefficient.estimate*(term_a_value*term_b_value))

            //handle all polynomial terms in this 'else' statement
            } else {

                //console.log('entered interaction term for ' + coefficient.name);
                var variable_name = coefficient.name.substring(0, coefficient.name.indexOf('^'));
                x = selected_values_selection[selected_values.indexOf(variable_name)];
                //console.log('getting interaction term for ' + x);
                predicted_amount += (coefficient.estimate*(x*x));
                //console.log('added amount from ' + coefficient.name + ' is ' + coefficient.estimate*(x*x));
            }

            })

        // console.log('in predicted amount function return');
        // console.log(predicted_amount)

        return predicted_amount;

    }

    function predictAllValues(coefficients, selected_values, selected_values_selection, values_min, values_max, steps) {

        //all_responses is structured to feed graph making
        let all_responses = [];
        //console.log('entered predictAllValues');

        //predicted_values is used only to store predicted values, we use this to get 
        //min & max to later set y scale domain in d3.js
        let predicted_values = [];

        //loop through each feature to calculate values
        for(let i=0; i < selected_values.length; i++) {

            var feature = selected_values[i];
            console.log('feature: ' + feature);

            var feature_min = Number(values_min[i]);
            var feature_max = Number(values_max[i]);


            // console.log('feature: ' + feature);
            // console.log('feature_min: ' + feature_min);
            // console.log('feature_max: ' + feature_max);
            // console.log('feature_min' + typeof feature_min);
            // console.log('feature_max' + typeof feature_max);

            //handle categorical data here
            if(typeof feature_min == 'string') {
                console.log('entered category');

                //create copys of the values_max and values_min arrays so we can respectively predict with each
                var values_min_copy = JSON.parse(JSON.stringify(selected_values_selection));
                values_min_copy[i] = feature_min;

                var values_max_copy = JSON.parse(JSON.stringify(selected_values_selection));
                values_max_copy[i] = feature_max;

                //console.log("values_min_copy: " + values_min_copy);
                //console.log("values_max_copy: " + values_max_copy);

                var response_min = {};
                response_min['x'] = feature_min;
                response_min['y'] = predictValue(coefficients, selected_values, values_min_copy);
                predicted_values.push(response_min.y);

                var response_max = {};
                response_max['x'] = feature_max;
                response_max['y'] = predictValue(coefficients, selected_values, values_max_copy);
                predicted_values.push(response_max.y);

                all_responses.push({"variable": feature, "responses": [response_min, response_max]});

            //here, we will get predicted values for all numerical
            } else if(typeof feature_min == 'number'){
                
                //console.log('entered number');
                var responses = []
                
                feature_min = values_min[i];
                feature_max = values_max[i];

                var range = feature_max - feature_min;

                //this is used to create a range of x and y to create a plot from a numeric variable
                for(let j = 0; j < steps; j++){
                    var value  = feature_min + ((j*range)/steps);
                    //console.log("predicting numerical value with " + feature + " and value of " + value);
                    let values_copy = cloneDeep(selected_values_selection);
                    values_copy[i] = value;

                    //console.log(coefficients)
                    let predicted_from_value = predictValue(coefficients, selected_values, values_copy);

                    predicted_values.push(predicted_from_value);

                    //console.log("model features: " + selected_values);
                    //console.log("model inputs: " + values_copy);
                    //console.log("predicted value: " + predicted_from_value);

                    responses.push({'x': value, 'y': predicted_from_value});
                }
                all_responses.push({"variable": feature, "responses": responses});
            }
        }

        var domain = [Math.min(...predicted_values), Math.max(...predicted_values)];

        return [all_responses, domain];
    }


}



    return (
        <div className="plotslice_chart" ref={ref}></div>
    )
}
 
export default PlotSlice;