import * as React from 'react';
import { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { InputLabel } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';

const ShareAndSave = ({ setShareSave }) => {

    const [inputs, setInputs] = useState([
        {
            exportSamples: null, allowGivViewing: true, allowCustomerViewing: false, collaborators: "", internalOrExternal: null, externalAccess: null
        }
    ])

    // when inputs changes, modify shareSave
    useEffect(() => {

        console.log(inputs)

        console.log('in useEffect for shareSave')

        let internal_share = inputs.allowGivViewing
        let customer_share = inputs.allowCustomerViewing

        setShareSave(prevState => ({ ...prevState, internal_share: internal_share }));
        setShareSave(prevState => ({ ...prevState, customer_share: customer_share }));
        
        console.log(typeof inputs.collaborators);

        if (typeof inputs.collaborators === "string" && inputs.collaborators.includes(",")) {
            let share_list = inputs.collaborators.split(",");
            console.log(share_list);
            setShareSave(prevState => ({ ...prevState, share_list: share_list }));
        }

    }, [inputs]);


    // What saves the survey setting values once there is a change in the input field
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputs({
        ...inputs,
        [name]: value,
        });
    };
    
    const handleSwitchChange = (e) => {
        setInputs(
        {
            ...inputs,
            [e.target.name]: e.target.checked
        }
        )
    }

    const Android12Switch = styled(Switch)(({ theme }) => ({
        padding: 8,
        '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
        },
        '&:before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
            theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12,
        },
        '&:after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
            theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M19,13H5V11H19V13Z" /></svg>')`,
            right: 12,
        },
        },
        '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
        },
    }));

    return (
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <h5>Export Samples & Output Template to Excel File</h5>
                </Grid>
                <Grid item xs={4}>
                    <Button variant="contained" className="add-btn" size="large">
                        <AddIcon />
                    </Button>
                </Grid>
                <Grid item xs={8}>
                    <h5>Allow everyone in Givaudan to view this survey?</h5>
                </Grid>
                <Grid item xs={4}>
                    <FormControl>
                        <FormGroup>
                        <FormControlLabel
                            sx={{ p: 0.5 }}
                            control={<Android12Switch checked={inputs.allowGivViewing}
                            onChange={handleSwitchChange} name="allowGivViewing" defaultChecked />}
                        />
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={8}>
                    <h5>Allow Givaudan authorized customers to view this survey?</h5>
                </Grid>
                <Grid item xs={4}>
                    <FormControl>
                        <FormGroup>
                        <FormControlLabel
                            sx={{ p: 0.5 }}
                            control={<Android12Switch checked={inputs.allowCustomerViewing}
                            onChange={handleSwitchChange} name="allowCustomerViewing" />}
                        />
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={8}>
                <TextField
                    required
                    sx={{ minWidth: 600 }}
                    label="Add Collaborators or customers by email seperated by comma (,) "
                    id="collaborators"
                    variant="outlined"
                    name="collaborators"
                    value={inputs.collaborators}
                    onChange={handleInputChange}
                />
                </Grid>
               
                {/* {inputs.internalOrExternal === "External Collaborator" ?
                <>
                    <Grid item xs={8}>
                        <h5>What do you want to provide to external customers</h5>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl sx={{ minWidth: 280 }}>
                            <InputLabel variant="outlined" id="external-access-label">Type</InputLabel>
                            <Select
                                required
                                label="Type *"
                                labelId="external-access-label"
                                id="external-access"
                                value={inputs.externalAccess}
                                name="externalAccess"
                                onChange={handleInputChange}
                            >
                                <MenuItem value="Conduct Survey">Conduct Survey</MenuItem>
                                <MenuItem value="Share Results">Share Results</MenuItem>
                                <MenuItem value="Both">Both</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </>
                : <div>{inputs.externalAccess = null}</div>} */}
            </Grid>
    );
}


export default ShareAndSave;