import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import InputLabel, { inputLabelClasses } from '@mui/material/InputLabel';
import Modal from '@mui/material/Modal';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

// Only initialized value is the number of recommendations that are wanted. Everything else is dublicable
const recInputs = {
  numRecs: 3,
  drivers_of_liking: false,
  show_recommendations: false,
  option_values: [{rec_title: "", variable_values: null, attached_image: null}, {rec_title: "", variable_values: null, attached_image: null}, {rec_title: "", variable_values: null, attached_image: null}]
}

console.log(recInputs)

const ATOMRecommendations = ({ configInputs, setAddPages }) => {

    // Set the initial value of open to false, and then open the modal box when they click to attatch image
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // These two constants set the state of the number of recommendations and handle the change
    const [inputs, setInputs] = useState(recInputs);    
    const handleNumRecChange = (e) => {
      const { name, value } = e.target;
      setInputs({
        ...inputs,
        [name]: name === "numRecs" ? parseInt(value) : value,
      });
    };

    // Initializes the image to an empty useState and then updates the state for each recommendation
    const [image, setImage] = useState([]);
    const handleImageChange = (e, index) => {
      let newImage = [...image];
      newImage[index][e.target.name] = e.target.value;
      setImage(newImage);
    };

    // Initialize the state of the variable levels for each separate recommendation and saves the changes made to them
    const [varLevels, setVarLevels] = useState ([]);
    const handleVarLevelChange = (e, index) => {
      let newLevel = [...varLevels];
      newLevel[index][e.target.name] = e.target.value;
      setVarLevels(newLevel);
    };

    // Initializes the state of the recommendation textFields and saves the changes
    const [recs, setRecs] = useState([]);
    const handleRecChange = (e, index) => {
      let newRecField = [...recs];
      newRecField[index][e.target.name] = e.target.value;
      setRecs(newRecField);
    };
    
    // Create the template for recommendation text fields

    const RecTextField = ({ numRecs, value, onChange }) => (
        <TextField
            label={`Recommendation ${numRecs + 1}`}
            variant="outlined"
            name={`${value}`}
            value={value !== "" ? value = value : null}
            onChange={(e) => onChange(e, numRecs)}
            sx={{ minWidth: 400 }}
        />
    );

    // Render the template above based on the number of recommendations that are chosen

    const renderRecTextField = () => {
        const numberOfTextFields = inputs.numRecs;
        const textFields = [];
        
        for (let i = 0; i < numberOfTextFields; i++) {
            textFields.push(<RecTextField key={i} numRecs={i} value={recs[i] || ''} onChange={(e) => handleRecChange(e, i)} />);
            textFields.push(<Divider orientation='horizontal' sx={{ height: '5px' }} />);
            textFields.push(<SetVarValuesButton levelID={`recommendation${i+1}_var`} levelName={`recommendation${i+1}_var`} levelValue={varLevels[i] || ''} onChange={(e) => handleVarLevelChange(e, i)}/>);
            textFields.push(<Divider orientation='horizontal' sx={{ height: '5px' }} />);
            textFields.push(<AddImageButton imageID={`recommendation${i+1}_image`} imageName={`recommendation${i+1}_image`} imageValue={image[i] || ''} onChange={(e) => handleImageChange(e, i)} />);
            textFields.push(<Divider orientation='horizontal' sx={{ height: '5px' }} />);
        }
        return textFields;
    };

    // These next two components are called in the render function above. They are separate componented because they need to have their values, names, and ids updated dynamically
    
    // Create the Component for the "Set Variable Values" button to be rendered in renderRecTextField

    const SetVarValuesButton = ({ levelID, levelName, levelValue, onChange }) => (
      <Box sx={{ display: 'flex' }}>
        <Button variant='contained' onClick={handleOpen}>Set Variable Values</Button>
        <Modal open={open} onClose={handleClose}>
            <Box sx={style}>
                <h3>Set Variable Value Levels</h3>
                <Grid container>
                    <Grid item xs={6}>
                        <h4>Variables</h4>
                    </Grid>
                    <Grid item xs={6}>
                        <h4>Level</h4>
                    </Grid>
                    <Grid item xs={6}>
                        <h4>Variable Placeholder</h4>
                    </Grid>
                    <Grid item xs={6}>
                    <TextField
                      sx={{ minWidth: 110 }}
                      label="Variable Level"
                      required
                      inputProps={{ min: 0 }}
                      id={levelID}
                      variant="outlined"
                      type="number"
                      name={`${levelName}`}
                      value={levelValue !== "" ? levelValue = levelValue : null}
                      onChange={(e) => onChange(e)}
                    />
                    </Grid>
                </Grid>
            </Box>
          </Modal>
          <Divider orientation='vertical' sx={{ width: '5px' }} />
        </Box>
    )

    // Create the Component for the "Attatch Image" button to be rendered in renderRecTextField

    const AddImageButton = ({ imageID, imageName, imageValue, onChange }) => (
      <>
      <Button variant="contained" component="label">
          Attatch Image
          <input hidden type="file" accept="image/" id={imageID} name={imageName} value={imageValue} onChange={(e) => onChange(e)}/>
      </Button>
      {console.log(image.imageValue)}
      </>
    )

    // Handles the switch input
    const handleSwitchChange = (e) => {
        setInputs(
            {
                ...inputs,
                [e.target.name]: e.target.checked
            }
        )
    }
    
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 16,
        height: 16,
        },
        '&:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
            theme.palette.getContrastText(theme.palette.primary.main),
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
        },
        '&:after': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
            theme.palette.getContrastText(theme.palette.primary.main),
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
    },
    }));

//     useEffect(() => {

// //         numRecs: 3,
// //   drivers_of_liking: false,
// //   show_recommendations: false,
// //   option_values: [{rec_title: "", variable_values: null, attached_image: null}, {rec_title: "", variable_values: null, attached_image: null}, {rec_title: "", variable_values: null, attached_image: null}]
// // }
            
//         let num_recs = inputs.numRecs;
//         let drivers_of_liking = inputs.drivers_of_liking;
//         let show_recommendations = inputs.show_recommendations;
//         let rec_title = inputs.option_values.map(d => d.rec_title).filter(n => n);
//         let variable_values = inputs.option_values.map(d => d.variable_values).filter(number => typeof number == 'number');
//         let attached_image = inputs.option_values(d => d.attached_image).filter(n => n);

//         //console.log('in useEffect all_var_types')

//         setAddPages( prevState  => ({ ...prevState,   num_recs: num_recs }));
//         setAddPages( prevState  => ({ ...prevState,   drivers_of_liking: drivers_of_liking }));
//         setAddPages( prevState  => ({ ...prevState,   show_recommendations: show_recommendations }));
//         setAddPages( prevState  => ({ ...prevState,   rec_title: rec_title }));
//         setAddPages( prevState  => ({ ...prevState,   variable_values: variable_values }));
//         setAddPages( prevState  => ({ ...prevState,   attached_image: attached_image }));

//       }, [inputs]);

    return (
        <>
        <Grid container direction="column">
            <Grid item>
                <FormControl>
                <FormGroup row>
                <FormControlLabel
                    sx={{ p: 0.5 }}
                    label="Drivers of Liking" control={<Android12Switch checked={inputs.drivers_of_liking}
                        onChange={handleSwitchChange} name="drivers_of_liking" />}
                    />
                    <Divider orientation='vertical' sx={{ width: '10px' }} />
                    <FormControlLabel
                    sx={{ p: 0.5 }}
                    label="Show Recommendations" control={<Android12Switch checked={inputs.show_recommendations}
                        onChange={handleSwitchChange} name="show_recommendations" />}
                    />
                </FormGroup>
                </FormControl>
            </Grid>
            <Grid item>
              <FormControl sx={{ minWidth: 270 }}>
              <InputLabel variant="outlined" id="num-of-recs-label">Number of Recommendations</InputLabel>
                  <Select
                    required
                    label="Number of Recommendations *"
                    labelId="num-of-recs-label"
                    id="num-of-recs"
                    value={inputs.numRecs}
                    name="numRecs"
                    onChange={handleNumRecChange}
                  >
                    <MenuItem value="3">3</MenuItem>
                    <MenuItem value="5">5</MenuItem>
                    <MenuItem value="7">7</MenuItem>
                    <MenuItem value="9">9</MenuItem>
                  </Select>
              </FormControl>
            </Grid>
            <Grid item sx={{ marginTop: '10px' }}>
                {renderRecTextField()}
            </Grid>
        </Grid>
        </>
    )
};

export default ATOMRecommendations;