import * as React from 'react';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { IconButton, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

const ConstantVariables = ({ setConfigInputs }) => {

    const [constantsList, setConstantsList] = useState([
        {
          constant_names: "", constant_amounts: "", units: "", materialCode: "", description: ""
        },
      ]);
    
      let addConstantFields = () => {
        setConstantsList([...constantsList, {
          constant_names: "", constant_amounts: "", units: "", materialCode: "", description: ""
        }])
      }
    
      let removeConstantFields = (i) => {
        let newConstants = [...constantsList];
        newConstants.splice(i, 1);
        setConstantsList(newConstants);
      }
    
      // This is what happens when the variables are changed in the variable fields
      const handleConstantChange = (e, index) => {

        let newConstants = [...constantsList];
        if (e.target.name === "constant_amounts") {
          newConstants[index][e.target.name] = parseInt(e.target.value);
        } else {
          newConstants[index][e.target.name] = e.target.value;
        }
        setConstantsList(newConstants);
        

        // update constant names
        if (e.target.name === "constant_names") {
          let all_const_names = constantsList.map(d => d.constant_names);
          
          setConfigInputs( prevState  => ({ ...prevState,   [e.target.name]: all_const_names }));

        }

        // update constant amounts
        if (e.target.name === "constant_amounts") {
          let all_const_amounts = constantsList.map(d => d.constant_amounts);

          setConfigInputs( prevState  => ({ ...prevState,   [e.target.name]: all_const_amounts }));
        }

        

      };

      // This puts the constants into the correct format to be put into the DB
      let constant_names = [], constant_types = [], constant_amounts = [];
      for (let i = 0; i < constantsList.length; i++) {
        if (constantsList[i].constant_names !== '') {
          constant_names.push(constantsList[i].constant_names);
        }
        if (constantsList[i].constType !== '') {
          constant_types.push(constantsList[i].constType);
        }
        if (constantsList[i].constAmount !== '') {
          constant_amounts.push(constantsList[i].constAmount);
        }
      }

    return (
        <Grid container style={{ minWidth: '100%', marginBottom: '30px'}}>  
          <Grid item style={{ width: '100%', maxWidth: '100%' }}>          
            <h5>Constants</h5>
            </Grid>
                    {constantsList.map((element, index) => (
                      <>
                        <div key={index} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '12px'}}>
                            <Grid item style={{ width: '20%'}}>
                            <TextField
                                required
                                label="Name"
                                id="constant_names"
                                variant="outlined"
                                name="constant_names"
                                value={element.constant_names}
                                onChange={(e) => handleConstantChange(e, index)}
                            />
                            </Grid>
                            <Divider orientation='vertical' sx={{ width: '10px' }} />
                            <Grid item style={{ width: '20%'}}>
                            <TextField
                                required
                                label="Level"
                                id="constant_amounts"
                                type="number"
                                variant="outlined"
                                name="constant_amounts"
                                value={element.constant_amounts}
                                onChange={(e) => handleConstantChange(e, index)}
                            />
                            </Grid>
                            <Divider orientation='vertical' sx={{ width: '10px' }} />
                            <Grid item style={{ width: '20%'}}>
                            <TextField
                                label="Units (Optional)"
                                id="units"
                                variant="outlined"
                                name="units"
                                value={element.units}
                                onChange={(e) => handleConstantChange(e, index)}
                            />
                            </Grid>
                            <Divider orientation='vertical' sx={{ width: '10px' }} />
                            <Grid item style={{ width: '20%'}}>
                            <TextField
                                label="Material Code (Optional)"
                                id="materialCode"
                                variant="outlined"
                                name="materialCode"
                                value={element.materialCode}
                                onChange={(e) => handleConstantChange(e, index)}
                            />
                            </Grid>
                            <Divider orientation='vertical' sx={{ width: '10px' }} />
                            <Grid item style={{ width: '20%'}}>
                            <TextField
                                label="Description (Optional)"
                                id="description"
                                variant="outlined"
                                name="description"
                                value={element.description}
                                onChange={(e) => handleConstantChange(e, index)}
                                // sx={{ minWidth: 250 }}
                            />
                            </Grid>
                            <Grid item style={{ width: '-20%'}}>
                            {constantsList.length !== 1 &&
                                (<IconButton sx={{ display: 'block'}} aria-label="delete" className="remove-btn" onClick={() => removeConstantFields(index)}>
                                    <DeleteIcon />
                                </IconButton>)}
                            </Grid>
                        </div>

                        <Grid item xs={12}>
                        <div key={index} style={{ display: 'flex', justifyContent: 'center' }}>
                        {constantsList.length - 1 === index &&
                            (<Button sx={{ display: 'block' }} aria-label="add" variant="contained" className="add-btn" onClick={() => addConstantFields()} size="large">
                                <AddIcon />
                            </Button>)}
                        </div>
                        </Grid>
                        </>
                        
                    ))}
        </Grid>
    );
}


export default ConstantVariables;