import React, { useState, useEffect } from 'react';
import { Link, redirect, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { login } from '../../actions/auth';
import '../styles.scss';
import axios from 'axios';

import { Table } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const MySurveys = ({ isAuthenticated, access_token }) => {

    const [userDesigns, setUserDesigns] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    //this is used to get a users survey data
    // we just the access token from JWT to link back to user and grab their studies
    useEffect(() => {

        if (isAuthenticated) {
            fetch(`${process.env.REACT_APP_API_URL}/survey/view/my_projects`, {
                method: 'post',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `JWT ${access_token}`,
                    'Accept': 'application/json'
                },
            }
        )
        .then((response) => response.json())
        .then((data) => {
          data = data.user_designs;
          setUserDesigns(data)
        })
    }
}, []);

    //first check if user is authenticated, if not reroute to home
    if (userDesigns === null) {
        return (
            <div className='container-md pt-4 text-center'>
                <h1 class="display-4 pb-4">You do not have any projects</h1>
                <p>head over to <Link className='nav-link' to='/design'>Design</Link> module to get started.</p>
            </div>

        )

    //only render page contents if user is logged in
    } else {
        return (
            <div className='container-md pt-4 text-center'>
                <h1 class="display-4 pb-4">My Projects</h1>

                {userDesigns !== null && userDesigns.length > 0 && (

                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                            <TableRow>
                                <TableCell variant='head' align="left">Title</TableCell>
                                <TableCell variant='head' align="left">Description</TableCell>
                                <TableCell variant='head' align="center"># of Samples</TableCell>
                                <TableCell variant='head' align="center">Brief ID</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {userDesigns.map((userDesign) => (
                                <TableRow
                                key={userDesign.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                <TableCell align="left">{userDesign.project_title}</TableCell>
                                <TableCell align="left">{userDesign.project_objective}</TableCell>
                                <TableCell align="center">{userDesign.num_samples}</TableCell>
                                <TableCell align="center">{userDesign.brief_number}</TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                        </TableContainer>
                )
                }
            </div>
        );
    }
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    access_token: state.auth.access
});

export default connect(mapStateToProps, { })(MySurveys);