
import React, { useEffect, RefObject } from 'react'
import * as d3 from 'd3'
import './interactions.css'
import cloneDeep from 'lodash/cloneDeep';

const Drivers = ({ chart_data, height, driver_container_ref }) => {


    const ref = React.useRef(null);

    React.useEffect(() => {

        const width = driver_container_ref.current.offsetWidth;
        console.log(`Container width: ${width}px`);
        // draw the chart
        draw(chart_data, width, height)
    })

    const draw = (data, width, height) => {

        var margin = {top: 20, right: 20, bottom: 20, left: 20},
        padding = {top: 60, right: 60, bottom: 60, left: 60},
        outerWidth = width,
        outerHeight = height,
        innerWidth = outerWidth - margin.left - margin.right,
        innerHeight = outerHeight - margin.top - margin.bottom,
        width = innerWidth - padding.left - padding.right,
        height = innerHeight - padding.top - padding.bottom;
        

        //remove all prior out
        d3.select(ref.current).selectAll("*").remove();

        // create svg
        const svg = d3.select(ref.current)
        .append('svg')
        .attr("width", outerWidth)
        .attr("height", outerHeight);

        
            var container = svg.append("g")
                .attr("id", "container")
                .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
        
            var bar_holder = container.append("g")
                .attr("id", "bar_holder")
                .attr("width", width)
                .attr("height", height)
                .attr("transform",
                      "translate(" + padding.left + "," + padding.top + ")");
        
            var x = d3.scaleBand()
                .range([0, width])
                .domain(data.map(function(d){return d.name.slice(0,10);}))
                .padding(0.2)
        
            var x_axis = bar_holder.append("g")
                .attr("transform",
                      "translate(" + 0 + "," + height + ")")
                .attr("id", "x_axis")
              .call(d3.axisBottom(x))
              .selectAll("text")
                .attr("id", "x_axis_text")
                .attr("transform", "translate(-10,0)rotate(-45)")
                .style("text-anchor", "end");
        
            var y_scaled = d3.extent(data, function(d){return d.tstat;});
            y_scaled = [y_scaled[0]*1.1, y_scaled[1]*1.1];

            var y = d3.scaleLinear()
                .domain(y_scaled)
                .range([height, 0]);
        
            var min = d3.min(data, function(d){return d.tstat;});
            var max = d3.max(data, function(d){return d.tstat;});
        
            //set color scales depending on all values, using 3 point continuous scale
            if (min < 0 && max > 0){
                var color = d3.scaleLinear()
                    .domain([min, 0, max])
                    .range([d3.rgb(222, 239, 210), d3.rgb(156, 208, 121), d3.rgb(47, 77, 27)]);
            } else if (min < 0) {
                var color = d3.scaleLinear()
                    .domain([min, max])
                    .range([d3.rgb(222, 239, 210), d3.rgb(156, 208, 121)]);
            } else {
                var color = d3.scaleLinear()
                    .domain([min, max])
                    .range([d3.rgb(156, 208, 121), d3.rgb(47, 77, 27)]);
            }
        
            var y_axis = bar_holder.append("g")
                .attr("transform", "translate(" + (0) + "," + (0) + ")")
                .attr("id", "y_axis")
                .call(d3.axisLeft(y))
                .selectAll("text")
                .attr("font-family", "jost");



            var tooltip = d3
                .select('body')
                .append('div')
                .attr('class', 'd3-tooltip')
                .style('position', 'absolute')
                .style('z-index', '10')
                .style('visibility', 'hidden')
                .style('padding', '10px')
                .style('background', 'rgba(0,0,0,0.6)')
                .style('border-radius', '4px')
                .style('color', '#fff')
                .text('a simple tooltip');
        
        
            var bars =  bar_holder.selectAll("bar")
                    .data(data)
                    .enter()
                .append("rect")
                    .style("fill", "steelblue")
                    .attr("x", function(d) { return x(d.name.slice(0,10)); })
                    .attr("width", x.bandwidth())
                    .attr("y",   (d) => y(0))
                    .attr("height", 0)
                    .style("fill", function(d){  

                         //return color(d.tstat);
                
                        if (d.tstat < 0){
                            return d3.schemeTableau10[1];
                        } else {
                            return d3.schemeTableau10[0]
                        }
                       })
                       .on('mouseover', function (d, i) {

                        let driver = d.tstat < 0 ? "Negative" : "Positive";

                        d3.select(this)
                            .attr('stroke-width', '2')
                            .attr("stroke", "black");

                        tooltip
                          .html(
                            `<div>Variable: ${d.name}</div>
                            <div>Range: ${d.range}</div>
                            <div>Liking: ${driver}</div>
                            <div>Note: ${d.explain}</div>`
                          )
                          .style('visibility', 'visible');
                        d3.select(this).transition().attr('fill', "#eec42d");
                    })
                    .on('mousemove', function () {
                        tooltip
                          .style('top', d3.event.pageY - 10 + 'px')
                          .style('left', d3.event.pageX + 10 + 'px');
                    })
                    .on('mouseout', function () {
                        tooltip.html(``).style('visibility', 'hidden');
                        d3.select(this).transition().attr('fill', "#437c90");

                        d3.select(this)
                        .attr('stroke-width', '0');
                        
                    });
                       
                       
                       ;
        
            // Animation
            bar_holder.selectAll("rect")
                .transition()
                .duration(800)
                .attr("y",  function (d) {
                    return y(Math.max(0, d.tstat));
                })
                .attr("height",    function (d) {
                    return Math.abs(y(d.tstat) - y(0))
                })
                .delay(function(d,i){console.log(i) ; return(i*200)})


            var legend = d3.select("#bar_holder")
                .append("g")
                .attr('id', 'legend')
                .attr("transform",
                      "translate(" + innerWidth*.8 + "," + outerHeight*0.0 + ")");

            // add positive driver square
            legend
                .append("rect")
                .style("fill", "steelblue")
                .attr("x", '0px')
                .attr("width", '15px')
                .attr("y", '0px')
                .attr("height", 15)
                .style("fill", function(d){  return d3.schemeTableau10[0];});
            
            legend
                .append("text")
                .attr("x", "20px")
                .attr("y", '12px')
                .text("Positive Driver");



            // add positive driver square
            legend
                .append("rect")
                .style("fill", "steelblue")
                .attr("x", '0px')
                .attr("width", '15px')
                .attr("y", '20px')
                .attr("height", 15)
                .style("fill", function(d){  return d3.schemeTableau10[1];});
    
            legend
                .append("text")
                .attr("x", "20px")
                .attr("y", '32px')
                .text("Negative Driver");
                
        
        
            //title of plot
            // var title = svg.append("text")
            //     .attr("x", outerWidth/2)
            //     .attr("y", padding.top/2)
            //     .attr("id", "title")
            //     .text("Drivers of Liking");
        
            var x_axis_title = d3.select("#x_axis").append("text")
                .attr("id", "x_axis_title")
                .attr("x", (width/2))
                .attr("y", padding.bottom+(margin.bottom/2))
                .text("Variables");
        
            var y_axis_title = d3.select("#y_axis").append("text")
                .attr("id", "y_axis_title")
                .attr('transform', 'translate(' + (-padding.left) + ',' + ((height/2)) + ') rotate(-90)')
                .text("Liking Score");
        
    }



    return (
        <div className="drivers_chart" ref={ref}></div>
    )
}

export default Drivers