import React from 'react';
import Carousel from 'react-material-ui-carousel'
import { useState } from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import '../styles.scss';
import './carousel.css';
import BasicMenuSurvey from './basic_menu_survey';
import MobileStepper from '@mui/material/MobileStepper';
import Box from '@mui/material/Box'

// let varUseVAS = localStorage.getItem("storedUsingVAS");
// let isUsingVAS = varUseVAS;

const cards = [
    {
        card_id: 0,
        card_title: 'Does your project...',
        card_text: ''
    },
    {
        card_id: 1,
        card_title: 'Provide Dependent Variables',
        card_text: ''
    },
    {
        card_id: 2,
        card_title: 'Are you using a constants (bases)?',
        card_text: 'Otherwise, leave fields empty.'
    },
    {
        card_id: 3,
        card_title: 'Provide Independent Variables',
        card_text: ''
    },
    {
        card_id: 4,
        card_title: 'What is your intuition on the behavior of variables?',
        card_text: ''
    },
    {
        card_id: 5,
        card_title: 'Optional',
        card_text: '(If you are using a VAS Air)'
    },   
    {
        card_id: 6,
        card_title: 'Survey Responses',
        card_text: ''
    }
];

function BasicCarousel()
{
    const [counter, setCounter] = useState(0);

    const handleNext = () => setCounter(counter+1);
    
    const handlePrev = () => setCounter(counter-1);

    let samples = 5;
    
    // For some reason the max height of the card on the page is around 538
    return (
        <center>
        <Carousel autoPlay={false} navButtonsAlwaysVisible next={ handleNext } prev={ handlePrev }>
            <Card elevation={5} sx={{ maxWidth: 1371, height: 538, overflowY: 'scroll' }}>
            <CardContent>
                <Box sx={{ p: 1, border: 1, position: 'absolute', top: 25, right: 400 }}><Typography>Current Number of Samples: {samples}</Typography></Box>
                <MobileStepper
                variant="progress"
                steps={cards.length}
                position="static"
                activeStep={counter}
                sx={{ display: 'inline' }}
                />
                <h1>{cards[counter].card_title}</h1> 
                <h5>{cards[counter].card_text}</h5>  
                <BasicMenuSurvey index={counter} />
            </CardContent>
        </Card>
        </Carousel>
        </center>
    )
}

export default BasicCarousel;