import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Switch from '@mui/material/Switch';

                                                    // CheckboxesMC is used in both cases where they choose "CheckBox" or "Multiple Choice" for format

// initialize the non-duplicable inputs                                                    
const checkboxInput = {
    question: "",
    format: "",
    randomize: false,
    number_of_options: null,
    optionFields: [],
}

const CheckboxesMC = ({ format, setAddPages }) => {

    // initialize the state of the variables
    const [inputs, setInputs] = useState(checkboxInput);

    // What saves the input fields
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      let updatedValue = value;

      if (name === "number_of_options") {
          updatedValue = parseInt(value)

          if (name === "number_of_options" && value < inputs.number_of_options) {
              const diff = inputs.number_of_options - value;
              setInputs((prevInputs) => ({
                  ...prevInputs,
                  optionFields: prevInputs.optionFields.slice(0, prevInputs.optionFields.length - diff),
              }));
          }
      }

      setInputs((prevInputs) => ({
          ...prevInputs,
          [name]: updatedValue,
        }));
  };

    // Handles the switch input
    const handleSwitchChange = (e) => {
        setInputs(
        {
            ...inputs,
            [e.target.name]: e.target.checked
        }
        )
    }

    // Handles the Option Fields when changed

    const handleOptionChange = (e, index) => {
      const updatedFields = [...inputs.optionFields];
      updatedFields[index] = e.target.value;
      setInputs((prevInputs) => ({
        ...prevInputs,
        optionFields: updatedFields,
      }));
    };

    useEffect(() => {
    
      const checkbox = {
        question: inputs.question,
        format: format,
        randomize: inputs.randomize,
        num_options: inputs.number_of_options,
        options: inputs.optionFields,
      }
  
      setAddPages ( prevState  => ({ ...prevState,   secondary_survey_question: checkbox }));
  
    }, [inputs]);
    
    console.log(inputs);


    const Android12Switch = styled(Switch)(({ theme }) => ({
        padding: 8,
        '& .MuiSwitch-track': {
          borderRadius: 22 / 2,
          '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
          },
          '&:before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
              theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12,
          },
          '&:after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
              theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M19,13H5V11H19V13Z" /></svg>')`,
            right: 12,
          },
        },
        '& .MuiSwitch-thumb': {
          boxShadow: 'none',
          width: 16,
          height: 16,
          margin: 2,
        },
      }));    

    return (
        <>
        <Grid container direction="column">
            <Grid item>
              <FormControl>
                <Divider orientation='horizontal' sx={{ height: '10px' }} />
                <TextField
                    sx={{ minWidth: '315px' }}
                    label="Custom Question"
                    id="question"
                    variant="outlined"
                    name="question"
                    value={inputs.question}
                    onChange={handleInputChange}
                />
                </FormControl>
            </Grid>
            <Divider orientation='horizontal' sx={{ height: '10px' }} />
            <Grid item>
                <Box sx={{ display: 'flex' }} justifyContent="flex-start" alignItems="center">
                <FormControl>
                    <FormGroup>
                    <Divider orientation='horizontal' sx={{ height: '10px' }} />
                        <h5>Randomize</h5>
                        <FormControlLabel
                        sx={{ p: 0.5 }}
                        control={<Android12Switch checked={inputs.randomize}
                            onChange={handleSwitchChange} name="randomize" />}
                        />
                    </FormGroup>
                </FormControl>
                <Divider orientation='vertical' sx={{ width: '20px' }} />
                <TextField
                  sx={{ minWidth: 180 }}
                  inputProps={{ min: 2, max: 16 }}
                  label="Number of Options"
                  id="number_of_options"
                  variant="outlined"
                  type="number"
                  name="number_of_options"
                  value={inputs.number_of_options}
                  onChange={handleInputChange}
                />
                </Box>
            </Grid>
            {inputs.number_of_options && (
            <>
                <Divider orientation='horizontal' sx={{ height: '10px' }} />
                <Grid item sx={{ display: 'grid' }}>
                {[...Array(inputs.number_of_options)].map((_, index) => (
                    <div key={index}>
                    <TextField
                        label={`Field ${index + 1} (Optional)`}
                        variant="outlined"
                        inputProps={{ min: 2 }}
                        name={`field[${index}]`}
                        value={inputs.optionFields[index] || ""}
                        onChange={(e) => handleOptionChange(e, index)}
                        sx={{ width: '450px' }}
                    />
                    <Divider orientation='horizontal' sx={{ height: '10px' }} />
                    </div>
                ))}
                </Grid>
            </>
            )}
        </Grid>
        </>
    )
};

export default CheckboxesMC;