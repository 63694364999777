import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Switch from '@mui/material/Switch';
import { InputLabel } from '@mui/material';

// File inputs
import NumericScale from './NumericScale';
import CheckboxesMC from './Checkboxes';
import JARScale from './JAR_scale';

// Initialize the inputs

const baseInputs = {
    // question: "",
    // format: ""
}

const numericInputs = {
      showRatingLabels: false,
      min: null,
      max: null,
      step: null,
      shape: null,
      ratingLabelFields: [],
}

const SecondarySurveyQuestions = ({ setAddPages }) => {

  // Set input states
  const [inputs, setInputs] = useState(baseInputs);

  // Handle the inputs as they are received
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputs(prevState => ({ ...prevState, [name]: value }));
    setAddPages(inputs);
  };

  // useEffect(() => {
    
  //   const secondary_survey_question = {
  //       // question: inputs.question,
  //       format: inputs.format
  //   }

  //   setAddPages({ secondary_survey_question: secondary_survey_question });

  // }, [inputs]);

  console.log(inputs);


  return (
    <>
        <Grid container direction="row" alignItems="end">
            <Grid item>
                <FormControl>
                <InputLabel variant="outlined" id="format-label">Format</InputLabel>
                    <Select
                    sx={{ width: 160 }}
                    label="Format *"
                    labelId="format-label"
                    id="format"
                    value={inputs.format}
                    name="format"
                    onChange={handleInputChange}
                    >
                    <MenuItem value="Numeric Scale">Numeric Scale</MenuItem>
                    <MenuItem value="JAR Scale">JAR Scale</MenuItem>
                    <MenuItem value="Checkbox">Checkbox</MenuItem>
                    <MenuItem value="Multiple Choice">Multiple Choice</MenuItem>
                    <MenuItem value="Slider">Slider</MenuItem>
                    <MenuItem value="Plain Text">Plain Text</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
        <Divider orientation='horizontal' sx={{ height: '10px' }} />
            {inputs.format === "Numeric Scale" || inputs.format === "Slider" ? 
            <NumericScale format={inputs.format} setAddPages={setAddPages} /> : <div></div>
            }{inputs.format === "JAR Scale" ?
            <JARScale format={inputs.format} setAddPages={setAddPages}/> : <div></div>
            }{inputs.format === "Checkbox" || inputs.format === "Multiple Choice" ? 
            <CheckboxesMC format={inputs.format} setAddPages={setAddPages}/> : <div></div>
            }
            <Divider orientation='horizontal' sx={{ height: '10px' }} />        
    </>
  );
};

export default SecondarySurveyQuestions;
