import * as React from 'react';
import { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { IconButton, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

const ContinuousVariables = ({ setConfigInputs }) => {


    const [variablesList, setVariablesList] = useState([
        {
          variable_names: "", variable_types: "Continuous", variable_descriptions: "", variable_lows: 0, variable_highs: 1, variable_units: "", variable_material_codes: ""
        },
        {
          variable_names: "", variable_types: "Continuous", variable_descriptions: "", variable_lows: 0, variable_highs: 1, variable_units: "", variable_material_codes: ""
        },
      ]);


       // when variablesList is modified, we change the values of setConfigInputs to make API call in CreateAdvanced
       useEffect(() => {
            
        let all_var_names = variablesList.map(d => d.variable_names).filter(n => n);
        let all_var_types = variablesList.map(d => d.variable_types).filter(n => n);
        let all_var_variable_lows = variablesList.map(d => d.variable_lows).filter(number => typeof number == 'number');
        let all_var_variable_highs = variablesList.map(d => d.variable_highs).filter(number => typeof number == 'number');
        let all_var_units = variablesList.map(d => d.variable_units).filter(n => n);
        let all_material_codes = variablesList.map(d => d.variable_material_codes).filter(n => n);
        let all_var_descriptions = variablesList.map(d => d.variable_descriptions).filter(n => n);
        let all_var_categories = variablesList.map(d => d.variable_categories).filter(n => n);


        setConfigInputs( prevState  => ({ ...prevState,   variable_names: all_var_names }));
        setConfigInputs( prevState  => ({ ...prevState,   variable_types: all_var_types }));
        setConfigInputs( prevState  => ({ ...prevState,   variable_lows: all_var_variable_lows }));
        setConfigInputs( prevState  => ({ ...prevState,   variable_highs: all_var_variable_highs }));

        setConfigInputs( prevState  => ({ ...prevState,   variable_units: all_var_units }));
        setConfigInputs( prevState  => ({ ...prevState,   variable_material_codes: all_material_codes }));
        setConfigInputs( prevState  => ({ ...prevState,   variable_descriptions: all_var_descriptions }));
        setConfigInputs( prevState  => ({ ...prevState,   variable_categories: all_var_categories }));

      }, [variablesList]);

    
      let addVariableFields = () => {
        setVariablesList([...variablesList, {
          variable_names: "", variable_types: "Continuous", variable_descriptions: "", variable_lows: 0, variable_highs: 1, variable_units: "", variable_material_codes: ""
        }])
      }
    
      let removeVariableFields = (i) => {
        let newVariables = [...variablesList];
        newVariables.splice(i, 1);
        setVariablesList(newVariables);
      }
    


      // This is what happens when the variables are changed in the variable fields
      const handleVariableChange = (e, index) => {
        let newVariables = [...variablesList];
        if (e.target.name === "variable_lows" || e.target.name === "variable_highs") {
          newVariables[index][e.target.name] = parseInt(e.target.value);
        } else {
          newVariables[index][e.target.name] = e.target.value;
        }
        setVariablesList(newVariables);
      };


    return (
        <Grid container>
            <Grid item xs={12}>
                <h5>Variables</h5>
            </Grid>
            {variablesList.map((element, index) => (
                <div key={index} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '12px' }}>
                    <Grid item xs={2}>
                    <TextField
                        required
                        label="Name"
                        id="variable_names"
                        variant="outlined"
                        name="variable_names"
                        value={element.variable_names}
                        onChange={(e) => handleVariableChange(e, index)}
                    />
                    </Grid>
                    <Divider orientation='vertical' sx={{ width: '10px' }} />
                    <Grid item xs={2}>
                    <TextField
                        required
                        label="Type"
                        id="variable_types"
                        variant="outlined"
                        name="variable_types"
                        value={element.variable_types}
                        onChange={(e) => handleVariableChange(e, index)}
                    />
                    </Grid>
                    <Divider orientation='vertical' sx={{ width: '10px' }} />
                    <Grid item xs={2}>
                    <TextField
                        label="Descriptions (Optional)"
                        id="variable_descriptions"
                        variant="outlined"
                        name="variable_descriptions"
                        value={element.variable_descriptions}
                        onChange={(e) => handleVariableChange(e, index)}
                        // sx={{ minWidth: 250 }}
                    />
                    </Grid>
                    <Divider orientation='vertical' sx={{ width: '10px' }} />
                    <Grid item xs={2}>
                    <TextField
                        label="Units (Optional)"
                        id="variable_units"
                        variant="outlined"
                        name="variable_units"
                        value={element.variable_units}
                        onChange={(e) => handleVariableChange(e, index)}
                    />
                    </Grid>
                    <Divider orientation='vertical' sx={{ width: '10px' }} />
                    <Grid item xs={2}>
                    <TextField
                        label="Material Code (Optional)"
                        id="variable_material_codes"
                        variant="outlined"
                        name="variable_material_codes"
                        value={element.variable_material_codes}
                        onChange={(e) => handleVariableChange(e, index)}
                    />
                    </Grid>
                    <Divider orientation='vertical' sx={{ width: '10px' }} />
                    <Grid item xs={2}>
                    <TextField
                        label="Low Level"
                        required
                        inputProps={{ min: 0 }}
                        // sx={{ width: 100 }}
                        id="variable_lows"
                        variant="outlined"
                        type="number"
                        name="variable_lows"
                        value={element.variable_lows}
                        onChange={(e) => handleVariableChange(e, index)}
                    />
                    </Grid>
                    <Divider orientation='vertical' sx={{ width: '10px' }} />
                    <Grid item xs={2}>
                    <TextField
                        required
                        inputProps={{ min: element.variable_lows+1 }}
                        label="High Level"
                        id="variable_highs"
                        variant="outlined"
                        type="number"
                        name="variable_highs"
                        value={element.variable_highs}
                        onChange={(e) => handleVariableChange(e, index)}
                    />
                    </Grid>
                    <Grid item xs={1}>
                    {variablesList.length !== 1 &&
                        (<IconButton sx={{ display: 'block'}} aria-label="delete" className="remove-btn" onClick={() => removeVariableFields(index)}>
                            <DeleteIcon />
                        </IconButton>)}
                    </Grid>                    
                </div>                
            ))}
            <Grid item xs={12}>    
                <div style={{ display: 'flex', justifyContent: 'center' }}>         
                <Button sx={{ display: 'block' }} aria-label="add" variant="contained" className="add-btn" onClick={() => addVariableFields()} size="large">
                    <AddIcon/>
                </Button>      
                </div>               
            </Grid>
        </Grid>
    );
}


export default ContinuousVariables;