import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Switch from '@mui/material/Switch';
import { InputLabel } from '@mui/material';

// Initialize the inputs

const numericInputs = {
    title: "",
    background: ""
}

const InstructionsForUse = ({ setAddPages }) => {

  // Set input states
  const [inputs, setInputs] = useState(numericInputs);

  // Handle the inputs as they are received
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputs(prevState => ({ ...prevState, [name]: value }));
    setAddPages(inputs);
  };

  useEffect(() => {
    
    const IFU = {
        title: inputs.title,
        background: inputs.background
    }

    let instructions = IFU;

    setAddPages( prevState  => ({ ...prevState,   instructions: instructions }));

  }, [inputs]);

  console.log(inputs);

  return (
    <Grid container direction="column">
        <Grid item>
            <h5>Title</h5>
            <TextField
                sx={{ minWidth: '100%' }}
                label="Title"
                id="title"
                variant="outlined"
                name="title"
                value={inputs.title}
                onChange={handleInputChange}
            />
            </Grid>
            <Divider orientation='horizontal' sx={{ height: '10px' }} />
            <Grid item>
            <h5>Project Background</h5>
            <Divider orientation='horizontal' sx={{ height: '10px' }} />
            <TextField
                sx={{ minWidth: '100%' }}
                label="Description"
                multiline
                rows={4}
                id="background"
                variant="outlined"
                name="background"
                value={inputs.nackground}
                onChange={handleInputChange}
            />
        </Grid>
    </Grid>
  );
};

export default InstructionsForUse;
