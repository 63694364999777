import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import * as d3 from 'd3';
import { useRef } from 'react';

import { useState } from 'react';
import BarChart from './BarChart';
import PlotSlice from './charts/PlotSlice';
import Interactions from './charts/Interactions';
import Drivers from './charts/Drivers';
import Swal from 'sweetalert2';
import logo from './logo.png';
import { useEffect } from 'react';


const AnalyzeMilk = () => {



    // Swal.fire({
    //     title: 'M!lk-4-All Demo',
    //     html: `<input type="password" id="password" class="swal2-input" placeholder="">`,
    //     confirmButtonText: 'Access Project',
    //     focusConfirm: true,
    //     preConfirm: () => {
    //       const password = Swal.getPopup().querySelector('#password').value
    //       if (password !== 'milk4all') {
    //         Swal.showValidationMessage(`Please enter the correct session code`)
    //       }
    //       return { password: password }
    //     }
    //   }).then((result) => {
    //     Swal.fire( {
    //         title:"🥛🐮 Drink Up! 🐮🥛",
    //         text: "What you will see is a web-based preview of ATOM3. We are under 🚧 but want to provide a sneak peek of the progress as well as preview the results of Milk-4-All. ATOM3 will provide more modeling capability than ever to solve complex problems and win new business. Additionally, Givaudan team members can directly invite customers to a project 🤝 to facilitate better partnership and outcomes for all!"
    //      } )
    //   })

    const variables =
        [
            {
                name: "Rice Syrup",
                Dev_Code: "Non-Coded",
                Values: "1 to 6%",
                Description: "Meurens Natu-Rice 35-75(Brx =75 ; DE = 35)"
            },
            {
                name: "Rice Syrup II",
                Dev_Code: "Non-Coded",
                Values: "1 to 6%",
                Description: "Meurens Natu-Rice 62 (Brix = 80 ; DE = 62)"
            },
            {
                name: "Chickpea Protein",
                Dev_Code: "Non-Coded",
                Values: "0.7 to 2.8%",
                Description: "Chickpea protein concentrate: Innovopro, C70"
            },
            {
                name: "Pea Protein",
                Dev_Code: "Non-Coded",
                Values: "0.52 to 2.8%",
                Description: "Pea protein isolate: Cosucra Pisane C9"
            },
            {
                name: "Soy Protein",
                Dev_Code: "Non-Coded",
                Values: "0 to 2.09%",
                Description: "Soy protein isolate: Gushen 5200 A"
            },
            {
                name: "Rice Protein",
                Dev_Code: "Non-Coded",
                Values: "0.49 to 1.94%",
                Description: "hydrolyzed, Wuxi Jinnong Biotechnology, JNSP-N-Smooth B"
            }
        ]

    // used for plotSlice
    const coefficients = [
        { name: 'intercept', estimate: 3.865595779, type: 'linear' },
        { name: 'Rice Syrup', estimate: 0.0202607675125901, type: 'linear' },
        { name: 'Rice Syrup II', estimate: -0.020113184372154, type: 'linear' },
        { name: 'Chickpea Protein', estimate: 0.012480763230856, type: 'linear' },
        { name: 'Pea Protein', estimate: -0.0255557333212987, type: 'linear' },
        { name: 'Soy Protein', estimate: -0.0103611886242011, type: 'linear' },
        { name: 'Rice Protein', estimate: 0.0094413653559542, type: 'linear' },
        { name: 'Rice Syrup:Rice Syrup II', estimate: -0.00000263654204882245, type: 'interaction' },
        { name: 'Rice Syrup:Pea Protein', estimate: 0.00000881254211672265, type: 'interaction' },
        { name: 'Rice Syrup:Soy Protein', estimate: -0.00000394365384912503, type: 'interaction' },
        { name: 'Rice Syrup:Rice Protein', estimate: -0.00000738237671044489, type: 'interaction' },
        { name: 'Rice Syrup II:Soy Protein', estimate: 0.00000341101081669412, type: 'interaction' },
        { name: 'Chickpea Protein:Pea Protein', estimate: 0.00000625391134079604, type: 'interaction' },
        { name: 'Chickpea Protein:Rice Protein', estimate: 0.00001290314087491920, type: 'interaction' },
        { name: 'Pea Protein:Rice Protein', estimate: -0.00001520892207999870, type: 'interaction' },
        { name: 'Rice Syrup^2', estimate: -0.0000280445207677075, type: 'polynomial' },
        { name: 'Rice Syrup II^2', estimate: 0.0000302304946930177, type: 'polynomial' },
        { name: 'Chickpea Protein^2', estimate: -0.00004847365247996330, type: 'polynomial' },
        { name: 'Pea Protein^2', estimate: 0.00006663658850072670, type: 'polynomial' },
        { name: 'Soy Protein^2', estimate: 0.00004879159419705640, type: 'polynomial' },
        { name: 'Rice Protein^2', estimate: -0.00005261204927867250, type: 'polynomial'}
    ]

    const linear_coefficients = [
        { name: 'Rice Syrup', estimate: 0.0202607675125901, type: 'linear', tstat: 1.615593639, range: "1 to 6%", explain:"Rice Syrup raised liking more than anything else"},
        { name: 'Rice Syrup II', estimate: -0.020113184372154, type: 'linear', tstat: -0.38967798, range: "1 to 6%", explain: ""},
        { name: 'Chickpea Protein', estimate: 0.012480763230856, type: 'linear', tstat: -0.600772446, range: "0.52 to 2.8%", explain: "" },
        { name: 'Pea Protein', estimate: -0.0255557333212987, type: 'linear', tstat: -1.875845146, range: "0.52 to 2.8%", explain: "Pea Protein lowered liking more than any other protein" },
        { name: 'Soy Protein', estimate: -0.0103611886242011, type: 'linear', tstat: -0.387102545, range: "0 to 2.09%",  explain: "Soy Protein was the most favored protein for maximizing liking"},
        { name: 'Rice Protein', estimate: 0.0094413653559542, type: 'linear', tstat: -0.63073966, range: "0.49 to 1.94%", explain: "" }
    ]


    const project_information = {
        title: 'M!lk-4-All',
        owner: 'David Potts',
        collaborators: 'Jacob E. Farr and Rahul Siva',
        customer: 'FX Competition',
        brief: 'RPM 5060',
        created: 'January 2023',
        analyzed: 'April 2023',
        background: 'Every day Givaudan receives requests from customers in every region to help optimize their plant based milk base using our array of ingredients to enhance food experience for our consumers. The requirements for each region could be different based on the varied expectations from consumers say in China vs Brazil. So the team decided to use ATOM to develop a global platform that can be easily customized for each regional requirements.',
        goal: 'This study will use ATOM to mimic the performance of dairy whole fat milk using plant based ingredients in terms of taste, mouthfeel, stability, color etc.'
    }

    const interaction_container_ref = useRef(null);
    const drivers_container_ref = useRef(null);
    const plotslice_container_ref = useRef(null);


    const Top_combinations =
        [
            {
                Title: "Best Profile Constrained 1",
                Rice_syrup: "3.07%",
                Rice_syrup_2: "6.00%",
                Chickpea_Protein: "1.25%",
                Pea_Protein: "0.54%",
                Soy_Protein: "2.09%",
                Rice_Protein: "0.54%",
                Sugar: "3.99%",
                Protein: "3.5%",
                Predicted_Liking: "8.55"
            },
            {
                Title: "Best Profile Constrained 2",
                Rice_syrup: "3.07%",
                Rice_syrup_2: "6.00%",
                Chickpea_Protein: "0.70%",
                Pea_Protein: "1.03%",
                Soy_Protein: "2.09%",
                Rice_Protein: "0.49%",
                Sugar: "3.99%",
                Protein: "3.5%",
                Predicted_Liking: "8.5"
            },
            {
                Title: "Best Profile Constrained 3",
                Rice_syrup: "3.07%",
                Rice_syrup_2: "6.00%",
                Chickpea_Protein: "1.00%",
                Pea_Protein: "0.76%",
                Soy_Protein: "2.09%",
                Rice_Protein: "0.60%",
                Sugar: "3.99%",
                Protein: "3.58%",
                Predicted_Liking: "8.39"
            },
            {
                Title: "Wild Card Optimum  (Low Sugar)",
                Rice_syrup: "4.09%",
                Rice_syrup_2: "1.00%",
                Chickpea_Protein: "1.05%",
                Pea_Protein: "0.61%",
                Soy_Protein: "2.04%",
                Rice_Protein: "0.63%",
                Sugar: "1.5%",
                Protein: "3.5%",
                Predicted_Liking: "7.57"
            },
            {
                Title: "Best Profile without Constrains",
                Rice_syrup: "3.18%",
                Rice_syrup_2: "6.00%",
                Chickpea_Protein: "0.70%",
                Pea_Protein: "0.54%",
                Soy_Protein: "0%",
                Rice_Protein: "0.49%",
                Sugar: "4.02%",
                Protein: "1.36%",
                Predicted_Liking: "8.9"
            },
            {
                Title: "Worst Profile",
                Rice_syrup: "1.00%",
                Rice_syrup_2: "1.00%",
                Chickpea_Protein: "2.80%",
                Pea_Protein: "2.14%",
                Soy_Protein: "2.09%",
                Rice_Protein: "1.89%",
                Sugar: "0.79%",
                Protein: "7%",
                Predicted_Liking: "2.36"
            }
        ]
 
    //add t-stat
    let interaction_data = [
        { "source": "Rice syrup", "target": "Rice syrup II", "value": 0, "tstat": -2.64E-06, "coefficient": -0.40484757},
        { "source": "Rice syrup", "target": "Chickpea", "value": 0, "tstat": 0, "coefficient": 0},
        { "source": "Rice syrup", "target": "Pea Protein", "value": 0, "tstat": 8.81E-06, "coefficient": 0.225592527},
        { "source": "Rice syrup", "target": "Soy protein", "value": 0, "tstat": -3.94E-06, "coefficient": -0.787468432},
        { "source": "Rice syrup", "target": "Rice Protein", "value": 0, "tstat": -7.38E-06, "coefficient": -0.63450053},
        { "source": "Rice syrup", "target": "Chickpea", "value": 0, "tstat": 0, "coefficient": 0},
        { "source": "Rice syrup", "target": "Pea Protein", "value": 0, "tstat": 0, "coefficient": 0},
        { "source": "Rice syrup", "target": "Soy protein", "value": 0, "tstat": 3.41E-06, "coefficient": 0.373697601},
        { "source": "Rice syrup", "target": "Rice Protein", "value": 0, "tstat": 0, "coefficient": 0},
        { "source": "Chickpea", "target": "Pea Protein", "value": 0, "tstat": 6.25E-06, "coefficient": 0.253954738},
        { "source": "Chickpea", "target": "Soy protein", "value": 0, "tstat": 0, "coefficient": 0},
        { "source": "Chickpea", "target": "Rice Protein", "value": 0, "tstat": 1.29E-05, "coefficient": 0.253626713},
        { "source": "Pea Protein", "target": "Soy protein", "value": 0, "tstat": 0, "coefficient": 0},
        { "source": "Pea Protein", "target": "Rice Protein", "value": 0, "tstat": -1.52E-05, "coefficient": -0.122809772},
        { "source": "Soy protein", "target": "Rice Protein", "value": 0, "tstat": 0, "coefficient": 0},
        { "source": "Rice syrup", "target": "Rice syrup", "value": 0, "tstat": -2.80E-05, "coefficient": -0.967043221},
        { "source": "Rice syrup II", "target": "Rice syrup II", "value": 0, "tstat": 3.02E-05, "coefficient": 1.062712244},
        { "source": "Chickpea", "target": "Chickpea", "value": 0, "tstat": -4.85E-05, "coefficient": -0.001090325},
        { "source": "Pea Protein", "target": "Pea Protein", "value": 0, "tstat": 6.66E-05, "coefficient": 0.589441248},
        { "source": "Soy protein", "target": "Soy protein", "value": 0, "tstat": 4.88E-05, "coefficient": 0.566304252},
        { "source": "Rice Protein", "target": "Rice Protein", "value": 0, "tstat": -5.26E-05, "coefficient": -0.296419079},
      ]

      const summary = {
        explanation: 'This study is going to help us uncover a different recipe for each customer for each region depending on their requirements. We came up with Recipe #31 for Danone that maximizes liking for EU consumers (Avg score of 6.1/8) while maintaining the nutritional requirements of sugar (<4%) and protein (>3.5%). The above sample performed exceedingly well with consumers and outperformed benchmarks in consumer trials.',
    }
    
    const major_finding = ["Positive Drivers – Rice Syrup 35-75 and Rice Syrup 62 are both positive drivers ", 
                            "Negative Drivers – Chickpea, Pea, Soy Protein and Rice Protein are all negative drivers to varying degrees. Perhaps Soy Protein is the relatively the lowest negative driver amongst others",
                             "Quadratic Effects – While Rice Syrup 35-75 is a positive driver, it has quadratic effect meaning its only liked at certain levels after that it could be too much", 
                             "Synergistic Effects - There is a prominent synergistic effect between Rice Syrup 35-75 Vs Rice Syrup 62. While Rice Syrup 62 is a positive driver in the absence of Rice Syrup 35-75, it becomes a negative driver if large quantity of Rice Syrup 35-75 is present "
                            ];
    const major_findings = (
        <ul>
          {major_finding.map((finding, index) => (
            <li>
              {` ${finding}`}
            </li>
          ))}
        </ul>
    ); 

    const combinations = {
        explanation: 'With the model built, we can optimize it to determine the best combinations for your applications. The table above shows the top and worst combinations. Constraints were set based on maintaining nutrition targets such as protein and sugar.',
    }

    const chart_explanation = {
        plotslice: 'This graph lets you to input any amount of ingredients to create your own custom recipe and will display the predicted liking score of your consumers in real time. This graph leverages the power of AI to let flavourists create new combinations with instant results and no cost to try.',
        drivers: 'This graph shows which of the ingredients studied have positive (blue) or negative (orange) impact on the taste.',
        interactions: 'A flavour is typically a byproduct of the complex synergistic effects between its various ingredients, thats what creates the magic. We use AI to break down the magic and bring the science out. This graph visualizes the synergistic effects between the ingredients. '
    }


    useEffect(() => {
        document.title = "M!lk-4-All";  
      }, []);

    return (
        <div >
            {/* set up header of page with project information --> make component */}
            <Container>
                
                <Row>
                    <Col><h2 class="display-4 pb-3 text-center font-weight-bold">{project_information.title}</h2></Col>
                </Row>

                <Row>
                <div class="text-center">
                    <img src={logo} alt="nu-moo logo" style={{ width: 157, height: 150}}/>
                </div>
                </Row>

                <Row className='p-3 display-inline'>
                    <Col><strong>Project Lead: </strong>{project_information.owner}</Col>
                    <Col><strong>Team Member(s): </strong>{project_information.collaborators}</Col>
                    <Col><strong>Customer: </strong>{project_information.customer}</Col>
                </Row>
                <Row className='p-3'>
                    <Col><strong>Brief or Project: </strong>{project_information.brief}</Col>
                    <Col><strong>Date Created: </strong>{project_information.created}</Col>
                    <Col><strong>Date Analyzed: </strong>{project_information.analyzed}</Col>
                </Row>
                <Row className='p-3'>
                    <Col><strong>Background: </strong>{project_information.background}</Col>
                </Row>

                <Row className='p-3'>
                    <Col><strong>Goal: </strong>{project_information.goal}</Col>
                </Row>
                <Row className='p-3'></Row>


                {/* set up variable table information */}
                <Row>
                    <h2 className="text-center">Data Collected</h2>
                </Row>
                <Row><table class="table table-hover">
                    <thead>
                        <tr>
                            <th scope="Variable">Variable</th>
                            <th scope="MG/Dev Code">MG/Dev Code</th>
                            <th scope="Variable">Values</th>
                            <th scope="Variable">Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        {variables.map((variable) => (
                            <tr>
                                <td>{variable.name}</td>
                                <td>{variable.Dev_Code}</td>
                                <td>{variable.Values}</td>
                                <td>{variable.Description}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                </Row>

                <Row>
                    <Col><p className='pt-3 pb-3'>17 samples were evaluated across 6 variables for this project.</p></Col>
                </Row>
                <Row className='p-3' ref={drivers_container_ref} >
                    <h2 className="text-center">Drivers of Liking</h2>
                    <Drivers driver_container_ref = {drivers_container_ref} chart_data = {linear_coefficients} width={'100%'} height={600} />
                    <Col className='p-3'><strong>Explanation: </strong>{chart_explanation.drivers}</Col>
                </Row>

                <Row className='p-3'></Row>
                {/* PlotSlice to display interaction of variables and prediction */}
                <Row>
                    <h2 className="text-center">Model Performance</h2>
                </Row>
                <Row ref={plotslice_container_ref}>
                    <PlotSlice plotslice_container_ref={plotslice_container_ref} chart_data={coefficients} width={'100%'} height={600} />
                    <Col><strong>Explanation: </strong>{chart_explanation.plotslice}</Col>
                </Row>
                <Row className='p-3'></Row>
                <Row className='pt-3' ref={interaction_container_ref} >
                    <h2 className="text-center">Interaction of Factors</h2>
                    <Interactions interaction_container_ref = {interaction_container_ref} chart_data = {interaction_data} width={'100%'} height={600} />
                    <Col className='pb-3'><strong>Explanation: </strong>{chart_explanation.interactions}</Col>
                </Row>
                <Row className='p-3'></Row>
                <Row>
                    <h2 className="text-center p-3">Best and Worst Combinations</h2>
                    <center><table class="table table-hover">
                        <thead>
                            <tr>
                                <th></th>
                                <th scope="Rice Syrup">Rice Syrup</th>
                                <th scope="Rice Syrup II">Rice Syrup II</th>
                                <th scope="Chickpea Protein">Chickpea Protein</th>
                                <th scope="Pea Protein">Pea Protein</th>
                                <th scope="Soy Protein">Soy Protein</th>
                                <th scope="Rice Protein">Rice Protein</th>
                                <th scope="Sugar (<4%)">Sugar</th>
                                <th scope="Protein (>3.5%">Protein</th>
                                <th scope="Predictive_Score">Liking Score</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Top_combinations.map((combo) => (
                                <tr>
                                    <td>{combo.Title}</td>
                                    <td align="center">{combo.Rice_syrup}</td>
                                    <td align="center">{combo.Rice_syrup_2}</td>
                                    <td align="center">{combo.Chickpea_Protein}</td>
                                    <td align="center">{combo.Pea_Protein}</td>
                                    <td align="center">{combo.Soy_Protein}</td>
                                    <td align="center">{combo.Rice_Protein}</td>
                                    <td align="center">{combo.Sugar}</td>
                                    <td align="center">{combo.Protein}</td>
                                    <td align="center">{combo.Predicted_Liking}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table></center>
                </Row>
                <Row className='p-3'>
                    <Col><strong>Explanation: </strong>{combinations.explanation}</Col>
                </Row>
                <Row className='p-3'></Row>
                <Row className='p-3'>
                    <h2 className="text-center">Summary</h2>
                </Row>
                <Row className='p-3'>
                    <Col><strong>Explanation: </strong>{summary.explanation}</Col>
                </Row>

                {/* Adding Major Findings Part Here */}
                <Row className='p-3'></Row>
                <Row className='p-3'>
                    <h2 className="text-center">Major Findings</h2>
                </Row>
                <Row className='p-3'>
                    <Col>{major_findings}</Col>
                </Row>


            </Container>
        </div>

    )
};

export default AnalyzeMilk;