import React, { useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { login } from '../../actions/auth';
import { Alert } from '@mui/material';

const Login = ({ login, isAuthenticated }) => {
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });

    const { email, password } = formData;

    const [ loginFailMessage, setloginFailMessage ] = useState(false)

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

    const onSubmit = e => {
        e.preventDefault();

        login(email, password);
        
        if (!isAuthenticated) {
            setloginFailMessage(true);
        }

    }
    
    if (isAuthenticated) {
        return (<Navigate to='/dashboard' />);

    } else {

    return (
        <div className='container mt-2'>
            <h1>Sign In</h1>
            <p>Sign into your Account</p>
            <form onSubmit={e => onSubmit(e)}>
                <div className='form-group pb-2'>
                    <label for="emailInput" class="form-label">Email address:</label>
                    <input 
                        id='emailInput'
                        className='form-control'
                        type='email'
                        name='email'
                        value={email}
                        onChange={e => onChange(e)}
                        placeholder="email"
                        required
                    />
                </div>
                <div className='form-group pb-3'>
                <label for="passwordInput" class="form-label">Password:</label>
                    <input 
                        id='passwordInput'
                        className='form-control'
                        type='password'
                        name='password'
                        value={password}
                        onChange={e => onChange(e)}
                        minLength='6'
                        placeholder="password"
                        required
                    />
                </div>


                <button className='btn btn-primary pt-1' type='submit'>Login</button>
            </form>
            {loginFailMessage ? <Alert className='mt-3' severity="error">The email address and password provided did not work. Try creating an account or resetting your password below.</Alert> :<></> }
            <p className='mt-3'>
                Don't have an account? <Link to='/signup'>Sign Up</Link>
            </p>
            <p className='mt-3'>
                Forgot your Password? <Link to='/reset-password'>Reset Password</Link>
            </p>
        </div>
    );
    }
};


const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { login })(Login);