import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import Switch from '@mui/material/Switch';
import Drawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';

const drawerWidth = 400;

// Input values that the form saves to
const inputs = {
    fullScreen: true,
    loopingSurvey: true,
    screenSaver: null,
    override: true,
    disableAtomRec: false,
    randomizePresentationOrder: false,
    inactivityTimeout: true,
    selectTimeoutLimit: "",
    backButton: true,
    nextButton: true,
    pauseButton: true,
    quitButton: true,
    progressIndicator: true,
  };

const Administer = ({ isAuthenticated, access_token }) => {
    // This is where the inputs are saved to
    const [surveyInputs, setSurveyInputs] = useState(inputs);
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileInputChange = (event) => {
        setSelectedFile(event.target.files[0]);
    }
  
    // What saves the survey setting values once there is a change in the input field
    const handleSurveySettingChange = (e) => {
      const { name, value } = e.target;
      setSurveyInputs({
          ...surveyInputs,
          [name]: value,
      });
    };

    // Used for the switch changing
  const handleSwitchChange = (e) => {
    setSurveyInputs(
        {
            ...surveyInputs,
            [e.target.name]: e.target.checked
        }
        )
    }

    const Android12Switch = styled(Switch)(({ theme }) => ({
        padding: 8,
        '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
        },
        '&:before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
            theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12,
        },
        '&:after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
            theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M19,13H5V11H19V13Z" /></svg>')`,
            right: 12,
        },
        },
        '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
        },
        }));

    return (
        <Box sx={{ p: 2, display: 'flex' }}>
        {/* The drawer is where all of the survey settings are located */}
        <Drawer
            variant="permanent"
            containerstyle={{height: 'calc(100%-64px)', top: 64}}
            sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', position: 'absolute', top: 64, zIndex: 'tootip' },
            }}
        >

            {/* This box holds all of the variable input fields. */}
            <Box sx={{ overflow: 'auto', position: 'absolute', top: 0, left: 30 }}>
            <Divider orientation='horizontal' sx={{ height: '10px' }} />
            <h3>Project Settings</h3>
            <Divider orientation='horizontal' sx={{ height: '10px' }} />
                <FormControl>
                    <FormGroup>
                    <h5>Full Screen Mode</h5>
                    <FormControlLabel 
                    sx={{p:0.5}}
                    control={<Android12Switch checked={surveyInputs.fullScreen}  
                    onChange={handleSwitchChange} name="fullScreen" />} 
                    />
                    <h5>Looping Survey</h5>
                    <FormControlLabel 
                    sx={{p:0.5}}
                    control={<Android12Switch checked={surveyInputs.loopingSurvey}  
                    onChange={handleSwitchChange} name="loopingSurvey" />} 
                    />
                    <h5>Screen Saver</h5>
                    <IconButton color="primary" aria-label="upload picture" component="label">
                        <label htmlFor="image-input">
                            <AddCircleIcon />
                        </label>
                        <Input id="image-input" accept="image/*" type="file" name="screenSaver" value={surveyInputs.screenSaver} 
                        onChange={handleSurveySettingChange} style={{ display: 'none'}} />
                    </IconButton>
                    {surveyInputs.screenSaver}
                    {console.log(surveyInputs.screenSaver)}
                    <Divider orientation='horizontal' sx={{ height: '10px' }} />
                    <h5>Override Survey Options</h5>
                    <FormControlLabel 
                    sx={{p:0.5}}
                    control={<Android12Switch checked={surveyInputs.override}  
                    onChange={handleSwitchChange} name="override" />} 
                    defaultChecked
                    />
                    {surveyInputs.override === true ?
                    <>
                        <h5>Disable ATOM Recommendations</h5>
                            <FormControlLabel 
                            sx={{p:0.5}}
                            control={<Android12Switch checked={surveyInputs.disableAtomRec}  
                            onChange={handleSwitchChange} name="disableAtomRec" />} 
                            />
                            <h5>Randomize Presentation Order</h5>
                            <FormControlLabel 
                            sx={{p:0.5}}
                            control={<Android12Switch checked={surveyInputs.randomizePresentationOrder}  
                            onChange={handleSwitchChange} name="randomizePresentationOrder" />} 
                            />
                            <h5>Inactivity Timeout</h5>
                            <FormControlLabel 
                            sx={{p:0.5}}
                            control={<Android12Switch checked={surveyInputs.inactivityTimeout}  
                            onChange={handleSwitchChange} name="inactivityTimeout" />} 
                            />
                            <Divider orientation='horizontal' sx={{ height: '10px' }} />
                                <h5>Select Timeout Limit</h5>
                                <div>
                                <FormControl sx={{ minWidth: 150 }}>
                                <Select
                                    id="timeout-limit"
                                    defaultValue='20 min.'
                                    value={surveyInputs.selectTimeoutLimit}
                                    name="selectTimeoutLimit"
                                    onChange={handleSurveySettingChange}
                                >
                                    <MenuItem value="one-minute">1 min.</MenuItem>
                                    <MenuItem value="two-minutes">2 min.</MenuItem>
                                    <MenuItem value="five-minutes">5 min.</MenuItem>
                                    <MenuItem value="ten-minutes">10 min.</MenuItem>
                                    <MenuItem value="twenty-minutes">20 min.</MenuItem>
                                </Select>
                                </FormControl>
                                </div>
                            <Divider orientation='horizontal' sx={{ height: '20px' }} />
                            <h5>Back Button</h5>
                            <FormControlLabel 
                            sx={{p:0.5}}
                            control={<Android12Switch checked={surveyInputs.inactivityTimeout}  
                            onChange={handleSwitchChange} name="inactivityTimeout" />} 
                            />
                            <h5>Next Button</h5>
                            <FormControlLabel 
                            sx={{p:0.5}}
                            control={<Android12Switch checked={surveyInputs.inactivityTimeout}  
                            onChange={handleSwitchChange} name="inactivityTimeout" />} 
                            />
                            <h5>Pause Button</h5>
                            <FormControlLabel 
                            sx={{p:0.5}}
                            control={<Android12Switch checked={surveyInputs.inactivityTimeout}  
                            onChange={handleSwitchChange} name="inactivityTimeout" />} 
                            />
                            <h5>Quit Button</h5>
                            <FormControlLabel 
                            sx={{p:0.5}}
                            control={<Android12Switch checked={surveyInputs.inactivityTimeout}  
                            onChange={handleSwitchChange} name="inactivityTimeout" />} 
                            />
                            <h5>Progress Indicator</h5>
                            <FormControlLabel 
                            sx={{p:0.5}}
                            control={<Android12Switch checked={surveyInputs.inactivityTimeout}  
                            onChange={handleSwitchChange} name="inactivityTimeout" />} 
                            />
                    </> : <div></div>}
                    </FormGroup>
                    </FormControl>
                    {console.log(surveyInputs)}
            </Box>
        </Drawer>
    </Box>
    );
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    access_token: state.auth.access
  });
  
export default connect(mapStateToProps, { })(Administer);