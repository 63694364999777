import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Switch from '@mui/material/Switch';
import { InputLabel } from '@mui/material';


// Import lower-level components
import PrimarySurveyQuestion from './PrimarySurveyQuestion';
import InstructionsForUse from './InstructionsForUse';
import DemographicQuestions from './DemographicQuestions';
import ATOMRecommendations from './ATOM_Recommendations';
import SecondarySurveyQuestions from './SecondarySurveyQuestions';


// Initial state for your inputs
const addPagesInputs = {
  primaryShowRatingScale: false,
  primaryScale: null,
  primaryShape: "Star",
  instructionsTitle: "",
  instructionsBackground: "",
  ageRange: false,
  gender: false,
  race: false,
  randomizeAnswerChoice: false,
  driversOfLiking: false,
  showRecommendations: true,
};


const CustomGridItem = ({ id, title, defaultText, inputs, onInputChange, showDelete, onDelete, setAddPages }) => {
  
  // Set state of isEditing to false, and change to true when they click the edit button

  const [isEditing, setIsEditing] = useState(false);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {    
    setIsEditing(false);
  };

  // 

  const handleInputChangeWrapper = (event) => {
    const { name, value } = event.target;
      
    // Pass the value for inputs
      onInputChange(id, name, value);
  };

  const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
      borderRadius: 22 / 2,
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 16,
        height: 16,
      },
      '&:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main),
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      '&:after': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main),
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      width: 16,
      height: 16,
      margin: 2,
    },
  }));

  return (
    <Grid container direction="column" style={{ maxWidth: '63%', border: '1px solid black', padding: '10px' }}>
      <Grid item>
        <Box className="titles-and-editDelete" display="flex" justifyContent="space-between">
          <Typography variant="h6">{title}</Typography>
            <Box className="testingClassName">
              {showDelete && (
                <IconButton size="small" color="error" onClick={onDelete}>
                  <DeleteIcon />
                </IconButton>
              )}
              <IconButton size="small" onClick={handleEdit}>
                <EditIcon />
              </IconButton>
            </Box>
        </Box>
      </Grid>
      <Grid item>
        {isEditing && title==="Primary Survey Question" ? (
          <>
          <PrimarySurveyQuestion setAddPages={setAddPages}/>
          <Button onClick={handleSave} startIcon={<SaveIcon />}>
              Save
            </Button>
            </>
        ) : (
          <div></div>
        )}
        {isEditing && title==="Secondary Survey Question" ? (
          // <Box display="inline">
          <>
            <SecondarySurveyQuestions setAddPages={setAddPages}/>  
            <Divider orientation='horizontal' sx={{ height: '10px' }} />
            <Button onClick={handleSave} startIcon={<SaveIcon />}>
              Save
            </Button>
            </>
          /* </Box> */
        ) : (
          <div></div>
        )}
        {isEditing && title==="Instructions for Use" ? (
          <>
            <InstructionsForUse setAddPages={setAddPages}/>
            <Divider orientation='horizontal' sx={{ height: '10px' }} />
            <Button onClick={handleSave} startIcon={<SaveIcon />}>
              Save
            </Button>
          </>
        ) : (
          <div></div>
        )}
        {isEditing && title==="Demographic Questions" ? (
          <>
            <DemographicQuestions setAddPages={setAddPages} />
            <Divider orientation='horizontal' sx={{ height: '10px' }} />
            <Button onClick={handleSave} startIcon={<SaveIcon />}>
              Save
            </Button>
          </>
        ) : (
          <div></div>
        )}
        {isEditing && title==="ATOM Recommendations" ? (
          <>
          <Grid container direction="column">
            <ATOMRecommendations />
            <Grid item>
            <Button onClick={handleSave} startIcon={<SaveIcon />}>
              Save
            </Button>
            </Grid>
          </Grid>
          </>
        ) : (
          <div></div>
        )}
      </Grid>
    </Grid>
  );
};

export default CustomGridItem;
