import * as React from 'react';
import Box from '@mui/material/Box';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Grid from '@mui/material/Grid';
import CustomGridItem from '../low_level_components/CreateGridItem';

const drawerWidth = 360;
const drawerHeight = 400;

const AddPages = ({ setAddPages }) => {

    const [itemInputs, setItemInputs] = useState({
      primaryShowRatingScale: false,
      primaryScale: null,
      primaryShape: "Star",
      instructionsTitle: "",
      instructionsBackground: "",
      ageRange: false,
      gender: false,
      race: false,
      randomizeAnswerChoice: false,
      driversOfLiking: false,
      showRecommendations: true,
    });
  
    const handleInputChange = (id, inputName, value) => {
        setItemInputs((prevItemInputs) => ({
        ...prevItemInputs,
        [id]: {
            ...prevItemInputs[id],
            [inputName]: value
        }
        }));


        //set addPagesInputs
        console.log('print all secondary questions values');

        const test = gridItems.filter(question => question.title === "Secondary Survey Question")
        console.log(test);

    };

    const [gridItems, setGridItems] = useState ([
        {
          id: 1,
          title: "Primary Survey Question",
          defaultText: "How much do you like this aroma?",
          showDelete: false,
        },
        {
          id: 2,
          title: "Instructions for Use",
          defaultText: "Instructions for Use",
          showDelete: true,
        },
        {
          id: 3,
          title: "Demographic Questions",
          defaultText: "Demographic Questions",
          showDelete: true,
        },
        {
          id: 4,
          title: "ATOM Recommendations",
          defaultText: "Strawberry, banana, kiwi",
          showDelete: true,
        }
      ]);

      const handleAddSecondaryQuestion = () => {
        setGridItems((prevGridItems) => {
          let newGridItems = [...prevGridItems];
      
          // Find the index of the last secondary question
          let lastSecondaryQuestionIndex = 0;
          for (let i = 0; i < newGridItems.length; i++) {
            if (newGridItems[i].id === 1) {
              lastSecondaryQuestionIndex = i;
              break;
            }
          }
      
          // Increment id values of the following items
          for (let i = lastSecondaryQuestionIndex + 1; i < newGridItems.length; i++) {
            newGridItems[i].id += 1;
          }
      
          // Insert a new secondary question at the correct position
          const newSecondaryQuestion = {
            id: 2,
            title: "Secondary Survey Question",
            defaultText: "Secondary Survey Question",
            showDelete: true,
          };
          newGridItems.splice(lastSecondaryQuestionIndex + 1, 0, newSecondaryQuestion);
    
          return newGridItems;
        });
      };

      const handleAddInstructionsForUse = () => {
        // setAddInstructionsDisabled(true)
        setGridItems((prevGridItems) => {
          let newGridItems = [...prevGridItems];

          const newInstruction = {
            id: gridItems.length+1,
            title: "Instructions for Use",
            defaultText: "Instructions for Use",
            showDelete: true,
          }
          newGridItems.push(newInstruction);
          return newGridItems;
        }
      )};

      const handleAddDemographicQuesion = () => {
        setGridItems((prevGridItems) => {
          let newGridItems = [...prevGridItems];

          const newDemographicQuestion = {
            id: gridItems.length+1,
            title: "Demographic Questions",
            defaultText: "Demographic Questions",
            showDelete: true,
          }
          newGridItems.push(newDemographicQuestion);
          return newGridItems;
        }
      )};

      const handleAddRecommendation = () => {
        // setAddInstructionsDisabled(true)
        setGridItems((prevGridItems) => {
          let newGridItems = [...prevGridItems];

          const newRecommendation = {
            id: gridItems.length+1,
            title: "ATOM Recommendations",
            defaultText: "ATOM Recommendations",
            showDelete: true,
          }
          newGridItems.push(newRecommendation);
          return newGridItems;
        }
      )};

      const handleDeleteItem = (id) => {
        setGridItems(gridItems.filter((item) => item.id !== id));
      };

      const disableAddButton = (title) => {
        return gridItems.some((item) => {
          if (item.title === title) {
            return true
          } else { return false }
        })
      }
      
    return (
        <Box sx={{ display: 'flex' }}>
            {/* The drawer is where all of the survey settings are located */}
            <Drawer
                variant="permanent"
                containerstyle={{ height: 'calc(100%-64px)', top: 64 }}
                sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: drawerWidth, height: drawerHeight, boxSizing: 'border-box', position: 'relative', zIndex: 'tootip' },
            }}
          >
    
            {/* This box holds all of the variable input fields. */}
            <Box sx={{ overflow: 'auto', position: 'absolute', top: 0, left: 30 }}>
                <Divider orientation='horizontal' sx={{ height: '15px' }} />
                <h5><b>Survey Pages</b></h5>
                    <Divider orientation='horizontal' sx={{ height: '10px' }} />
                        <Box display="flex" alignItems="end" justifyContent="space-between">
                            <span><h7>Add Primary Survey Question</h7></span>
                            <Button color="primary" size="large" disabled>
                                <AddCircleIcon />
                            </Button>
                        </Box>
                    <Divider orientation='horizontal' sx={{ height: '20px' }} />
                    <Box display="flex" alignItems="end" justifyContent="space-between">
                        <span><h7>Add Secondary Survey Question</h7></span>
                        <Button color="primary" size="large" onClick={handleAddSecondaryQuestion}>
                            <AddCircleIcon />
                        </Button>
                    </Box>
                <Divider orientation='horizontal' sx={{ height: '20px' }} />
                <Box display="flex" alignItems="end" justifyContent="space-between">
                        <span><h7>Add Instructions for Use</h7></span>
                        <Button color="primary" size="large" onClick={handleAddInstructionsForUse} disabled={disableAddButton("Instructions for Use")}>
                            <AddCircleIcon />
                        </Button>
                    </Box>
                <Divider orientation='horizontal' sx={{ height: '20px' }} />
                <Box display="flex" alignItems="end" justifyContent="space-between">
                        <span><h7>Add Demographics Survey Question</h7></span>
                        <Button color="primary" size="large" onClick={handleAddDemographicQuesion} disabled={disableAddButton("Demographic Questions")}>
                            <AddCircleIcon />
                        </Button>
                    </Box>
                <Divider orientation='horizontal' sx={{ height: '20px' }} />
                <Box display="flex" alignItems="end" justifyContent="space-between">
                        <span><h7>Add Recommendation</h7></span>
                        <Button color="primary" size="large" onClick={handleAddRecommendation} disabled={disableAddButton("ATOM Recommendations")}>
                            <AddCircleIcon />
                        </Button>
                    </Box>
                <Divider orientation='horizontal' sx={{ height: '20px' }} />
                </Box>
            </Drawer>
            <Box sx={{ p: 2 }}>
                <h3>Survey Pages</h3>
                <Divider orientation='horizontal' sx={{ height: '10px' }} />
                {gridItems.map((item) => (
                <Grid container direction="column" justifyContent="space-evenly" sx={{ width: "350%" }} key={item.id}>
                    <CustomGridItem
                      id = {item.id}
                      title={item.title}
                      defaultText={item.defaultText}
                      showDelete={item.showDelete}
                      inputs={itemInputs[item.id] || {}}
                      onInputChange={handleInputChange}
                      onDelete={() => handleDeleteItem(item.id)}
                      setAddPages={setAddPages}
                    />
                    <Divider orientation='horizontal' sx={{ height: '10px' }} />
                </Grid>
                ))}
            </Box>
        </Box>
    );
}

export default AddPages;