import { Navigate } from 'react-router-dom';
import { useSelector, connect } from 'react-redux';
import { Route } from 'react-router-dom'
//import history from '_helpers';


const PrivateRoute = ({ children, isAuthenticated }, ...rest) => {  

    if (!isAuthenticated) {
        // not logged in so redirect to login page with the return url
        return <Navigate to="/" />
    }

    // authorized so return child components
    return children;
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, {})(PrivateRoute);

