import * as React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { Fragment, useState, useRef } from 'react';
import axios from 'axios';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';

const drawerWidth = 415;
const drawerHeight = 400;


const ViewSamples = ({ samples, configInputs, updateSamples }) => {

  const [exportSample, setExportSample] = useState(
    {
      csvSample: null
    }
  );
  const inputFile = useRef(null);
  const onButtonClick = () => {
    inputFile.current.click();
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setExportSample({
        ...exportSample,
        [name] : value,
    });
    console.log(e.target.files);
  };

  const [isLoading, setIsLoading] = useState(true)
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([{ headerName: '', field: 'sample' }]);
  const gridRef = useRef(null);

  
  let generatedBy = ["Generated By"];
  const variable_names = generatedBy.concat(configInputs.variable_names);

  // USE EFFECT
  useEffect(() => {
    const newColumnDefs = [...columnDefs];

    if (samples && samples[0]) {
      const initialRowData = [];
      // Initialize the rows array
      for (let i = 0; i < variable_names.length; i++) { // 0 -> 2
        if (!newColumnDefs.some(column => column.field === variable_names[i])) {
            // This will be for the column headers
            newColumnDefs.push({
              headerName: variable_names[i],
              field: variable_names[i],
              editable: (params) => params.data['Generated By'].includes('Custom') || params.data['Generated By'].includes('Dummy'), // Check if the row has the isAdded property
              cellClassRules: {
                'ag-cell-editable': () => true,
              },
            });
          }
            // This will be for the rows
            for (let j = 0; j < samples[0].length; j++) { // 0 -> 2
              const value = samples[i][j];
              // IF 'Sample 1, 2, 3, ..., 10'
              if (initialRowData[j]) {
                initialRowData[j][variable_names[i].toString()] = value;
              } else {
                initialRowData.push({
                  sample: 'Sample ' + (j + 1),
                  [variable_names[i].toString()]: value
                });
              }
            }
          }
            setRowData(initialRowData);
            setColumnDefs(newColumnDefs);
            setIsLoading(false);
          }
  }, [samples]);
  

  // This is where the custom and dummy samples are being added
  let [customCountPD, setCustomCountPD] = useState(1);
  let [customCountNPD, setCustomCountNPD] = useState(1);
  let [dummyCount, setDummyCount] = useState(1);

  const addCustomPDSample = () => {
    const newRow = { sample: 'PD Custom Sample ' + (customCountPD), 'Generated By': 'Custom (PD)', isAdded: true };
    variable_names.forEach((variable_name) => {
      if (variable_name !== 'Generated By') {
        newRow[variable_name] = '';
      }
    });

    // Add the new row to rowData and update the state
    const updatedRowData = [...rowData, newRow];
    setRowData(updatedRowData);
    updateSamplesData(updatedRowData);
    setCustomCountPD(customCountPD+1)
  }

  const addCustomNPDSample = () => {
    const newRow = { sample: 'NPD Custom Sample ' + (customCountNPD), 'Generated By': 'Custom (NPD)', isAdded: true };
    variable_names.forEach((variable_name) => {
      if (variable_name !== 'Generated By') {
        newRow[variable_name] = '';
      }
    });

    // Add the new row to rowData and update the state
    const updatedRowData = [...rowData, newRow];
    setRowData(updatedRowData);
    updateSamplesData(updatedRowData);
    setCustomCountNPD(customCountNPD+1)
  }

  const addDummySample = () => {
    const newRow = { sample: 'Dummy Sample ' + (dummyCount), 'Generated By': 'Dummy', isAdded: true };
    variable_names.forEach((variable_name) => {
      if (variable_name !== 'Generated By') {
        newRow[variable_name] = '';
      }
    });

    // Add the new row to rowData and update the state
    const updatedRowData = [...rowData, newRow];
    setRowData(updatedRowData);
    updateSamplesData(updatedRowData);
    setDummyCount(dummyCount+1)
  }

  // This allows the user to delete rows that they have added
  const deleteRow = (index) => {
    const rowToDelete = rowData[index];
    const sampleType = rowToDelete.sample.split(' ')[0]; // The first part of the sample name should be the type
  
    const updatedRowData = rowData.filter((_, i) => i !== index);
  
    // Reassign sample names
    let customPDSampleCount = 1;
    let customNPDSampleCount = 1;
    let dummySampleCount = 1;
  
    updatedRowData.forEach((row) => {
      const rowSampleType = row.sample.split(' ')[0];
      if (rowSampleType === 'PD') {
        row.sample = `PD Custom Sample ${customPDSampleCount}`;
        customPDSampleCount++;
      } else if (rowSampleType === 'NPD') {
        row.sample = `NPD Custom Sample ${customNPDSampleCount}`;
        customNPDSampleCount++;
      } else if (rowSampleType === 'Dummy') {
        row.sample = `Dummy Sample ${dummySampleCount}`;
        dummySampleCount++;
      }
    });
  
    setRowData(updatedRowData);
    updateSamplesData(updatedRowData);
  
    // Update the customCountPD, customCountNPD or dummyCount based on the sample type
    if (sampleType === 'PD') {
      setCustomCountPD(customCountPD - 1);
    } else if (sampleType === 'NPD') {
      setCustomCountNPD(customCountNPD - 1);
    } else if (sampleType === 'Dummy') {
      setDummyCount(dummyCount - 1);
    }
  };
  

  // This is what shows the delete icons in the right hand column
  const deleteIconCellRenderer = (params) => {
    const sampleType = params.data.sample.split(' ')[0] + ' ' + params.data.sample.split(' ')[1];

    if (params.data['Generated By'].includes('Custom') || params.data['Generated By'].includes('Dummy')) {
      return (
        <button
          style={{ border: 'none', background: 'transparent' }}
          onClick={(e) => {
            e.stopPropagation();
            deleteRow(params.rowIndex, sampleType);
          }}
        >
          <DeleteIcon />
        </button>
      );
    } else {
      return null;
    }
  };

  const deleteColumn = {
    headerName: "Delete",
    cellRendererFramework: deleteIconCellRenderer,
    width: 100
  };

  // This updates the columns after they are deleted
  const updatedColumnDefs = [...columnDefs, deleteColumn];

  const updateSamplesData = (newRowData) => {
    const newSamples = [];
    newRowData.forEach((row) => {
      variable_names.forEach((variable_name, index) => {
        if (!newSamples[index]) {
          newSamples[index] = [];
        }
        newSamples[index].push(row[variable_name]);
      });
    });
    console.log("Updating samples with: ", newSamples);
    updateSamples(newSamples);
  };  
  console.log(exportSample.csvSample)
  
  return (
      <Box sx={{ p: 2, display: 'flex' }}>
        {/* The drawer is where all of the survey settings are located */}
        <Drawer
          variant="permanent"
          containerstyle={{ height: 'calc(100%-64px)', top: 64 }}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: { width: drawerWidth, height: drawerHeight, boxSizing: 'border-box', position: 'relative', zIndex: 'tootip', left: '-23%' },
          }}
        >
  
          {/* This box holds all of the variable input fields. */}
          <Box sx={{ overflow: 'auto', position: 'absolute', top: 0, left: 30 }}>
          <Divider orientation='horizontal' sx={{ height: '20px' }} />
                  <Box display="flex" alignItems="end" justifyContent="center">
                      <Button variant="contained" color="primary" size="large" sx={{ display: 'flex', justifyContent: 'end'}}>
                        Regenerate Samples
                      </Button>
                  </Box>
              <Divider orientation='horizontal' sx={{ height: '20px' }} />
                  <Box display="flex" alignItems="end" justifyContent="space-between">
                      <span><h5>Add Custom Sample (Part of Design)</h5></span>
                      <Button color="primary" size="large" onClick={addCustomPDSample} sx={{ display: 'flex', justifyContent: 'end'}}>
                          <AddCircleIcon />
                      </Button>
                  </Box>
              <Divider orientation='horizontal' sx={{ height: '20px' }} />
              <Box display="flex" alignItems="end" justifyContent="space-between">
                      <span><h5>Add Custom Sample (Not Part of Design)</h5></span>
                      <Button color="primary" size="large" onClick={addCustomNPDSample} sx={{ display: 'flex', justifyContent: 'end'}}>
                          <AddCircleIcon />
                      </Button>
                  </Box>
              <Divider orientation='horizontal' sx={{ height: '20px' }} />
              <Box display="flex" alignItems="end" justifyContent="space-between">
                  <span><h5>Add Dummy Sample</h5></span>
                  <Button color="primary" size="large" onClick={addDummySample} sx={{ display: 'flex', justifyContent: 'end'}}>
                      <AddCircleIcon />
                  </Button>
              </Box>
              <Divider orientation='horizontal' sx={{ height: '20px' }} />
              <Box display="flex" alignItems="end" justifyContent="center">
                  {/* <span><h5>Export Samples (.csv)</h5></span> */}
                  <Button variant="contained" onClick={onButtonClick}>
                    Export Samples (.csv)
                  </Button>
                  <input 
                    type="file" 
                    style={{display: 'none'}} 
                    ref={inputFile}
                    accept=".csv" 
                    id="export_sample" 
                    name="csvSample" 
                    onChange={handleInputChange} 
                  />
              </Box>
            <Divider orientation='horizontal' sx={{ height: '20px' }} />
          </Box>
        </Drawer>
        <Toolbar />
        <Box>
          <h3>Design of Experiment</h3>
          <Divider orientation='horizontal' sx={{ height: '10px' }} />
            <div className="parent-container">
              <div className="ag-theme-alpine" style={{ height: 1080, width: 1200 }}>
                {isLoading === false ?
                  <AgGridReact
                    ref={gridRef}
                    columnDefs={updatedColumnDefs}
                    rowData={rowData}
                    gridOptions={{ singleClickEdit: true, autoHeight: true, stopEditingWhenCellsLoseFocus: false }}
                    onCellValueChanged={() => updateSamplesData(rowData)}
                  />
              : <div></div>}
              </div>
            </div>         
        </Box>
  </Box>
  )
}

export default ViewSamples;