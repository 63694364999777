import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import FlipCard from '../general/FlipCard';
import array from './create-cards.json';

const cards = array.createCards;

const Design = () => {
  return (
    <div className="container-md pt-4">
      <div className="row h-100">
      <h1 class="display-4"><center>Create</center></h1>

        <div className="col d-flex flex-column flex-md-row justify-content-around align-items-center">
          {cards.map((card) => (
            <FlipCard key={card.id} card={card} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Design;
