import * as React from 'react';
import { useState } from 'react';
import ContinuousVariables from '../low_level_components/ContinuousVariables';
import DynamicModeling from '../low_level_components/dynamic_modeling';
import DesignType from '../low_level_components/DesignType';
import ConstantVariables from '../low_level_components/constants';
import AllVariables from '../low_level_components/AllVariables';
// import Box from '@mui/material/Box';
// import Calculate_NoSamples_ATOM3 from '../low_level_components/Calculate_NoSamples_ATOM3'

// Pass in onInputChange

// setConfigInputs, configInputs
const Configuration = ({ projectType, atomAdminister, hardware, setConfigInputs }) => {

    let variable_selection = null;
    if (hardware === "Not Applicable") {
        // configMenu={configMenu} setConfigMenu={setConfigMenu}
        variable_selection = <AllVariables  setConfigInputs={setConfigInputs} />
    }
    else {
        variable_selection = <ContinuousVariables setConfigInputs={setConfigInputs} />
    }

    // const num_samples = 12;
    // const Calculate_NoSamples_ATOM3(No_Continious_Var,Cat_Var_Levels,Discrete_Var_Levels,Required,Custom,ModelType);

    return (        
        <>
            {variable_selection}
            <ConstantVariables  setConfigInputs={setConfigInputs} />
            <DynamicModeling setConfigInputs={setConfigInputs} />
            <DesignType setConfigInputs={setConfigInputs} />
            {/* <Box>{Calculate_NoSamples_ATOM3(1,1,1,1,1,"Linear",0,"Bayesian")}</Box> */}
        </>
    );
}

export default Configuration;