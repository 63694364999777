import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Switch from '@mui/material/Switch';
import { InputLabel } from '@mui/material';

// Initialize the inputs

const numericInputs = {
    primarySurveyQuestion: "",
    showRatingLabels: true,
    min: null,
    max: null,
    step: null,
    shape: "",
    ratingLabelFields: [],
}

const PrimarySurveyQuestion = ({ setAddPages }) => {

  // Set input states
  const [inputs, setInputs] = useState(numericInputs);

  // Handle the inputs as they are received
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;
  
    if (name === "min" || name === "max" || name === "step") {
      updatedValue = parseInt(value);
  
      // Handle the change in increase or decrease of number of ratingLabelFields
      if (name === "step" && value < inputs.step) {
        const diff = inputs.step - value;
        setInputs((prevInputs) => ({
          ...prevInputs,
          ratingLabelFields: prevInputs.ratingLabelFields.slice(0, prevInputs.ratingLabelFields.length - diff),
        }));
      }
    }
  
    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: updatedValue,
    }));

    console.log("Primary Survey Quesiton", inputs);
    // setAddPages(inputs);
  };
  useEffect(() => {
    
    const PSQ = {
        question: inputs.primarySurveyQuestion,
        show_rating_label: inputs.showRatingLabels,
        minimum: inputs.min,
        maximum: inputs.max,
        step: inputs.step,
        shape: inputs.shape,
        rating_labels: inputs.ratingLabelFields
    }

    let primary_survey_question = PSQ;

    setAddPages( prevState  => ({ ...prevState,   primary_survey_question: primary_survey_question }));

  }, [inputs]);
  
  // Handles the switch inputs
  const handleSwitchChange = (e) => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      [e.target.name]: e.target.checked,
    }));
  };

  // Take in the changes made to the rating label fields
  const handleRatingLabelChange = (e, index) => {
    const updatedRatingLabelFields = [...inputs.ratingLabelFields];
    updatedRatingLabelFields[index] = e.target.value;
    setInputs((prevInputs) => ({
      ...prevInputs,
      ratingLabelFields: updatedRatingLabelFields,
    }));
  };

  console.log(inputs);

  return (
    <>
      <Grid container direction="column">
        <Grid item xs={12}>
            <TextField 
              id="primarySurveyQuestion"
              value={inputs.primarySurveyQuestion}
              onChange={handleInputChange}
              name="primarySurveyQuestion"
              label="How much do you like this aroma?"
              sx={{ display: 'grid' }}
          />
        </Grid>
        <Divider orientation='horizontal' sx={{ height: '10px' }} />
        <Grid item xs={12}>
          {/* <Box sx={{ display: 'flex' }} justifyContent="flex-start"> */}
            <FormControl>
              <FormGroup>
                <h6>Show Rating Labels</h6>
                <FormControlLabel
                  sx={{ p: 0.5 }}
                  control={
                    <Switch
                      checked={inputs.showRatingLabels}
                      onChange={handleSwitchChange}
                      name="showRatingLabels"
                    />
                  }
                />
              </FormGroup>
            </FormControl>
        </Grid>
        <Grid item>
          <Box sx={{ display: 'flex' }} justifyContent="flex-start">
            <TextField
              sx={{ width: 120 }}
              label="Minimum"
              required
              inputProps={{ min: 0 }}
              variant="outlined"
              type="number"
              name="min"
              value={inputs.min || ""}
              onChange={handleInputChange}
            />
            <Divider orientation='vertical' sx={{ width: '10px' }} />
            <TextField
              sx={{ width: 120 }}
              label="Maximum"
              required
              inputProps={{ min: inputs.min + 1 }}
              variant="outlined"
              type="number"
              name="max"
              value={inputs.max || ""}
              onChange={handleInputChange}
            />
            <Divider orientation='vertical' sx={{ width: '10px' }} />
            <TextField
              sx={{ width: 110 }}
              inputProps={{ min: 2, max: 10 }}
              label="Step"
              variant="outlined"
              type="number"
              name="step"
              value={inputs.step || ""}
              onChange={handleInputChange}
            />
            <Divider orientation='vertical' sx={{ width: '10px' }} />
            <FormControl sx={{ minWidth: '10%' }}>
              <InputLabel variant="outlined" id="shape-label">Shape</InputLabel>
              <Select
                required
                label="Shape *"
                labelId="shape-label"
                id="shape"
                value={inputs.shape}
                name="shape"
                onChange={handleInputChange}
              >
                <MenuItem value="Star">Star</MenuItem>
                <MenuItem value="Triangle">Triangle</MenuItem>
                <MenuItem value="Circle">Circle</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
        {inputs.showRatingLabels && inputs.step && (
          <>
            <Divider orientation='horizontal' sx={{ height: '10px' }} />
            <Grid item sx={{ display: 'grid' }}>
              {[...Array(inputs.step)].map((_, index) => (
                <div key={index}>
                  <TextField
                    label={`${inputs.shape} ${index + 1} (Optional)`}
                    variant="outlined"
                    name={`ratingLabelFields[${index}]`}
                    value={inputs.ratingLabelFields[index] || ""}
                    onChange={(e) => handleRatingLabelChange(e, index)}
                    sx={{ width: '450px' }}
                  />
                  <Divider orientation='horizontal' sx={{ height: '10px' }} />
                </div>
              ))}
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default PrimarySurveyQuestion;
